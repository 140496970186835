@import "../../../../scss/helpers/variables";
.site-collapse-custom-collapse-integration {
    border-color: $iron;
    background-color: #fff;
    .ant-collapse-item {
        border: 1px solid $iron;
        margin-bottom: 16px;

        &.ant-collapse-item-active {
            .ant-collapse-header {
                border-bottom: 1px solid $iron;
            }
        }
        &:last-child {
            border-bottom: 1px solid $iron;
            &.invalid {
                border-bottom: 1px solid #f35848;
            }
        }

        .ant-collapse-header {
            background-color: #fff;
            .ant-collapse-expand-icon {
                span {
                    transform: inherit;
                    top: 12px;
                }
            }
            .ant-collapse-header-text {
                .title {
                    font-weight: bold;
                    color: $mid-night;
                    display: flex;
                    align-items: center;
                    svg {
                        width: 16px;
                        height: 16px;
                        line-height: 1.25;
                        letter-spacing: 0.03px;
                        margin: 0 0 4px 4px;
                        color: $sunset-color;
                    }
                }
                .description {
                    font-size: 12px;
                    line-height: 1.33;
                    letter-spacing: 0.02px;
                    color: $nobel-color;
                }
            }
        }
        .ant-collapse-content {
            &-box {
                padding: 16px !important;
            }
        }

        &.invalid {
            border-color: #f35848;
            .ant-collapse-header {
                border-color: #f35848;
            }
        }
    }
    .UA-panel {
        .ant-collapse-header {
            .ant-collapse-header-text {
                .title {
                    color: $nobel-color;
                }
            }
        }
    }
}