@import "../../../../../../scss/helpers/variables";
.activity-tab {
    max-height: calc(90vh - 262px);
    overflow: auto;
    &::-webkit-scrollbar {
        height: 5px;
        width: 6px;
    }
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track-piece {
        background: $iron;
    }
    &::-webkit-scrollbar-thumb {
        background: $nobel-color;
        border: none;
    }
}