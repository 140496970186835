.edit-image-modal {
    .ant-modal-header,
    .ant-modal-close {
        display: none;
    }

    .ant-modal-content {
        border-radius: 4px;
        .ant-modal-header {
            border-radius: 4px 4px 0 0;
        }
        .ant-modal-body {
            border-radius: 0 0 4px 4px;
        }
    }
}

.jWESXx,
.MDSqF,
.bicESt {
    border-color: #0185de !important;
    background: #0185de !important;

    &:hover {
        border-color: #0067ab !important;
        background: #0067ab !important;
    }
}

.SfxModalContent-root {
    > .SfxInputGroup-root {
        &:first-child {
            display: none;
        }

        &:nth-child(2) {
            margin-top: 0px;
        }
    }
}
