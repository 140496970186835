.users-page {
    &__tableWrapper {
        margin-top: 40px;
    }

    &__tableHeader {
        .search-input {
            width: 210px;
        }

        .filter-input {
            width: 182px;
        }
    }

    .dropdown-action-button {
        border: none;

        svg {
            width: 14px;
            height: 14px;
            color: inherit;
            margin-left: 5px;
        }
    }
}

.client-site-drawer {
    .ant-drawer-footer {
        border-top: none;
        padding: 24px;
    }
}
