@import "../../../../../scss/helpers/variables";
.select-account-ga {
    .add-connection {
        width: 100%;
        height: 46px;
        justify-content: flex-start;
        font-weight: 500;
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: $mid-night;
        img {
            margin-bottom: 1px;
            width: 40px;
            height: 40px;
        }
        &:hover {
            background: #f5f5f5 !important;
        }
    }
}

.select-property-ga {
    .search-property {
        padding: 12px;
        display: flex;
        .ant-input-affix-wrapper {
            padding: 0 4px 0 0;
            input {
                height: 36px;
                padding: 0 12px;
                &:focus {
                    border-color: none;
                }
            }
            .ant-input-suffix {
                svg {
                    color: $nobel-color;
                }
            }
        }
        .refresh-btn {
            height: 38px;
            width: 38px;
            border-radius: 4px;
            border-color: $iron;
            padding: 0;
            margin-left: 8px;
            svg {
                margin: 0;
                width: 18px;
                height: 20px;
                color: $nobel-color;
                &:hover {
                    color: #000;
                }
            }
        }
    }
    .display-property {
        line-height: 1.43;
        letter-spacing: 0.03px;
        .property-name {  
            color: $mid-night;
            .highlight {
                background-color: #f2d249 !important;
            }
        }
        .property-id {
            color: $nobel-color;
        }
    }
}