@import "../../../../../scss/helpers/variables";
.approval-request {
  width: 75%;
  margin: 0 auto;

  .list-media-content-card {
    height: 250px;
    .ant-carousel .slick-slide > div .media-item {
      height: 262px;
    }
  }

  .content-editor .media {
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  .approval-request-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid $iron;
    .approval-request-header__left {
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.04px;
        color: #4d546c;
        margin-bottom: 12px;
      }
      .info {
        .info-item {
          margin-right: 20px;
          font-size: 14px;
          line-height: 1.43;
          letter-spacing: 0.03px;
          color: $nobel-color;
          span {
            color: $mid-night;
          }
        }
      }
    }
    .approval-request-header__right {
      display: flex;
      button {
        margin-left: 20px;
        &.btn-dangerous {
          border-color: $sunset-color;
          color: $sunset-color;
        }

        &:disabled {
          opacity: 0.5;
          pointer-events: none;

          &.ant-btn-primary {
            color: #ffffff;
          }
        }
      }
    }
  }
  .approval-request-main {
    .list-content {
      .edit-content-container {
        margin-top: 40px;
        .edit-content-container-body {
          .edit-content-container-body__right {
            .list-comment {
              height: unset;
              max-height: 635px;
            }
          }
          .edit-content-container-body__middle {
            .content-editor {
              .content-editor-caption {
                .content-editor-caption__mask, textarea {
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
  .back-to-top {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #e6e9f1;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      color: $mid-night;
    }
  }
}

@media (max-width: 576px) {
  .approval-request {
    width: 100%;

    .list-media-content-card {
      .ant-carousel .slick-slide > div .media-item {
        height: 250px;
        
        video {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .approval-request-header {
      padding-bottom: 24px;

      display: block;
      .approval-request-header__left {
        .title {
          margin-bottom: 16px;
        }
        .info {
          .info-item {
            display: block;
            margin-bottom: 10px;
          }
        }
      }
      .approval-request-header__right {
        padding-top: 6px;
        display: flex;
        gap: 15px;
        button {
          width: 100%;
          margin: 0px;
        }
      }
    }
    .approval-request-main {
      .list-content {
        .edit-content-container {
          margin-top: 24px;

          .edit-content-container-body {
            margin-top: -28px;
          }
        }
      }
    }
  }
}
