@import "../../../../../../scss/helpers/variables";
.comment-tab {
  max-width: 419px;

  .list-comment {
    height: calc(90vh - 262px);
    overflow-y: auto;
    & > div {
      margin-right: 5px;
    }

    &::-webkit-scrollbar {
      height: 5px;
      width: 6px;
    }
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track-piece {
      background: $iron;
    }
    &::-webkit-scrollbar-thumb {
      background: $nobel-color;
      border: none;
    }
  }

  .view-all-comment {
    padding-left: 16px;
    margin-top: 25px;
    p {
      color: $primaryColor;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
        transition: all ease-in-out 0.3s;
      }
    }
  }
}

@media (max-width: 576px) {
  .comment-tab {
    margin-top: 16px;
    .list-comment {
        height: unset;
    }
  }
  .confirm-delete-comment {
    width: 100%;
    .ant-modal-content {
      width: 100% !important;
    }
  }
  .view-all-comment {
    margin: 0 auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9b9b9b1a;
    P {
      color: $mid-night!important;
      margin:0;
    }
  }
}
