.main-layout {
    width: 100%;
    background-color: #f7f8fb;
    height: fit-content;
    min-height: 100vh;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;

    .header-overlay {
        padding-bottom: 266px;
        margin-bottom: -266px;

        &.sales-analytics {
            padding-bottom: 203.5px;
            margin-bottom: -203.5px;
        }
    }
}
