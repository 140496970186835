
.edit-content-approval-container {
    .edit-content-container-body {
        .edit-content-container-body__left {
            .edit-approval {
                align-items: flex-start;
                .edit-approval-status {
                    margin-top: 7px;
                }
            }
        }
        .edit-content-container-body__middle {
            .content-editor {
                flex-grow: unset;
            }
        }
    }
}