@import "../../../../../../../../scss/helpers/variables";

.offer-tab {
  .add-post {
    width: 100%;
    height: 70px;
    border: dashed 1px #a1aac7;
    border-radius: 4px;
    background-color: #f7f8fb;
    line-height: 1.43;
    letter-spacing: 0.03px;
    color: #4d546c;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .offer-content {
    .title {
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: 0.03px;
      color: $mid-night;
      margin-bottom: 6px;
    }
    .date {
      line-height: 1.43;
      letter-spacing: 0.03px;
      color: $nobel-color;
    
    }
    .caption {
      line-height: 1.43;
      letter-spacing: 0.03px;
      color: $mid-night;
    }
    a {
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: 0.03px;
      color: $primaryColor;
      text-decoration: underline;
    }
    .add-post {
      height: 100px;
      flex-direction: column;
      padding: 24px;
      margin-top: 16px;

      div {
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: $nobel-color;
        &.code {
          font-size: 20px;
          font-weight: 500;
          line-height: 1.4;
          letter-spacing: 0.04px;
          color: $mid-night;
          margin: 5px 0;
        }
      }
    }
    .ant-collapse {
      .ant-collapse-header {
        flex-direction: row-reverse;
        align-items: center;
        padding: 5px 0;
        .ant-collapse-expand-icon {
          flex-grow: 1;
          svg {
            width: 10px;
            height: 10px;
            color: $nobel-color;
            margin-bottom: -1px;
            margin-left: 4px;
          }
        }
        .ant-collapse-header-text {
          flex-grow: 0;
          font-size: 10px;
          line-height: 1.6;
          letter-spacing: 0.02px;
          color: $nobel-color;
        }
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0;
          font-size: 10px;
          line-height: 1.4;
          letter-spacing: 0.02px;
          color: $nobel-color;
        }
      }
    }
  }
}

.add-event-post-modal {
  .ant-form {
    .app-date-picker {
      .app-date-picker-date {
        width: 100%;
      }
    }
    .ant-form-item {
      &.event-title {
        label {
          width: 100%;
          & > div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            & > span {
              line-height: 1.43;
              letter-spacing: 0.03px;
              color: $nobel-color;
            }
          }
          &::before {
            display: none;
          }
        }
        // .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        //   display: none;

        // }
      }
    }
  }
}

@media (max-width: 576px) {
}
