
.reset-pw-drawer {
    .ant-form-item {
        margin-bottom: 16px;
    }

    .password-hint-wrapper {
        margin-top: -12px;
        margin-bottom: 16px;
    }

    .ant-form-item-explain-error {
        margin-bottom: 4px;
    }
}