@import "../../scss/helpers/variables";

.select-company-page {
    max-width: 600px;
    min-width: 554px;
    background: #ffffff;
    padding: 40px;
    border-radius: 4px;
    text-align: center;
    .header {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #4b546e;
    }

    .list-client {
        margin-top: 24px;
        cursor: pointer;
        .client-item {
            height: 92px;
            display: flex;
            padding: 14px;
            border-radius: 4px;
            box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
            border: solid 1px $iron;
            background-color: #fff;
            &:not(:last-child) {
                margin-bottom: 16px;
            }
            .avatar {
                margin-right: 16px;
            }
            .info {
                text-align: left;
                overflow: hidden;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                & > div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .name {
                    font-weight: 500;
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    color: $primaryColor;
                }
                .email {
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    color: $mid-night;
                }
                .last-login {
                    font-size: 12px;
                    line-height: 1.67;
                    letter-spacing: 0.02px;
                    color: $nobel-color;
                }
            }
            .remove {
                svg {
                    width: 14px;
                    height: 14px;
                    color: $nobel-color;
                }
                &:hover {
                    svg {
                        color: $sunset-color;
                    }
                }
            }

            &.add-new-company {
                width: 100%;
                border: 1px $primaryColor dashed;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primaryColor;
                height: 65px;
                svg {
                    width: 14px;
                    height: 14px;
                    color: $primaryColor;
                    margin-right: 5px;
                }
            }
        }
    }
}
.confirm-delete-company-modal {
    width: unset !important;
}

.alert-desc {
   color: #4d546c;
   ul {
    padding-left: 14px;
    margin: 8px 0px;
   }
}