@import "../../../../../../../../scss/helpers/variables";

.write-with-ai-modal {
    width: 90vw !important;
    max-width: 90vw !important;
    min-height: 90vh;
    top: 5vh !important;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.03px;
    color: $mid-night;
    .ant-modal-content {
        min-height: 90vh;
        .ant-modal-body {
            min-height: calc(90vh - 55px);
            padding: 0;
        }
    }

    .container {
        min-height: calc(90vh - 55px);
        .ant-col {
            padding: 24px;
            &:not(:last-child) {
                border-right: 1px solid $iron;
            }

            .ant-collapse {
                font-size: 14px;
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: $mid-night;
                .ant-collapse-item {
                    border: none;
                    .ant-collapse-header {
                        flex-direction: row-reverse;
                        padding: 12px;
                        border-radius: 4px;
                        .ant-collapse-expand-icon {
                            svg {
                                height: 16px;
                                width: 16px;
                                margin-bottom: -1px;
                            }
                        }
                        .ant-collapse-header-text {
                            // font-weight: 500;
                            svg {
                                height: 16px;
                                width: 16px;
                                margin-bottom: -1px;
                                margin-right: 8px;
                            }
                        }
                    }
                    .ant-collapse-content {
                        background-color: transparent;
                        .ant-collapse-content-box {
                            padding: 16px 0 16px 0;
                            .idea-item {
                                padding: 4px 8px;
                                display: flex;
                                justify-content: space-between;
                                border-radius: 4px;
                                margin-bottom: 2px;
                                svg {
                                    width: 16px;
                                    height: 16px;
                                    line-height: 1.25;
                                    letter-spacing: 0.03px;
                                    color: $nobel-color;
                                    display: none;
                                }
                                &:hover {
                                    background-color: #353d5309;
                                    cursor: pointer;
                                    svg {
                                        display: block;
                                    }
                                }
                                &.selected {
                                    background-color: #353d531a;
                                }
                            }
                            .idea-item-empty {
                                line-height: 1.43;
                                letter-spacing: 0.03px;
                                color: $nobel-color;
                            }
                        }
                    }

                    &:hover, &-active {
                        .ant-collapse-header {
                            background-color: #353d531a;
                        }
                    }
                }
                &.ant-collapse-borderless {
                    background-color: transparent;
                }
            }

            &.your-idea {
                display: flex;
                flex-direction: column;
            }

            .describe {
                display: flex;
                justify-content: space-between;
            }

            .tone-label {
                margin: 30px 0 10px;
            }
            .tones {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 10px;
                button {
                    height: 35px;
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    font-size: 14px;
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    color: $mid-night;
                    border: 1px solid $iron;
                    border-radius: 6px;
                    svg {
                        width: 14px;
                        height: 14px;
                        margin-bottom: -2px;
                    }
                    &.selected {
                        background-color: $mid-night;
                        color: #fff;
                    }
                }
            }

            .ant-slider {
                .ant-slider-track {
                    background-color: #9bc13c;
                }
                .ant-slider-handle {
                    background-color: #9bc13c;
                    border: 2px solid #FFF;
                }
            }

            .include {
                margin: 10px 0;
                div {
                    margin-bottom: 16px;
                    .ant-switch {
                        margin-right: 8px;
                    }
                }
            }

            .save {
                cursor: pointer;
                width: fit-content;
                padding: 0;
                height: 20px;
                border: none;
                border-radius: 0;
                &:hover {
                    background-color: transparent;
                    border-bottom: 1px dashed $mid-night;
                    transition: none;
                }
                &:focus {
                    background-color: transparent;
                }
                &:disabled {
                    border-bottom: none;
                }
                &.saved {
                    color: #9bc13c;
                    border-bottom: none;
                }
                svg {
                    width: 14px;
                    height: 14px;
                    margin-bottom: -1px;
                }
            }

            .generate {
                width: fit-content;
                flex-grow: 1;
                display: flex;
                align-items: flex-end;
            }

            &.result {
                display: flex;
                flex-direction: column;
                .empty-result {
                    margin-top: 150px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .title {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        letter-spacing: 0.03px;
                        color: #4d546c;
                        margin: 10px 0;
                    }
                    .description {
                        width: 400px;
                        text-align: center;
                        svg {
                            width: 16px;
                            height: 16px;
                            margin-bottom: -3px;
                        }
                    }
                }
                .list-result {
                    .result-item {
                        background-color: #fff;
                        padding: 20px;
                        border: 1px solid $iron;
                        margin-top: 16px;
                        border-radius: 4px;

                        &:hover {
                            cursor: pointer;
                        }

                        &.selected {
                            border: 2px solid $primaryColor;
                        }
                    }
                }
                .result-footer {
                    flex-grow: 1;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    .credit {
                        svg {
                            width: 16px;
                            height: 16px;
                            margin-bottom: -2px;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
}