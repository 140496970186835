@import "../../../../../../../scss/helpers/variables";
.comment-draft {
    display: flex;
    margin-bottom: 16px;
    position: relative;
    .comment-draft-avatar {
    }
    .comment-draft-detail {
        margin-left: 8px;
        flex-grow: 1;
        .comment-draft-detail__container {
            display: flex;
            gap: 10px;
            .action-item.action-lock {
                &.lock {
                    position: relative;
                    top: 9px;
                    left: 0px;
                    color: #f6af2d;
                    cursor: pointer;
                }
                &.unlock {
                    position: relative;
                    top: 9px;
                    left: 0px;
                    cursor: pointer;
                }
            }
            &.lock {
                .comment-draft-detail__editor {
                    background-color: $light-yellow-color;
                    border: 1px solid $yellow-color;
                    .comment {
                        textarea {
                            background-color: #f8f2e7;
                        }
                    }
                }
            }
        }
        .comment-draft-detail__editor {
            flex-grow: 1;
            border-radius: 4px;
            border: 1px solid $iron;
            background-color: #fff;
            min-height: 36px;
          
            &.centered {
                display: flex;
            }
            .comment {
                display: flex;
                align-items: center;
              
                &.fullWidth {
                    width: 100%;
                }
                textarea {
                    border: none;
                    font-size: 14px;
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    color: $mid-night;
                    // min-height: 36px !important;
                    height: 17px;
                    &:focus {
                        box-shadow: none;
                        border-color: unset;
                        outline: none;
                    }
                }
                &.overflow {
                    flex-direction: column;
                    align-items: flex-end;
                }
                .action {
                    &.tools {
                        padding: 8px 0px;
                    }
                    display: flex;
                    .action-item,
                    .emoji-wrap {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                    .emoji-wrap {
                        .emoji-picker {
                            top: 20px;
                        }
                    }
                    svg {
                        margin-right: 8px;
                        color: $nobel-color;
                        width: 16px;
                        height: 18px;
                    }
                    .action-item:hover {
                        svg {
                            color: $primaryColor;
                        }
                    }
                    .review-upload {
                        button {
                            padding: 0;
                            label {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                            }
                            svg {
                                width: 16px;
                                height: 18px;
                                line-height: 1.25;
                                letter-spacing: 0.03px;
                                color: $nobel-color;
                            }
                        }
                    }
                }
            }
            .list-media {
                margin: 24px 12px 12px 12px;
                display: flex;
                flex-wrap: wrap;
                .media {
                    position: relative;
                    margin: 0 4px 4px 0;
                    border-radius: 4px;
                    .remove-media {
                        display: none;
                        position: absolute;
                        top: 4px;
                        right: 4px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: $mid-night;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        z-index: 999;
                        svg {
                            width: 8px;
                            height: 8px;
                            line-height: 2.5;
                            letter-spacing: 0.02px;
                            color: #fff;
                        }
                    }
                    img,
                    video {
                        height: 60px;
                        width: auto;
                        border-radius: 4px;
                    }

                    video {
                        min-width: 80px;
                    }
                }
                .media:hover {
                    .overlay {
                        position: absolute;
                        top: 0;
                        border-radius: 8px;
                        width: 100%;
                        height: 60px;
                        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(128, 128, 128, 0) 118%);
                    }
                    .remove-media {
                        display: flex;
                    }
                }
                .upload-media {
                    width: 60px;
                    height: 60px;
                    border-radius: 4px;
                    border: dashed 1px #0185de;
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    svg {
                        width: 16px;
                        height: 18px;
                        line-height: 1.25;
                        letter-spacing: 0.03px;
                        color: #0185de;
                    }
                    &.device-upload-btn {
                        padding: 0;
                        label {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            svg {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
        .comment-draft-detail__action {
            // display: none;
            span {
                font-size: 10px;
                line-height: 1.6;
                letter-spacing: 0.02px;
                color: $nobel-color;
                cursor: pointer;
            }
            span.cancel {
                margin-right: 4px;
            }
        }

        &:focus-within {
            .comment-draft-detail__editor {
                .action {
                    svg {
                        color: $mid-night;
                    }
                }
            }
            .comment-draft-detail__action {
                // display: block;
            }
        }
    }

    .btn-comment-mobile-wrapper {
        display: none;
        width: 100%;
    }
    .comment-draft-detail-mobile {
        width: 100%;
        min-height: 58px;
        padding: 8px 16px;
        opacity: 1;
        box-shadow: 0 -7px 6px 0 rgba(188, 188, 188, 0.14);
        background-color: #fff;
        display: none;
        align-items: center;
        .attachment {
            button {
                padding: 0;
                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                svg {
                    width: 16px;
                    height: 18px;
                    line-height: 1.25;
                    letter-spacing: 0.03px;
                    color: $mid-night;
                }
            }
        }
        .comment-draft-detail-mobile__editor {
            flex-grow: 1;
            margin: 0 12px;
            border-radius: 21px;
            border: solid 1px $iron;
            background-color: #f7f8fb;
            overflow-y: scroll !important;
            max-height: 80px !important;

            &::-webkit-scrollbar {
                display: none;
            }
            .comment {
                min-height: 42px;
                padding: 5px;
                display: flex;
                align-items: center;
                textarea {
                    border: none;
                    padding: 0 10px;
                    background-color: transparent;
                    overflow: hidden;
                    max-height: unset !important;
                    &:focus {
                        box-shadow: none;
                        border-color: unset;
                        outline: none;
                    }
                }
            }
            .list-media {
                padding: 15px;
                display: flex;
                flex-wrap: wrap;
                .media {
                    position: relative;
                    margin: 0 4px 4px 0;
                    border-radius: 4px;
                    .media-item__bg {
                        background-image: none !important;
                        .ant-image {
                            width: unset;
                        }
                    }
                    .remove-media {
                        display: none;
                        position: absolute;
                        top: 4px;
                        right: 4px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: $mid-night;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        z-index: 999;
                        svg {
                            width: 8px;
                            height: 8px;
                            line-height: 2.5;
                            letter-spacing: 0.02px;
                            color: #fff;
                        }
                    }
                    img,
                    video {
                        height: 40px;
                        width: auto;
                        border-radius: 4px;
                    }

                    video {
                        min-width: 20px;
                    }
                }
            }
        }
        .btn-send {
            width: 32px;
            height: 32px;
            background-color: $primaryColor;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 18px;
                height: 20px;
                line-height: 0.78;
                color: #fff;
            }
        }
    }

    &.comment-draft-mobile {
        margin-bottom: 0;
    }
}

.approval-buttons-modal {
    .ant-modal-content {
        border-radius: 8px;
        box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.15);
    }
    .custom-modal-approval-status .modal-approval-status-item {
        padding: 14px 0;
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .comment-draft {
        display: block;
        .comment-draft-detail,
        .comment-draft-avatar {
            display: none;
        }
        .btn-comment-mobile-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            &-left {
                width:100%;
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: $mid-night;
                &:disabled {
                    opacity: 0.5;
                }
            }
            &-right {
                line-height: 1.43;
                letter-spacing: 0.03px;
                text-align: center;
                width:100%;
                color: #fff;
                &.pending {
                    background-color: #1790ff;
                    border:none;
                }
                &.not_required {
                    background-color: $iron;
                    border: none !important;
                    svg {
                        color: $nobel-color;
                    }
                }
                &.approved {
                    background-color: $green-color;
                    border: none !important;
                    svg {
                        color: #FFF;
                    }
                }
                &.declined {
                    background-color: $sunset-color;
                    border: none !important;
                    svg {
                        color: #FFF;
                    }
                }
            }
        }

        .comment-draft-detail-mobile {
            display: block;
            margin: 20px 0px;
            padding: 0;
            background-color: transparent !important; 
            .comment-draft-detail__container {
                margin-bottom: 16px;
                .comment-draft-detail__editor {
                    .comment {
                        textarea {
                            min-height: 66px !important;
                            &:focus {
                                border: 1px solid $primaryColor;
                            }
                        }
                    }
                }
            }
            .action {
                display: flex;
                justify-content: space-between;
                .action-right {
                    display: flex;
                    .attachment {
                        margin-right: 16px;
                    }
                    .post-comment-mobile:disabled {
                        background-color: $primaryColor;
                        opacity: 0.5;
                        color: #fff;
                    }
                }
            }
        }
    }
    .custom-modal-approval-status {
        padding:0;
        border-radius: 10px !important;;
        .ant-modal-close-x{
            display: none;
        }
        .ant-modal-body { 
            padding:0
        }
        .modal-approval-status-item{
            padding:10px 0; 
             text-align: center;
            & ~ .modal-approval-status-item {
                border-top: 1px solid $iron;
            }
            &.decline-text {
                color:$sunset-color;
            }
        }
    }
}


