@import "../../../../../scss/helpers/variables";
.edit-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  &-header {
    width: 472px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    .sync-content {
      span {
        margin-right: 8px;
        color: $mid-night;
      }
    }
  }
  &-body {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100%;
    &__left {
      display: flex;
      justify-content: flex-end;
      width: calc((100% - 472px) / 2);
      .sharelink-approval {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .share-link {
          margin-bottom: 10px;
          cursor: pointer;
        }
        .approval {
          display: flex;
          align-items: center;
        }
        .icon {
          width: 32px;
          height: 32px;
          border-radius: 999px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $mid-night;
          margin-left: 8px;
          background-color: #fff;
          svg {
            width: 14px;
            height: 14px;
          }
          &:hover {
            box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.14);
            border: solid 1px $primaryColor;
            background-color: #fff;
            svg {
              color: $primaryColor;
            }
          }
        }
      }
      .content-tab {
        margin-left: 16px;
      }
    }
    &__middle {
      width: 472px;
      margin: 0 16px;
      display: flex;
      flex-direction: column;
      .create-content-modal-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        .btn-group-save {
          display: flex;
          .btn-save {
            &:not(.no-dropdown) {
              border-radius: 4px 0 0 4px;
            }
            margin-right: 1px;
            &.full-radius {
              border-radius: 4px;
            }
          }
          .btn-dropdown {
            border-radius: 0 4px 4px 0;
            padding: 4px 8px;
            svg {
              margin: 0;
            }
          }
        }
      }
    }
    &__right {
      width: calc((100% - 472px) / 2);
      .ant-tabs {
        max-width: 419px;
        .ant-tabs-tab {
          color: $nobel-color;
          background-color: #f7f8fb;
          border: 1px solid #f7f8fb;
          border-bottom: 1px solid #f0f0f0;
        }
        .ant-tabs-tab-active {
          border: 1px solid #f0f0f0;
          border-bottom: 1px solid #f7f8fb;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          .ant-tabs-tab-btn {
            color: $mid-night;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .edit-content-container {
    .edit-content-container-header {
      width: unset;
    }
    .edit-content-container-body {
      flex-direction: column;
      .edit-content-container-body__middle {
        width: 100%;
        margin: 0;
      }
      .edit-content-container-body__right {
        width: unset;
      }
    }
  }
}
