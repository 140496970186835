.support-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    height: 100%;
    flex: 1;
    border-radius: 5px;
    height: calc(100vh - 303px);
    min-height: 800px;
    margin-bottom: -20px;
    padding-bottom: 20px;
    color: #4d546c;

    &__body {
        width: 312px;
        margin-top: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        img {
            width: 78.5px;
            height: auto;
        }

        p {
            font-size: 15px;
            font-weight: 500;
            color: #4b546e;
            margin-top: 20px;
            margin-bottom: 8px;
        }

        small {
            font-size: 12px;
        }

        button {
            margin-top: 20px;

            svg {
                width: 14px;
                height: 14px;
                margin-right: 8px;
            }
        }

        &-buttons {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    &__footer {
        font-size: 10px;
    }
}
