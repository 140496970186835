@import "../../../../scss/helpers/variables";

.sales-analytics-charts {
    .rl-card {
        margin-bottom: 16px;
        padding: 16px 22px;

        &.table {
            padding: 16px 0 0 0;
        }

        .icons {
            display: flex;
            gap: 6px;
            align-items: center;

            span {
                padding: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    background-color: #e9eaec;
                    transition: all 0.1s;
                    border-radius: 4px;
                }
            }
        }

        &-title {
            margin-bottom: 18px;
            text-transform: uppercase;
             
            &.table {
                padding: 0px 22px;
                margin-bottom: 4px;
            }
        }
    }

    &__cardSum {
        display: flex;
        align-items: center;
        margin-bottom: 18px;

        &-time {
            color: #4b546e;
            line-height: 20px;
            margin-top: 8px;
        }

        p {
            font-size: 24px;
            color: #4b546e;
            margin-bottom: 0;
            margin-right: 4px;
            font-weight: 500;
        }

        span {
            display: flex;
            align-items: center;
            margin-top: 5px;
            margin-left: 5px;
            font-weight: 500;

            &.active {
                color: #9bc13c;
            }

            &.inactive {
                color: #f35848;
            }

            svg {
                margin-left: 4px;
                height: 18px;
                width: 18px;
            }
        }
    }

    &.empty-state {
        position: relative;
    }

    .empty {
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        margin-top: 121px;

        @media (max-width: 576px) {
            margin-top: 14px;
        }

        .title {
            color: #4b546e;
            font-size: 15px;
            font-weight: 500;
            margin-top: 24px;
            margin-bottom: 8px;
            line-height: 1.47;

            @media (max-width: 576px) {
                margin-top: 8px;
                margin-bottom: 4px;
            }
        }

        .desc {
            color: #4b546e;
            margin-bottom: 24px;
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: 0.02px;

            @media (max-width: 576px) {
                margin-bottom: 12px;
            }
    
        }

        .connect-btn {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    

    .call-status-chart {
        display: flex;
        align-items: center;
        gap: 70px;

        .chart {
            width: 80%;

            @media (max-width: 576px) {
                width: 100%;
            }
        }

        .right-panel {
            .wrapper {
                display: flex;
                align-items: center;

                .label {
                    color: #9b9b9b;  
                    width: 120px;
                  }
      
                  .value {
                   color: #4b546e;   
                  }
            }
            
        }

        @media (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }
    }

    @media (min-width: 576px) and (max-width: 1200px) {
        .col-total-lead {
            order: 1;
        }
    }
}
.card-title-field {
    label {
        width: 100%;
    }
    &-label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .counter {
            color: #9b9b9b;
        }
    }
}

.select-users {
    &__select {
        width: 100%;
    }
    &__search {
        padding: 8px 12px 12px 12px;
    }

    &__icon {
        svg {
            width: 18px;
            height: 18px;
            color: $nobel-color;
            display: flex;
        }
    }

    &__input {
        border-radius: 4px;
    }

    &__display {
        &-name {
            color: $mid-night;
        }
        &-highlight {
            background-color: #f2d249 !important;
        }

        &-email {
            color: #9b9b9b;
        }
    }
}

.ant-select-selector {
    min-height: 36px;
    border-radius: 4px !important;
}
.user-tag {
    background-color: #e3e4e6;
    border-radius: 6px;
    padding: 5px 8px;
    margin: 2px 4px 2px 0;
    height: 24px;
    display: flex;
    align-items: center;
    gap: 6px;

    color: #4b546e;
    font-weight: 500;
    line-height: 1.33;
    font-size: 12px;

    &:hover > span > svg {
        color: #f35848;
        transition: all 0.1s;
    }

    &:hover > &-label {
        .select-users__display-name {
            color: #f35848;
            transition: all 0.1s;
        }
    }

    svg {
        width: 12px;
        height: 12px;
        cursor: pointer;
        color: #9b9b9b;

        &:hover {
            color: #f35848;
            transition: all 0.1s;
        }
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        background-color: #feefe8;
        color: #f35848;
        transition: all 0.1s;
    }
}

.assigned-users {
    label {
        width: 100%;

        div {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
}

.sales-test-btn {
    margin-top: 24px;

    &:disabled {
        color: #fff !important;
        opacity: 0.4;
    }
}

.account-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .remove {
        color: #9b9b9b;

        &:hover {
            color: #f35848;
            transition: all 0.1s;
        }
    }
}

.connect-account {
    display: flex;
    align-items: center;
    gap: 6.3px;
    color: #4b546e;
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
        background: #f5f5f5 !important;
        transition: all 0.1s;
    }
}
