@import "../../../../../../scss/helpers/variables";

.add-hash-tag-dropdown {
  width: 370px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 2px 2px 6px 0 rgba(153, 153, 153, 0.16);
  border: solid 1px $iron;
  background-color: #fff;

  .search-tag {
    margin-bottom: 10px;
    input {
      height: 30px;
    }
  }
  .list-hash-tag {
    &-item {
      display: flex;
      padding: 5px 12px;
      margin: 0 -12px;
      &:hover {
        background-color: #f7f8fb;
      }

      &__left {
        flex-grow: 1;
        cursor: pointer;
        overflow: hidden;
        .group-name {
          color: $mid-night;
          line-height: 1.43;
          letter-spacing: 0.03px;
        }
        .hashtag,.category {
          font-size: 10px;
          line-height: 1.6;
          letter-spacing: 0.02px;
          color: #738fbd;
          white-space: nowrap;
        }
      }

      svg {
        width: 14px;
        height: 15px;
        font-size: 14px;
        margin-left: 8px;
        cursor: pointer;
      }
      svg.trash-icon {
        color: $sunset-color;
        margin-left: 20px;
      }
      span {
        display: flex;
        align-items: center;
      }
    }
  }
  .ant-divider {
    color: $iron;
    margin: 8px 0;
    margin-left: -12px;
    width: calc(100% + 24px);
  }
  .add-new-hash-tag {
    margin-top: 8px;
    button {
      padding-left: 0;
    }
  }
  .group-btn {
    display: flex;
    justify-content: space-between;
  }
  .error-text {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.03px;
    color: $sunset-color;
  }
  .maximum-hashtag {
    font-size: 10px;  
    line-height: 1.6;
    letter-spacing: 0.02px;
    color: $nobel-color;

  }
  .no-tag {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.03px;
    color: #9b9b9b;
    margin-top: 8px;
  }
  textarea {
    color: #0185de;
  }
}

.ant-modal-wrap:has(.confirm-delete-tag-modal) {
  z-index: 9999;
}