.connection-rate-chart {
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;

    &__chartWrapper {
        width: 232px;
        height: 222px;
        position: relative;
    }

    &__legend {
        display: flex;
        flex-direction: column;
        width: 178px;

        &__item {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 12px;
            &__name {
                color: #9b9b9b;
                flex: 1;
            }
            div {
                &:first-child {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }
        }
    }

    &__sum {
        > div {
            display: flex;
            flex-direction: column;
            margin-bottom: 8px;

            span {
                &:first-child {
                    font-size: 12px;
                    color: #9b9b9b;
                }

                &:nth-child(2) {
                    color: #4d546c;
                    font-size: 20px;
                    font-weight: 500;
                }

                &:last-child {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }

            &:first-child {
                span {
                    &.tren {
                        color: #9bc13c;
                    }
                }
            }

            &:last-child {
                span {
                    &.tren {
                        color: #f35848;
                    }
                }
            }
        }
    }

    &__emptyChart {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 576px) {
    .connection-rate-chart {
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;

        &__sum {
            display: flex;
            width: 100%;
            >div {
                width: 50%;
            }
        }

        &__legend {
            flex-direction: row;
            width: 100%;
            justify-content: center;
            gap: 8px;
            margin-top: 16px;

            &__item {
                width: auto;
            }
        }
    }
}