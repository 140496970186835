@import "../../../../../scss/helpers/variables";

.add-user-service-modal {
    .ant-select {
        .ant-tag {
            height: 24px;
            background-color: #4b546e1A;
            border-radius: 6px;
            border: none;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.02px;
            text-align: left;
            color: $mid-night;
            vertical-align: middle;
            display: flex;
            align-items: center;
            &:hover {
                background-color: #f358481A;
                color: $sunset-color;
                svg {
                    color: $sunset-color;
                }
            }
        }
    }
    .add-user-service-form {
        .label {
            line-height: 1.43;
            letter-spacing: 0.03px;
            color: #4d546c;
            margin-bottom: 8px;
            span {
                color: $sunset-color;
            }
        }
        .ant-select {
            .ant-select-selector {
                min-height: 36px;
                border-radius: 4px;
                border-color: $iron;
            }
            .ant-select-arrow {
                svg {
                    width: 18px;
                    height: 20px;
                    line-height: 1.11;
                    letter-spacing: 0.04px;
                    color: $nobel-color;
                }
            }
        }
    }
    .ant-modal-footer {
        .ant-btn-primary[disabled] {
            opacity: 0.5;
            border-radius: 4px;
            background-color: $primaryColor;
            color: #fff;
        }
    }
}
.addable-user-select {
    .ant-select-item {
        .ant-select-item-option-content {
            .display-user-social-select {
                font-size: 14px;
                line-height: 1.43;
                letter-spacing: 0.03px;
                text-align: left;
                display: flex;
                .user-name {
                    color: $mid-night;
                    margin-right: 3px;
                    .highlight {
                        background-color: #f2d249 !important;
                    }
                }
                .email {
                    color: $nobel-color;
                }
            }
        }
    }
}