@import "../../../scss/helpers/variables";

.select-team-page {
    max-width: 600px;
    min-width: 454px;
    background: #ffffff;
    padding: 40px;
    border-radius: 4px;
    text-align: center;
    .header {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #4b546e;
    }

    .list-client {
        margin-top: 24px;
        cursor: pointer;
        .client-item {
            height: 72px;
            display: flex;
            padding: 14px;
            border-radius: 4px;
            box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
            border: solid 1px $iron;
            background-color: #fff;
            &:not(:last-child) {
                margin-bottom: 16px;
            }
            .avatar {
                margin-right: 16px;
            }
            .info {
                text-align: left;
                overflow: hidden;
                & > div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .name {
                    font-weight: 500;
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    color: $mid-night;
                }
                .email {
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    color: $nobel-color;
                }
            }
        }
    }
}
