.app-mobile-range-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: fit-content;
    border-radius: 4px;
    height: 36px;
    width: 36px;
    position: relative;
    cursor: pointer;
}

.app-mobile-range-picker-popup {
    .ant-modal-content {
        border-radius: 4px;
    }

    .ant-modal-header {
        padding: 16px;
        .ant-modal-title {
            font-weight: normal;
        }

        border-radius: 4px 4px 0 0;
    }

    .ant-modal-body {
        padding: 16px;
        // padding-bottom: 270px;
    }

    .ant-picker {
        visibility: hidden;
        height: 0;
        width: 0;
    }

    .ant-modal-footer {
        border-top: 1px solid #e3e4e6;
        padding-bottom: 10px;
        padding-top: 10px;
        border-radius: 0 0 4px 4px;
    }

    .app-mobile-range-picker__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        > button {
            color: #0185de;
        }
    }

    .app-range-picker__footer-wrapper__action-wrapper {
        border-bottom: 1px solid #e3e4e6;
        padding-bottom: 16px;
        margin-bottom: 24px;

        .active {
            color: #0185de;
            font-weight: 500;
        }
    }

    .app-mobile-range-picker-value {
        display: flex;

        span {
            height: 36px;
            border: solid 1px #e3e4e6;
            flex: 1;
            display: flex;
            align-items: center;
            padding: 0 12px;
            transition: all 0.3s;

            svg {
                width: 20px;
                height: 20px;
                color: #9b9b9b;
                margin-right: 10px;
            }

            &.active {
                border: solid 1px #0185de;
                background-color: rgba(1, 133, 222, 0.1);
                color: #0185de;

                svg {
                    color: inherit;
                }
            }

            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }

            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
        }
    }

    .app-mobile-range-picker-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        margin-bottom: 10px;

        .btn-pre,
        .btn-next {
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .ant-picker-calendar .ant-picker-panel {
        border-top: none;

        .ant-picker-content th {
            font-size: 10px;
            color: #9b9b9b;
            padding: 8px;
        }
    }

    .ant-picker-cell {
        padding: 0;

        &:not(.ant-picker-cell-in-view) {
            pointer-events: none;
            .custom-date-cell {
                display: none;
            }
        }

        &.ant-picker-cell-disabled {
            pointer-events: none;

            .custom-date-cell-value {
                border-radius: 0;
                background: rgba(0, 0, 0, 0.04);
            }

            &::before {
                display: none;
            }
        }
    }

    .custom-date-cell {
        width: 100%;
        height: 100%;

        .custom-date-cell-value {
            border-radius: 4px;
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            line-height: 24px;
            border-radius: 2px;
            transition: background 0.3s, border 0.3s;
            font-size: 13px;
        }

        &__selected {
            .custom-date-cell-value {
                color: #fff;
                background: #0185de;
                border-radius: 3px;
            }
        }

        &__inrange {
            background-color: #0185de10;
            border-bottom: 0.5px solid #d1e9f980;
            color: #0185de;
        }
    }

    .ant-picker-cell-today {
        .custom-date-cell-value {
            border: 1px solid #1890ff;
        }
    }
}
