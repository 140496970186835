@import "../../../../../../scss/helpers/variables";

.content-editor-tab {
  button {
    width: 110px;
    line-height: 1.14;
    letter-spacing: 0.03px;
    color: $nobel-color;
    padding: 9px 12px;
    justify-content: flex-start;
    border: 1px solid $iron;
    border-bottom: none;
    border-top: none;
    border-radius: 0px;
    transition: 0s;

    display: flex;
    justify-content: space-between;
    svg {
        width: 14px;
        height: 15px;
        line-height: 1.14;
        letter-spacing: 0.03px;
        color: #f59f00;
        margin-left: 6px;
    }
    img {
      opacity: 0.4;
    }

    &[ant-click-animating-without-extra-node="true"]::after {
      display: none;
    }
    &:hover {
        border: 1px solid $iron;
    }
    &:first-child {
      border-top: 1px solid $iron;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      border-bottom: 1px solid $iron;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &.selected {
      border: 1px solid $primaryColor;
      color: $mid-night;
      img {
        opacity: 1;
      }
    }
  }
}
