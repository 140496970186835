.home-performance {
    .rl-card {
        margin-bottom: 16px;
    }

    &__cardSum {
        display: flex;
        align-items: center;

        p {
            font-size: 24px;
            color: #4b546e;
            margin-bottom: 0;
            margin-right: 4px;
            font-weight: 500;
        }

        span {
            display: flex;
            align-items: center;
            margin-top: 5px;
            margin-left: 5px;
            
            &.active {
                color: #9bc13c;
            }

            &.inactive {
                color: #f35848;
            }

            svg {
                margin-left: 4px;
                height: 18px;
                width: 18px;
            }
        }
    }

    @media (min-width: 576px) and (max-width: 1200px) {

        .col-total-lead {
            order: 1;
        }

        .col-roi {
            order: 3;
        }

        .col-unique-search {
            order: 2;
        }

      }

}
