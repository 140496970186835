@import "../../../scss/helpers/variables";
.client-sites-page {
    &__tableWrapper {
        margin-top: 40px;
    }

    &__tableHeader {
        .search-input {
            width: 210px;
        }

        .filter-input {
            width: 182px;
            float: right;
        }
    }

    .dropdown-action-button {
        border: none;

        svg {
            width: 14px;
            height: 14px;
            color: inherit;
            margin-left: 5px;
        }
    }

    .ant-badge {
        display: flex;
        align-items: center;
        .ant-badge-status-text {
            white-space: nowrap;
            color: #4b546e;
        }
    }

    &__plan {
        padding: 5px 7px;
        border-radius: 12px;
        width: max-content;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        
        &.free-trial {
            background-color: #f9eaf2;
            color: #c13584;
        }
        &.premium {
            background-color: #fef5e5;
            color: #f59f00;
        }
        &.free-plan {
            background-color: #f5f9eb;
            color: #9bc13c;
        }
        &.none {
            background-color: #f5f6f9;
            color: #a1aac7;
        }
    }

    @media (max-width: 576px) {
        &__tableHeader {
            .filter-input {
                width: 139px;
            }
        }
    }
}
.edit-client-site-tab {
    .integration-tab {
        display: flex;
        align-items: center;
        svg {
            width: 14px;
            height: 15px;
            font-size: 14px;
            line-height: 1.43;
            letter-spacing: 0.03px;
            margin: 0 0 2px 4px;
            color: $sunset-color;
        }
    }
}
.exist-business-email-modal {
    width: unset !important;
}