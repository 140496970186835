@import "../../../../../../scss/helpers/variables";

.list-media-create-content {
    display: flex;
    overflow-x: auto;
}
.list-media-content-card {
    height: 150px;
    width: 100%;
    position: relative;
    .ant-carousel {
        .slick-list {
            &:has(.media-title.editing) {
                min-height: 290px !important;
                border-bottom: 1px solid #40a9ff;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            .slick-slide {
                & > div {
                    .media-item {
                        height: 150px;
                        position: relative;
                        .number-image-overlay {
                            position: absolute;
                            top: 70%;
                            bottom: 0px;
                            left: 0;
                            right: 0;
                            background-image: linear-gradient(to top, rgba(0, 0, 0, 0.92), rgba(128, 128, 128, 0) 50%);
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            font-size: 10px;
                            line-height: 1.6;
                            letter-spacing: 0.02px;
                            color: $nobel-color;
                        }
                    }
                }
            }
        }
    }
    .btn-slide {
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #fff;
        top: calc(50% - 12px);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
            width: 13px;
            height: 14px;
            line-height: 0.69;
            letter-spacing: 0.03px;
            color: $mid-night;
        }
    }
    .pre-slide {
        left: 16px;
    }
    .next-slide {
        right: 16px;
    }
}

.list-media-content-card {
    width: 100%;
    margin-top: 12px;
    .media-item {
        position: relative;
        width: 100%;
        cursor: pointer;

        img {
            height: 260px;
            width: 100%;
            object-fit: cover;
        }

        &:not(:last-child) {
            margin-right: 8px;
        }

        .image-list {
            width: 100%;
        }

        video {
            margin-right: 8px;
            width: 100%;
            height: 260px;
        }
        .overlay {
            display: none;
            width: 100%;
            height: 260px;
            top: 0;
            position: absolute;
            transition: 0.4s;
            & > div {
                position: absolute;
                width: 33px;
                height: 33px;
                border-radius: 50%;
                background-color: $mid-night;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                svg {
                    width: 18px;
                    height: 20px;
                    font-size: 18px;
                    line-height: 1.11;
                    letter-spacing: 0.04px;
                    color: #fff;
                }
            }
            .replace {
                top: 10px;
                left: 10px;
            }
            .remove {
                top: 10px;
                right: 10px;
            }
        }
        &:hover {
            .overlay {
                display: block;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(128, 128, 128, 0) 118%);
            }
        }
        .media-item-doc {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            svg {
                width: 70px;
                height: 90px;
            }
            div {
                font-size: 12px;
                line-height: 1.33;
                letter-spacing: 0.02px;
                color: #0385df;
                text-decoration: underline;
            }
        }
    }

    .media-title {
        min-height: 30px;
        height: 100%;
        background-color: $iron;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0.02px;
        color: $mid-night;
        cursor: pointer;
        .no-title {
            color: $nobel-color;
        }
        &.editing {
            textarea {
                width: 100%;
                min-height: 30px !important;
                text-align: center;
                overflow: hidden;
                resize: none;
                font-size: 12px;
                // line-height: 1.31;
                letter-spacing: 0.02px;
                // padding: 0px;
            } 
        }
        pre {
            margin: 0;
            text-align: center;
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: 0.02px;
            white-space: pre-wrap;  
            padding: 2px 8px; 
        }

        .ant-input {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .media-title-content-card {
        position: absolute;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.92) 27%, rgba(0, 0, 0, 0));
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        .title {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: 0.02px;
            color: $solitude-color;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            padding: 10px 16px;
            display: -webkit-box;
            height: 26px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    .video-with-light-box {
        pointer-events: none;
    }
}

.list-media-create-content {
    width: 100%;
    margin-top: 12px;
    
    @media (max-width: 576px) { 
        margin-top: -5px;
    }

    &.media-no-padding {
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: -6.5px;
        width: calc(100% + 40px);

        .media-item {
            img {
                border-radius: 0;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    .media-item {
        position: relative;
        margin-right: 8px;
        flex: 1;
        min-width: 138px;

        &__bg {
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;

            &__blur {
                width: 100%;
                height: 100%;
                -webkit-backdrop-filter: blur(30.4px);
                backdrop-filter: blur(30.4px);
            }
        }

        .ant-image {
            width: 100%;
        }

        img {
            height: 260px;
            width: 100%;
            border-radius: 4px;
            object-fit: contain;
        }

        &:last-child {
            margin-right: 0px;
        }

        .image-list {
            width: 100%;
        }

        video {
            width: 100%;
        }
        .overlay {
            display: none;
            width: 100%;
            height: 60px;
            top: 0;
            position: absolute;
            transition: 0.4s;
            & > div {
                position: absolute;
                width: 33px;
                height: 33px;
                border-radius: 50%;
                background-color: $mid-night;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                svg {
                    width: 18px;
                    height: 20px;
                    font-size: 18px;
                    line-height: 1.11;
                    letter-spacing: 0.04px;
                    color: #fff;
                }
            }
            .replace {
                top: 10px;
                left: 10px;
            }
            .remove {
                top: 10px;
                right: 10px;
            }
        }
        &:hover {
            .overlay {
                display: block;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(128, 128, 128, 0) 100%);
            }
        }
    }
}
