.spin-div-wrapper {
    width: 100%;
    height: fit-content;
    position: relative;

    &__loading-div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #ffffff;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
