@import "../../../../../../scss/helpers/variables";
.best-time-to-post {
  display: flex;
  &__chart {
    width: 70%;
  }
  &__legend {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 100px;
    &-items {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .legend-item {
        display: flex;
        justify-content: space-between;
        &__label {
          color: $nobel-color;
        }
        &__value {
          color: $mid-night;
        }
      }
    }
    .ant-spin-nested-loading {
      height: 100%;
      .ant-spin-container {
        height: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .best-time-to-post {
    flex-direction: column;
    &__chart {
      width: 100%;
    }
    &__legend {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 90px;
      .legend-item {
        display: flex;
        justify-content: space-between;
        &__label {
          color: $nobel-color;
        }
        &__value {
          color: $mid-night;
        }
      }
    }
  }
}
