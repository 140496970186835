@import "../../../../../scss/helpers/variables";
.social-dashboard-hashtags {
    .rl-card {
        .rl-card-title {
            margin-bottom: 16px;
        }
        .rl-card-hashtag {
            .hashtag-item {
                &-info {
                    display: flex;
                    justify-content: space-between;
                }
                height: 25px;
                margin-bottom: 17px;
                color: #9b9b9b;  
                font-size: 14px;
                line-height: 1.43;
                letter-spacing: 0.03px;
                &__count {
                    color: $mid-night;
                }
                .ant-progress {
                    .ant-progress-outer {
                        .ant-progress-inner {
                            margin-top: -12px;
                            .ant-progress-bg {
                                height: 3px !important;
                            }
                        }
                    }
                }
                &.active {
                    .hashtag-item-info {
                        &__name {
                            color: $mid-night;
                        }
                    }
                }
            }
            &.most-used-hashtag {
                .hashtag-item.active {
                    .hashtag-item-info {
                        &__count {
                            color: $primaryColor;
                        }
                    }
                    .ant-progress {
                        .ant-progress-bg {
                            background-color: $primaryColor;
                        }
                    }
                }
            }
            &.most-viewed-hashtag {
                .hashtag-item.active {
                    .hashtag-item-info {
                        &__count {
                            color: $green-color;
                        }
                    }
                    .ant-progress {
                        .ant-progress-bg {
                            background-color: $green-color;
                        }
                    }
                }
            }
            &.most-engaged-hashtag {
                .hashtag-item.active {
                    .hashtag-item-info {
                        &__count {
                            color: #f59f00;
                        }
                    }
                    .ant-progress {
                        .ant-progress-bg {
                            background-color: #f59f00;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 576px) {
    .social-dashboard-hashtags {
        .ant-row {
            .ant-col {
                margin-bottom: 8px;
            }
        }
    }
}