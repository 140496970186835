
.overview-form {
    .ant-form-item {
        color: #4d546c;
        .ant-form-item-label {
            > label {
                color: #4d546c;
            }
        }
        .ant-badge-status-text {
            color: #4d546c;
        }
    }
}