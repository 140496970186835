.sign-in-form {
    width: 100%;
    background: #ffffff;
    padding: 40px;
    border-radius: 4px;

    .header {
        margin-bottom: 32px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #4b546e;
    }

    .forgot-div {
        position: relative;

        .forgot-link {
            position: absolute;
            right: 0px;
            top: -3px;
            cursor: pointer;
            color: #0185de;
            z-index: 1;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__google {
        color: #dd4b39;
        margin-right: 7px;
        display: block;
        margin-top: 3px;

        svg {
            width: 15px;
            height: 15px;
        }
    }

    &__alertSuccess {
        color: #4b546e;
        margin-bottom: 32px;
        p {
            margin-bottom: 0;
        }
    }
}
