@import "../../../../../../../scss/helpers/variables";

.btn-advanced-setting {
    &:hover {
        background-color: $mid-night;
        border-color: $mid-night;
        color: #FFF !important;
        outline: none;
    }
}

.advanced-setting-collapse {
    border-color: $iron;
    background-color: #fff;
    .ant-collapse-item {
        border: 1px solid $iron !important;
        border-radius: 4px !important;
        .ant-collapse-header {
            background-color: #fff;
            padding: 11px 20px !important;
            height: 40px;
            border-radius: 4px !important;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            .ant-collapse-expand-icon {
                svg {
                    width: 18px;
                    height: 18px;
                    font-weight: normal;
                }
            }
            .ant-collapse-header-text {
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: $mid-night;
            }
        }
        &.ant-collapse-item-active {
            .ant-collapse-header {
                border-bottom: none;
                .ant-collapse-header-text {  
                    font-weight: 500;
                }
            }
        }
        .ant-collapse-content-box {
            padding-bottom: 0px !important
        }
    }
}

.advanced-setting-modal {
    width: 600px !important;
    max-width: 600px !important;
}

.select-category {
    .search-category {
        padding: 12px;
        display: flex;
        .ant-input-affix-wrapper {
            padding: 0 4px 0 0;
            input {
                height: 36px;
                padding: 0 12px;
                &:focus {
                    border-color: none;
                }
            }
            .ant-input-suffix {
                svg {
                    color: $nobel-color;
                }
            }
        }
        .refresh-btn {
            height: 38px;
            width: 38px;
            border-radius: 4px;
            border-color: $iron;
            padding: 0;
            margin-left: 8px;
            svg {
                margin: 0;
                width: 18px;
                height: 20px;
                color: $nobel-color;
                &:hover {
                    color: #000;
                }
            }
        }
    }
    .display-property {
        line-height: 1.43;
        letter-spacing: 0.03px;
        .property-name {  
            color: $mid-night;
            .highlight {
                background-color: #f2d249 !important;
            }
        }
        .property-id {
            color: $nobel-color;
        }
    }
}

.advanced-setting-form {
    .ant-form-item-label {
        height: 26px;
        label {
            svg {
                width: 16px;
                height: 16px;
                margin-left: 4px;
            }
            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: #e3e4e6;
                cursor: pointer;
                margin-left: 4px;
          
                span {
                  font-size: 11px;
                  line-height: 1.82;
                  letter-spacing: 0.02px;
                }
          
                svg {
                  width: 11px;
                  height: auto;
                }
            }
        }
    }
    .tag-setting {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 6px;
        border: 1px solid $iron;
        background-color: #fff;
        width: 100%;
        border-radius: 4px;
        .ant-tag {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33;
            letter-spacing: 0.02px;
            color: $mid-night;
            min-height: 24px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            background-color: #4b546e1E;
            border: none;
            margin-bottom: 8px;
            svg {
                width: 12px;
                height: 12px;
                margin-bottom: -2px;
            }
            &:hover {
                background-color: #f3584820;
                svg {
                    color: $sunset-color;
                }
            }
            .tag-title {
                width: 100%;
                word-break: break-all;
                white-space: break-spaces;
            }
        }
        input {
            border: none;
            outline: none;
            height: 24px;
            width: auto;
            padding: 0;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33;
            letter-spacing: 0.02px;
            color: $mid-night;
            flex-grow: 1;
            &:focus {
                border: none;
                outline: none;
                box-shadow: none;
            }
            &:disabled {
                background-color: #fff;
            }
        }

        &.disabled {
            background-color: #f5f5f5;
            input {
                background-color: #f5f5f5;
            }
        }
    }
    .setting-description {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0.02px;
        color: $nobel-color;
    }
}