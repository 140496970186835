.admin-layout {
    width: 100%;
    background-color: #f7f8fb;
    height: fit-content;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 187px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;

    .page-header {
        height: 90px;
    }

    .header-overlay {
        padding-bottom: 130px;
        margin-bottom: -130px;
    }
}
