
.media-item__bg {
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    &__blur {
        .ant-image {
            width: 100%;
            cursor: pointer;
        }
    }
}