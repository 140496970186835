@import "../../../../scss/helpers/variables";
.profile-service-tab {
    .btn-service-dropdown {
        margin-bottom: 17px;
        border: solid 1px $iron;
        display: flex;
        justify-content: space-between;
        min-width: 100px;
        max-width: 250px;
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.03px;
        .label {
            color: $nobel-color;
            margin-right: 2px;
        }
        .value {
            font-weight: 500;
            color: $mid-night;
            margin-right: 2px;
            flex-grow: 1;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        svg { 
            margin: 0;
        }
    }
    .client-name-service-table {
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: $primaryColor;
        cursor: pointer;
    }
}

.list-service-dropdown {
    border-radius: 4px;
    box-shadow: 2px 2px 6px 0 rgba(153, 153, 153, 0.16);
    border: solid 1px $iron;
    background-color: #fff;
    width: 280px;
    padding: 12px;
    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        .ant-checkbox-group-item:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}