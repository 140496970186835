.engagement-social {

    &__content {
        border: 1px solid;
        padding: 16px;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.3s;
        height: 100%;

        p {
            margin-bottom: 0;
        }

        &:not(.active) {
            border-color: #e3e4e6 !important;
        }

        &__legend {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            display: block;
            margin-right: 5px;
        }

        &__title {
            font-size: 12px;
            color: #9b9b9b;
            display: flex;
            align-items: center;

            >span:last-child {
                flex: 1;
            }
        }

        &__value {
            font-size: 20px;
            font-weight: 500;
            color: #4d546c;
            display: flex;
            justify-content: space-between;

            .volatility {
                margin-left: 7px;
                margin-top: 4px;
                span {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                }
    
                svg {
                    width: 18px;
                    height: 18px;
                    margin-left: 3px;
                }
            }
        }

    }

    &__itemWrapper {
        margin-top: 20px;
        margin-bottom: -20px !important;
    }

    &__item {
        flex: 1;
        margin-bottom: 20px;
    }

    @media only screen and (max-width: 900px) {
        &__item {
            flex: 0 0 100%;
        }
    }

    @media only screen and (max-width: 576px) {
        .engagement-social__itemWrapper {
            margin-bottom: 0px !important;
        }
    }
}
