@import "../../../../../../../scss/helpers/variables";

.ant-tab-content-caption {
    .ant-tabs-nav {
        margin: 0 -20px 16px;
        padding-left: 20px;
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    padding: 8px 8px;
                    border: 1px solid #f0f0f0;
                    border: none;
                    border-bottom: 1px solid #f0f0f0;
                    background-color: #fff;
                    transition: 0.4s;
                    margin-left: 0;
                    .ant-tabs-tab-btn {
                        color: $nobel-color;
                        svg {
                            width: 14px;
                            height: 14px;
                            margin-bottom: -2px;
                        }
                    }
                    &-active {
                        border: 1px solid #f0f0f0 !important;
                        border-bottom: none !important;
                        border-radius: 8px 8px 0 0;
                        .ant-tabs-tab-btn {
                            color: $primaryColor;
                        }
                    }
                    &:hover {
                        border: 1px solid #f0f0f0 !important;
                        border-bottom: none !important;
                        border-radius: 8px 8px 0 0;
                        background-color: #f7f8fb;
                        .ant-tabs-tab-btn {
                            color: $mid-night;
                        }
                    }
                    
                }
            }
        }
    }
    .ant-tabs-content-holder {
        margin-bottom: 16px;
        .ant-tabs-content {
            .update-button {
                display: flex;
                & > div {
                    p {
                        margin-bottom: 8px;
                        color: $mid-night;
                    }
                    .ant-select {
                        width: 100%;
                    }
                }
                .add-button {
                    width: 33%;
                    margin-right: 8px;
                }
                .link-button {
                    flex-grow: 1;
                    .error {
                        color: $sunset-color;
                        font-size: 12px;
                        line-height: 1.33;
                        letter-spacing: 0.02px;
                        margin-top: 2px;
                    }
                    .ant-input-affix-wrapper {
                        height: 36px;
                        input {
                            height: 28px;
                            text-overflow: ellipsis;
                        }
                        .ant-input-suffix {
                            span {
                                cursor: pointer;
                                svg {
                                    width: 16px;
                                    height: 16px;
                                    color: $sunset-color;
                                    margin-bottom: -2px;
                                }
                            }
                        }
                    }
                    input {
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
    .event-post-action {
        svg {
            color: $nobel-color;
        }
        height: 36px;
        width: 40px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: #f7f8fb;
            border: 1px solid #f0f0f0;
            border-radius: 8px 8px 0 0;
            border-bottom: none;
            cursor: pointer;
        }
    }
    &.no-margin {
        .ant-tabs-content-holder {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 576px) {
    .ant-tab-content-caption {
        .ant-tabs-content-holder {
            .ant-tabs-content {
                .update-button {
                    display: flex;
                    flex-direction: column;
                    .add-button {
                        margin-bottom: 8px;
                        width: 100%;
                    }
                }
            }
        }
    }

}