
@import "../../../../../../../scss/helpers/variables";

.write-with-ai {
    .btn-write-with-ai {
        height: 16px;
        width: 90px;
        padding: 2px 6px;
        border-radius: 6px;
        border: solid 1px #b956c2;
        background-color: #fff;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.02px;
        color: $purple-color;
        transition: 0.3s;
        span {
            display: flex;
            align-items: center;
        }
        svg {
            width: 10px;
            height: 10px;
            color: $purple-color;
        }
        &:hover {
            background-color: $purple-color;
            color: #FFF;
            svg {
                color: #FFF;
            }
        }
    }
}