@import "../../../scss/helpers/variables";

.invite-team-form {
    width: 100%;
    background: #ffffff;
    padding: 40px;
    border-radius: 4px;
    text-align: center;
    .header {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #4b546e;
    }

    .description {
        color: $mid-night;
        margin-bottom: 0;
        margin-top: 24px;
        text-align: left;
    }
    
    form {
        margin-top: 24px;
    }

    &__termsCondition {
        font-weight: 500;
        a {
            &:hover {
                text-decoration: underline;
                color: $nobel-color;
            }
        }
    }
}
