.users-page {
    .page-header {
        height: 90px;
    }

    &__tableWrapper {
        margin-top: 40px;
    }

    &__tableHeader {
        .search-input {
            width: 210px;
        }

        .filter-input {
            width: 182px;
            display: flex;
            justify-content: space-between;

            svg {
                color: #9b9b9b;
            }
        }
    }

    .dropdown-action-button {
        border: none;

        svg {
            width: 14px;
            height: 14px;
            color: inherit;
            margin-left: 5px;
        }
    }

    @media (max-width: 576px) {
        .table-action {
            flex-direction: column;
            &__left {
                margin-bottom: 16px;
            }
        }
    }
}