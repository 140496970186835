@import "../../../scss/helpers/variables";

.usage {
    margin-bottom: 24px;
    &__heading {
        color: #4d546c;
        font-size: 16px;
        font-weight: 500;
        padding: 16px 20px;
    }

    &__divider {
        margin-top: 0;
    }

    .ant-card-body {
        padding: 0px;
    }

    .ant-card {
        border-radius: 4px;
    }

    &__infinite {
        font-size: 15px;
        font-weight: 600;
    }

    &__wrapper {
        padding: 0 20px;
        padding-bottom: 24px;

        .item {
            padding: 16px;
            border-radius: 4px;
            box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
            border: solid 1px #e3e4e6;
            background-color: #fff;
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;
            .icon-color {
                width: 40px;
                height: 40px;
                padding: 6px 8px;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    color: #fff;
                }

                &.blue {
                    background-color: #0185de;
                }
                &.gray {
                    background-color: #9b9b9b;
                }
                &.light-green {
                    background-color: #9bc13b;
                }
                &.parakeet {
                    background-color: #2fb344;
                }
                &.steel {
                    background-color: #738fbd;
                }
                &.purple {
                    background-color: #b956c2;
                }
                &.storage {
                    background-color: #0bacd2;
                }
            }
            .title {
                font-weight: 500;
                color: #4b546e;
                display: flex;

                &.gray {
                    color: #9b9b9b;
                }
            }
            .desc {
                color: #9b9b9b;

                display: flex;
                align-items: center;
                gap: 4px;

                svg {
                    width: 16px;
                    height: 16px;
                }

                .circle-icon {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}
