@import "../../../../scss/helpers/variables";

.share-link-modal {
    .get-link-block {
        display: flex;
        .btn-link-icon {
            background-color: #f7f8fb;
            border-color: $iron;
            border-right: none;
            border-top-right-radius: 0%;
            border-bottom-right-radius: 0%;
            width: 36px;
            padding: 0 10px;
            svg {
                margin: 0;
                color: $mid-night;
            }
        }
        .btn-link-text {
            flex-grow: 1;
            border-radius: 0%;
            border-color: $iron;
            overflow: hidden;
            text-align: left;
            visibility: visible;
            span {
                overflow: hidden;
                width: 100%;
                text-overflow: ellipsis;
            }
        }
        .btn-copy {
            border-top-left-radius: 0%;
            border-bottom-left-radius: 0%;
            svg {
                margin-right: 8px;
            }
        }
    }
}
