@import "../../../../../scss/helpers/variables";
.social-dashboard-overview {
    .follower-chart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -15px;

        .follower-chart-detail {
            width: 33%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .ant-badge-status-text {
                    font-size: 12px;
                    line-height: 1.33;
                    letter-spacing: 0.02px;
                    text-align: left;
                    color: $nobel-color;
                }
                .follower-number {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.4;
                    letter-spacing: 0.04px;
                    color: #4d546c;
                }
            }
        }
        .follower-chart-line {
            width: 66%;
        }
    }
    
    .ant-row {
        margin-bottom: 16px;
    }

}

@media (max-width: 576px) {
    .social-dashboard-overview {
        .ant-row {
            margin-bottom: 0px;
            .ant-col {
                margin-bottom: 8px;
            }

            .follower-chart {
                flex-direction: column;
                .follower-chart-detail {
                    flex-direction: row;
                    width: 100%;
                    & > div {
                        width: 50%;
                    }
                }
                .follower-chart-line {
                    width: 100%;
                }
            }
        }
    }

}