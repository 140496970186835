@import "../../../../../scss/helpers/variables";

.uppy-Container {
  .uppy-Dashboard-inner {
    width: 100% !important;
    height: 400px !important;
    background-color: transparent;
    border: none;
    .uppy-Dashboard-AddFiles {
      margin: 0;
    }
  }
}

.upload-media-file {
  background-color: #f7f8fb;

  &.isModal {
    padding: 16px;
    border-radius: 0 0 4px 4px;
  }

  .header {
    padding-bottom: 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &.isModal {
      padding: 12px;
      border-top: solid 1px #e3e4e6;
      border-left: solid 1px #e3e4e6;
      border-right: solid 1px #e3e4e6;
    }

    background-color: #f7f8fb;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      label {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .secondary-btn:disabled {
        background-color: #d1e1ae;
        border-color: #d1e1ae;
        color: #fff;
      }
    }
  }

  .dragdrop-area {
    background-color: #fff;
    border: 1px solid #e3e4e6;
    display: flex;

    &.centered {
      &:hover {
        .empty-text {
          color: #0185de;
        }
      }
    }

    .empty-text {
      min-height: 239px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 16px;
      font-size: 13px;
      color: #9b9b9b;

      &.dragging {
        color: #0185de;
      }
    }

    &.centered {
      align-items: center;
      justify-content: center;
    }

    .scroll-upload {
      height: 5px;
      background-color: $iron;
      width: 100%;

      overflow: hidden;
      .scroll-bar {
        height: 100%;
        background-color: $green-color;
        border-radius: 4px;
        // animation-name: move;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-direction: right;
        animation-timing-function: linear;
      }
      @keyframes move {
        0% {
          margin-left: -30%;
        }
        100% {
          margin-left: 100%;
        }
      }
    }

    p {
      color: #0185de;

      &:hover {
        opacity: 0.9;
        transition: all ease-in-out 0.3s;
      }
    }
    .progress-bar {
      position: absolute;
      top: 0px;
    }
    .ant-progress-status-success .ant-progress-bg {
      background-color: $green-color !important;
    }
    .uploaded-list {
      min-height: 223px;
      margin-top: 16px;
      padding: 0px 12px;

      .uploaded-item-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .uploaded-item {
          display: flex;
          align-items: center;
          gap: 16px;
          inline-size: 75%;

          .thumbnail {
            position: relative;

            .duration {
              position: absolute;
              bottom: 6px;
              right: 0;
              padding: 4px 10px;
              font-size: 10px;
              border-radius: 4px;
              background-color: rgba(0, 0, 0, 0.5);
              color: #fff;
              font-size: 500;
            }
          }
         
          video,
          img {
            object-fit: cover;
            width: 104px;
            height: 104px;
            border-radius: 4px;
          }

          .details {
            color: #4b546e;
            width: 100%;

            .quantity {
              text-align: left;
            }

            .file-title {
              text-align: left;
              margin-bottom: 6px;
            }

            .status {
              margin-left: 22.4px;
              padding: 2px 6px;
              font-weight: 500;
              font-size: 10px;
              border-radius: 6px;
              padding-bottom: 3px;
              &.pending {
                background-color: #fdeccf;
                color: #f59f00;
              }

              &.success {
                background-color: #f6ffed;
                color: #52c41a;
              }

              &.error {
                background-color: #fff2f0;
                color: #ff4d4f;
              }
            }
          }
        }

        .close-red-icon {
          color: #f35848;
          cursor: pointer;
          margin-right: 12px;
        }
      }
    }
  }
}

.ant-upload.ant-upload-drag .ant-upload-btn {
  display: unset !important;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: unset !important;
}
