.edit-notifications {
    .card-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .card {
            padding: 16px 20px;
            border-radius: 4px;
            border: solid 1px #e3e4e6;
            background-color: #fff;
            height: 100%;
            
            .card-title {
                color: #4d546c;
                font-weight: 500;
            }
    
            .switches {
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                gap: 12px;
    
                color: #4d546c;
    
                div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
       
    }


}