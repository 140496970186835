.home-call-web-form {
    &__sumValue {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 0;
        color: #4b546e;
    }

    &__details {
        display: flex;

        > div {
            display: flex;
            flex-direction: column;

            > div {
                display: flex;
                align-items: center;
                margin-right: 25px;
                span {
                    &:first-child {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }

                    &:last-child {
                        font-size: 12px;
                        color: #9b9b9b;
                    }
                }
            }

            > p {
                color: #4d546c;
                font-weight: 500;
                font-size: 20px;
            }
        }
    }

    .form-submission-table {
        margin-left: -16px;
        margin-right: -16px;
        margin-top: 2px;
        margin-bottom: -16px;

        .ant-table {
            background-color: transparent;
        }

        .ant-table-thead {
            font-size: 10px;
            text-transform: uppercase;

            > tr > th {
                border: none;
                background-color: #f2f3f4;
                color: #9b9b9b;

                &::before {
                    display: none;
                }
            }
        }

        .ant-table-cell {
            padding: 9px 9px;

            &:first-child {
                > span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                }
            }
        }

        .ant-table-tbody > tr > td {
            border: none;
        }
    }
}
