.react-tel-input {
    display: flex;
    text-align: left;

    .form-control {
        flex: 1;
    }

    .search-emoji {
        display: none;
    }

    .country-list .search {
        display: flex;
        .search-box {
            flex: 1;
        }
    }
}
