@import "./helpers/variables";

::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

.auth__backLink {
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-top: 16px;
    color: #e3e4e6;

    &:hover {
        text-decoration: underline;
        color: #e3e4e6;
    }
}

.auth__form {
    width: 100%;
    background: #ffffff;
    padding: 40px;
    border-radius: 4px;
    text-align: center;
}

.app-body {
    flex: 1;
}

.app-footer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding: 0 16px;
    z-index: 999;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #4b546e;
}

.page-container-width {
    width: 100%;
    max-width: 1296px;
    margin: 0 auto;
    padding: 0 20px;
}

.page-header {
    height: 115px;
    display: flex;
    align-items: center;

    &__title {
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
    }
}

.rl-card {
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
    border: solid 1px #e3e4e6;
    background-color: #fff;
    padding: 16px;
}

.rl-card-title {
    font-size: 10px;
    color: #9b9b9b;
    font-weight: 500;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    :first-child {
        text-transform: uppercase;
    }
}

.rl-card-count {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.05px;
    color: #4d546c;
}

.admin-page-title {
    color: #ffffff;
}

.chart-disabled {
    > * {
        pointer-events: none;
    }
}

.table-cell {
    > p {
        margin-bottom: 0;
    }

    > span {
        color: #9b9b9b;
    }
}

.table-action {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__right,
    &__left {
        display: flex;

        > :not(:last-child) {
            margin-right: 16px;
        }
    }
}

.password-hint-wrapper {
    margin-top: 8px;
    margin-bottom: 32px;
}

.password-hint-item {
    font-size: 10px;
    display: flex;
    align-items: center;
    font-weight: 500;

    svg {
        width: 10px;
        height: 10px;
        margin-right: 3px;
    }

    &.active {
        color: $green-color;
    }
}

.custom-create-modal {
    width: 90%;
    max-width: 454px;
    .ant-modal-content {
        border-radius: 8px;
        .ant-modal-header {
            border-radius: 8px 8px 0 0;
        }
        .ant-modal-body {
            border-radius: 8px;
        }
        .ant-modal-footer {
            border-radius: 0 0 8px 8px;
        }
        .ant-modal-footer,
        .ant-modal-body {
            background-color: #f7f8fb;
        }
    }
}
.upgrade-modal {
    width: 90%;
    max-width: 500px;
    .ant-modal-content {
        border-radius: 4px;
    }
    .ant-modal-body {
        padding: 0;
    }
}

.filter-wrapper {
    width: 100%;
    box-shadow: 2px 2px 6px 0 rgba(153, 153, 153, 0.16);
    border: solid 1px #e3e4e6;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 12px;

    .filter-section {
        width: 100%;
        display: flex;
        flex-direction: column;

        &__title {
            color: #9b9b9b;
            text-transform: uppercase;
            font-size: 10px;
            margin-bottom: 8px;
        }

        &:last-child {
            .filter-item {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .filter-item {
        margin-bottom: 8px;
    }
}

.table-row-action {
    padding: 4px 8px;
}

.delete-ghost-button,
.delete-ghost-button:hover {
    color: #f35848;
}

.confirm-modal {
    .ant-modal-content {
        width: 550px;
        border-radius: 8px;

        @media (max-width: 576px) {
            width: auto;
        }
    }

    &.confirm-modal-no-footer {
        .ant-modal-confirm-btns {
            display: none;
        }
    }
}

.confirm-delete-modal {
    top: -100px !important;
    .ant-btn-primary {
        background-color: #f35848;
        border-color: #f35848;
    }
}

.app-edit-drawer {
    .ant-drawer-footer {
        border-top: none;
        padding: 24px;
    }
    .drawer-footer-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .ant-select-selection-item .remove {
        display: none;
    }

    .connection-alert {
        margin-bottom: 16px;
    }

    .ant-select-selector .select-users__display-email {
        display: none;
    }
}

.form-item-has-extra {
    display: flex;
    flex-direction: column;

    p {
        margin-bottom: 8px;
    }

    span {
        font-size: 12px;
        color: #9b9b9b;
    }
}

.ant-btn-primary.save-btn:disabled {
    opacity: 0.5;
    background-color: $primaryColor;
    color: #fff;
}

.d-flex {
    display: flex;
}
.d-flex-column {
    display: flex;
    flex-direction: column;
}
.align-center {
    align-items: center;
}
.trumbowyg-box {
    border-radius: 4px;
    border: solid 1px $iron;
}
.trumbowyg-button-pane {
    border-radius: 4px;
}

#react-trumbowyg {
    background: #ffffff;
    border-radius: 4px;
}

@media (max-width: 576px) {
    .header-wrapper {
        background-repeat: no-repeat;
        background-position: top center;
        background-size: auto;
    }
    .page-container-width {
        padding: 0 8px;
    }

    /* modal */
    .ant-modal {
        max-width: calc(100vw - 16px) !important;
    }

    /* drawer */
    .ant-drawer-content-wrapper {
        width: 100vw !important;
    }
}

@media (max-width: 992px) {
    .ant-table-content {
        overflow: auto;
    }
}

.modal-radius-4 {
    .ant-modal-content {
        border-radius: 4px;
        .ant-modal-header {
            border-radius: 4px 4px 0 0;
        }
        .ant-modal-footer {
            border-radius: 0 0 4px 4px;
        }
    }
}

.circle-question {
    border-radius: 50%;
    background-color: #e3e4e6;
    width: 18px;
    height: 18px;
    padding-top: 1px;
    padding-left: 1px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.custom-popover {
    width: 500px;

    .ant-popover-inner {
        border-radius: 4px;
        margin-top: -14px;
    }

    .ant-popover-inner-content {
        padding: 0;
    }
}

.date-range-picker-box {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #fff;
    padding: 9px 17px 8px 12px;
    border-radius: 4px;
    height: 36px;

    svg {
        color: #9b9b9b;
        width: 18px;
        height: 18px;
    }
}

.page-header-with-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    
    .icon-edit,
    .icon-refresh {
        cursor: pointer;
        width: 20px;
        height: 20px;
        color: #858994;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        &:hover {
            color: #fff;
            transition: all ease-in-out 0.1s;
        }
        &.social-dashboard {
            color: #979aa3;
            &:hover {
                color: #4b546e;
                transition: all ease-in-out 0.1s;
            }
        }

        svg {
            stroke-width: 2;
        }

        &.syncing {
            margin-bottom: 0;
        }
    }

    .icon-edit {
        svg {
            width: 20px;
            height: 20px;
        }
    }

    .sync-status {
        color: #858994;
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 10px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.add-org-icon svg {
    width: 14px;
    height: 14px;
    margin-top: 5px;
}

.spin-animation {
    animation:spin 1s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(-360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(-360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(-360deg); 
        transform:rotate(-360deg); 
    } 
}

.external-link {
    &:hover {
        text-decoration: underline;
    }
}