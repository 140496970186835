@import "../../../../scss/helpers/variables";

.add-connect-wrapper {
    text-align: center;
    position: relative;
    .title {
        margin: 24px 0 8px 0;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.47;
        color: $mid-night;
    }
    .description {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0.02px;
        color: #4d546c;
    }
    .btn-connect {
        margin: 24px auto;
    }

    .btn-connect-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: center;
    }
}