.calendar-content-drawer {
  z-index: 999;
  .ant-drawer-mask {
    z-index: 999;
  }
  .ant-drawer-content-wrapper {
    width: 480px !important;
    .ant-drawer-body {
      .content-card {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
