$primaryColor: #0185de;
$primaryHoverColor: #0067ab;
$secondaryColor: #9bc13c;
$secondaryHoverColor: #738f2c;

$nobel-color: #9b9b9b;
$green-color: #9bc13c;
$mid-night: #4b546e;
$iron: #e3e4e6;
$sunset-color: #f35848;
$blue-color: #0185de;

$yellow-color: #f6af2d;
$light-yellow-color: #f8f2e7;
$purple-color: #b956c2;
$solitude-color: #f7f8fb;