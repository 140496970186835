.home-page {
    position: relative;
    .page-header__title {
        color: #e3e4e6;
    }

    &__session:not(:last-child) {
        margin-bottom: 30px;
    }

    &__sessionTitle {
        font-size: 20px;
        color: #a1aac7;
        font-weight: 500;
    }

    .page-header {
        .ant-row {
            width: 100%;

            .ant-col {
                &:last-child {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}
@media (max-width: 576px) {
    .home-page {
        &__session:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}
