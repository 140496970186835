.forgot-password-form {
    width: 100%;
    background: #ffffff;
    padding: 40px;
    border-radius: 4px;

    &__header {
        margin-bottom: 32px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #4b546e;
    }

    &__description {
        margin-bottom: 20px;
    }

    .ant-btn {
        .email-icon {
            color: #ffffff;
            margin-right: 7px;
            display: block;
            margin-top: 5px;

            svg {
                width: 17px;
                height: 17px;
            }
        }
    }

    &__submited {
        text-align: center;

        p {
            margin-bottom: 0;
        }

        img {
            margin-bottom: 18px;
        }
    }
}
