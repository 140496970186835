@import "../../../../../../scss/helpers/variables";
.total-engagement-table {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 36px;
    margin-bottom: -16px;

    .ant-table {
        background-color: transparent;
    }

    .ant-table-thead {
        font-size: 10px;
        text-transform: uppercase;

        > tr > th {
            border: none;
            background-color: #f2f3f4;
            color: #9b9b9b;

            &::before {
                display: none;
            }
        }
    }

    .ant-table-cell {
        padding: 9px 16px;

        &:first-child {
            > span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
        }

        .see-content {
            line-height: 1.43;
            letter-spacing: 0.03px;
            color: $primaryColor;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    .ant-table-tbody > tr > td {
        border: none;
        border-bottom: 1px solid $iron;
        padding: 17px 16px;
    }

    .total-engagement-chart {
        margin-bottom: -29px;
    }
}