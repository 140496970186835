@import "../../../../../../scss/helpers/variables";
.activity-item {
    display: flex;
    margin-bottom: 16px;
    .activity-item__icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        margin-right: 9px;
        .overlay {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: $nobel-color;
            opacity: 0.1;
        }
        .icon {
            position: absolute;
            color: $nobel-color;
            opacity: 1;
            width: 16px;
            height: 16px;
            svg {
                width: 16px;
                height: 16px;
                line-height: 1.56;
                letter-spacing: 0.03px;
            }
        }
    }
    .activity-item__detail {
        .activity-item__detail-description {
            font-size: 14px;
            line-height: 1.43;
            letter-spacing: 0.03px;
            color: $mid-night;
            
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            span {
                span {
                    color: #3a83d7;
                    cursor: pointer;
                }
            }
        }
        .activity-item__detail-time {
            font-size: 10px;
            line-height: 1.6;
            letter-spacing: 0.02px;
            color: $nobel-color;
        }
    }
    &.activity-item-red {
        .activity-item__icon {
            .overlay {
                background-color: #e16351;
            }
            .icon {
                color: $sunset-color;
                svg {
                    color: $sunset-color;
                }
            }
        }
        .activity-item__detail {
            .activity-item__detail-description {
                color: $sunset-color;
            }
        }
    }
    &.activity-item-green {
        .activity-item__icon {
            .overlay {
                background-color: #9bc13c;
            }
            .icon {
                color: #9bc13c;
                svg {
                    color: #9bc13c;
                }
            }
        }
        .activity-item__detail {
            .activity-item__detail-description {
                color: #9bc13c;
                font-weight: 500;
            }
        }
    }
}