@import "../../../../../../scss/helpers/variables";

.content-editor-wrap {
    display: flex;
}

.content-editor {
    flex-grow: 1;
    padding: 16px 20px 0px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
    border: solid 1px $iron;
    background-color: #fff;
    min-height: 136px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: 576px) {
        padding: 16px 20px 0px 20px;
    }

    &-tag {
        display: flex;
        justify-content: space-between;
        .list-tag {
            display: flex;
            flex-wrap: wrap;
            .tag-social {
                margin-right: 4px;
                margin-bottom: 6px;
            }
        }
        .warning-limit {
            margin: -8px -10px 0 0;
            svg {
                color: #f59f00;
            }
        }
    }

    &-avatar {
        display: flex;
        margin: 0 0 17px 0;
        .avatar {
            margin-right: 10px;
        }
        .name-and-date {
            display: flex;
            flex-direction: column;
            .name {
                height: 17px;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: #4d546c;
            }
            .date {
                height: 12px;
                font-size: 10px;
                line-height: 2;
                letter-spacing: 0.02px;
                color: $nobel-color;
            }
        }
        &.no-profile {
            opacity: 0.5;
        }
    }

    &-tab {
        .ant-tabs {
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    padding-bottom: 6px;
                    color: $nobel-color;
                    span {
                        font-size: 14px;
                        line-height: 1.14;
                        letter-spacing: 0.03px;
                        display: flex;
                        align-items: center;
                        img {
                            width: 14px;
                            margin-right: 6px;
                            opacity: 0.4;
                        }
                    }
                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: $mid-night;
                            img {
                                opacity: 1;
                            }
                        }
                    }
                    & + .ant-tabs-tab {
                        margin-left: 16px;
                    }
                }
            }
        }
    }
    &-tab-mobile {
        display: flex;
        width: 100%;
        margin-bottom: 16px;
        button {
            width: 50%;
            border-radius: 4px;
            border: solid 1px $iron;
            color: $mid-night;
            &.active {
                border: solid 1px $primaryColor;
                background-color: rgba(1, 133, 222, 0.1);
                color: $primaryColor;
            }
        }
        .tab-post {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .tab-reel {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &-title {
        textarea {
            border: none;
            outline: none;
            box-shadow: none !important;
            padding-left: 0;
            z-index: 1;
            background-color: transparent;
            font-weight: 500;
            &:placeholder-shown {
                font-weight: 500;
            }
        }
    }
    &-caption {
        flex-grow: 1;
        overflow: auto;
        position: relative;
        &::-webkit-scrollbar {
            height: 5px;
            width: 6px;
        }
        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-track-piece {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: $nobel-color;
            border: none;
        }
        &__mask,
        textarea {
            resize: none;
            border: none;
            outline: none;
            box-shadow: none !important;
            padding-left: 0;
            padding-bottom: 10px;
            padding-right: 0;
            min-height: 60px;
            z-index: 1;
            background-color: transparent;
        }

        textarea {
            color: rgba(0, 0, 0, 0.2);
        }

        textarea.read-only {
            color: #000;
        }

        &__mask {
            height: 100%;
            width: 100%;
            padding-top: 4px;
            position: absolute;
            white-space: pre-wrap;
            overflow-wrap: break-word;
            overflow: hidden;
            span {
                color: #0185de;
                &.highlight {
                    background-color: #b956c21a;
                    color: $mid-night;
                }
            }
        }

        .hashtag,
        .category {
            color: #0185de;
            margin-bottom: 16px;
        }
    }
    &-footer {
        display: flex;
        justify-content: space-between;
        height: 18px;
        &__left {
            display: flex;
            & > div {
                margin: 0 16px 0 0;
            }
            svg {
                width: 16px;
                height: 18px;
                font-size: 16px;
                line-height: 1.25;
                letter-spacing: 0.03px;
                color: $mid-night;
                cursor: pointer;
                &:hover {
                    color: $primaryColor;
                }
            }

            .emoji-wrap {
                .emoji-picker {
                    left: -34px;
                }
            }

            .user.active {
                svg {
                    color: $primaryColor;
                }
            }
        }
        &__right {
            display: flex;
            .limit-character {
                display: flex;
                align-items: center;
                margin-left: 6px;
                svg,
                span {
                    font-size: 10px;
                    line-height: 1.6;
                    letter-spacing: 0.02px;
                    color: $nobel-color;
                }
                svg,
                img {
                    width: 10px;
                    height: 10px;
                }
                img {
                    margin-bottom: 2px;
                }
                span {
                    margin-left: 4px;
                }
                .mb-2 {
                    svg {
                        margin-bottom: 2px;
                    }
                }
                .icon-tabler-brand-linkedin {
                    margin-bottom: 2px;
                }
            }
            .over-limit {
                svg,
                span {
                    color: $sunset-color;
                    fill: $sunset-color;
                }
            }
        }
    }
    .media {
        width: calc(100% + 40px);
        margin-left: -20px;
        .alert-media-youtube {
            height: 32px;
            background-color: #fef5e5;
            font-size: 11px;
            line-height: 1.82;
            letter-spacing: 0.02px;
            color: $mid-night;
            margin-left: -20px;
            margin-right: -20px;
            margin-bottom: -12px;
            margin-top: 15px;
            padding-left: 20px;
            width: calc(100% + 40px);
            display: flex;
            align-items: center;
            svg {
                color: #f59f00;
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }
        .list-media-content-card {
            height: unset;
            .media-item {
                @media (min-width: 576px) {
                    height: 260px !important;
                }
                .number-image-overlay {
                    font-size: 14px !important;
                }
            }
        }
    }
    .content-editor-tab {
        .content-editor-tab-label {
            display: flex;
            svg {
                width: 14px;
                height: 15px;
                line-height: 1.14;
                letter-spacing: 0.03px;
                color: #f59f00;
                margin-left: 6px;
            }
        }

        .ant-tabs {
            .ant-tabs-nav-list {
                .ant-tabs-tab:has(.hidden) {
                    display: none;
                }
                &:has(.isIG) {
                    transform: translate(-16px, 0px) !important;
                }
            }
        }
    }

    .share-to-feed-instagram {
        color: $mid-night;
        margin-bottom: 15px;
        label {
            margin-right: 8px;
        }
    }
    .advanced-setting-youtube {
        margin-bottom: 20px;
        button {
            height: 26px;
            font-size: 11px;
            line-height: 1.82;
            letter-spacing: 0.02px;
            color: $mid-night;
        }
    }
}

.editable:empty:before {
    content: attr(data-placeholder);
}
