@import "../../../scss/helpers/variables";
.filter-list-select {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #fff;
        color: $mid-night;
        font-weight: normal;
    }
    .ant-select-item-option {
        color: $mid-night;
    }

    &-option {
        display: flex;
        gap: 5px;
        align-items: center;

        &-name {
            margin-top: 0.5px;
            white-space: pre-wrap;
        }
    }
}
.filter-list {
    .ant-form {
        .ant-form-item {
            margin-bottom: 12px;
        }
    }
    .group-btn {
        display: flex;
        justify-content: space-between;
    }
    .ant-select {
        .ant-select-arrow {
            svg {
                width: 16px;
            }
        }
        .ant-select-selector {
            color: $mid-night;
            border-radius: 4px;
            .ant-select-selection-overflow {
                flex-wrap: nowrap;
                overflow: hidden;
                .ant-select-selection-overflow-item {
                    div {
                        display: -webkit-box;
                        height: 16px;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        line-height: 1.43;
                        letter-spacing: 0.03px;
                    }
                }
            }
        }
    }
}

.tags-text {
    font-size: 14px;
    font-weight: 500;
    padding: 2px 6px;
    border-radius: 6px;
    margin: 0 2px;
}

.dropdown-filter {
    border-color: $iron !important;
    &.ant-btn {
        min-width: 182px;
        max-width: 300px;
        display: flex;
        justify-content: space-between;
        span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    svg {
        margin: 0 !important;
    }
}

.dropdown-filter-overlay {
    width: 462px;
    padding: 16px;
    box-shadow: 2px 2px 6px 0 rgba(153, 153, 153, 0.16);
    border: solid 1px #e3e4e6;
    background-color: #fff;
    border-radius: 4px;
}
