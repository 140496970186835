@import "../../../scss/helpers/variables";

.admin-update-password-form {
    width: 454px;
    background: #ffffff;
    padding: 40px;
    border-radius: 4px;

    &__header {
        margin-bottom: 32px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #4b546e;
    }

    &__description {
        margin-bottom: 16px;
    }

    &__emailIcon {
        color: #ffffff;
        margin-right: 7px;
        display: block;
        margin-top: 5px;

        svg {
            width: 17px;
            height: 17px;
        }
    }

    &__submited {
        text-align: center;

        p {
            margin-bottom: 0;
        }

        img {
            margin-bottom: 18px;
        }
    }

    &__resendMail {
        margin: 0 auto;
        display: block;
    }
    
    form {
        .ant-form-item:not(.mr-bottom-0), .password-hint-wrapper {
            margin-bottom: 16px;
        }
        button[type=submit] {
            margin-top: 20px;
        }

        .ant-form-item-new-pw {
            .ant-form-item-explain-error {
                margin-bottom: 0px;
                & + div {
                    display: none;
                }
            }
        }
    }
}
