@import "../../../scss/helpers/variables";

.billing {
    margin-bottom: 24px;
    &__heading {
        color: #4d546c;
        font-size: 16px;
        font-weight: 500;
        padding: 16px;
    }

    &__divider {
        margin-top: 0;
    }

    .ant-card-body {
        padding: 0px;
    }

    .ant-card {
        border-radius: 4px;
    }

    &__wrapper {
        padding: 0 20px;
        padding-bottom: 24px;
        

        .info {
            font-size: 12px;
            font-weight: 500;
            background-color: #fef5e5;
            color: #f59f00;
            padding: 5px 8px;
            border-radius: 6px;
            width: max-content;
            display: flex;
            align-items: center;
            gap: 4px;

            svg {
                width: 14px;
                height: 14px;
            }

            &.expired {
                background-color: #f5f5f5;
                color: #9b9b9b;
            }

            &.renewal-failed {
                background-color: #FDEEEB;
                color: #f35848;
            }

            &.canceled {
                background-color: #e7e6e7;
                color: #9b9b9b;
            }
        }

        .plan {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
            border-radius: 4px;
            box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
            border: solid 1px #e3e4e6;
            margin-top: 16px;
            flex-direction: row;

            &.none {
                padding: 0px;
                border: 0;
                box-shadow: none;
            }

            @media (max-width: 576px) {
                flex-direction: column;
                align-items: flex-start;
            }

            .left {
                display: flex;
                align-items: center;
                gap: 16px;

                .plan-name {
                    color: #4b546e;
                    font-size: 16px;
                    font-weight: 500;
                }

                .plan-amount {
                    color: #4d546c;
                    font-size: 12px;
                }
            }

            .right {
                display: flex;
                align-items: center;
                gap: 12px;

                @media (max-width: 576px) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 14px;
                    margin-top: 16px;
                    width: 100%;
                }

                .what-in-plan {
                    color: #0185de;
                    font-size: 12px;
                    text-decoration: underline;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.9;
                        transition: 0.5s all ease-in-out;
                    }
                }

                .dropdown-btn {
                  
                    width: 32px;
                    height: 32px;
                    padding: 0;
                    .dropdown-icon {
                        &.clicked {
                            background-color: #4B546E;
                            color: #fff;
                            width: 100%;
                            height: 100%;
                            border: 0;
                        }
                    }
                    svg {
                        margin-right: 0px;
                    }
                }

               

                .action-button {
                    @media (max-width: 576px) {
                        width: 100%;
                    }

                   

                    button {
                        @media (max-width: 576px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__skeleton {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 16px;
        padding: 24px;

        @media (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 8px;
            padding: 8px 0px;
        }
    }

    &__skeletonInput {
        width: 100%;
    }

    .ant-skeleton-element .ant-skeleton-input {
        width: 100%;
    }

    .credit-icon {
        margin-right: 4px;
        display: flex;
        align-items: center;
    }
}
