@import "../../../../../../scss/helpers/variables";
.add-tag-social {
  font-size: 14px;
  font-weight: 500;
  padding: 2px 6px;
  border-radius: 6px;
  cursor: pointer;
  width: fit-content;
  height: 16px;
  background-color: #fff;
  transition: 0.3s;
  margin-right: 4px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  border: #4b546e4D 1px dashed;

  &:hover {
    background-color: #fff;
    border: $mid-night 1px dashed;
  }

  span {
    color: $mid-night;
    font-size: 10px;
    font-weight: 500;
    height: 12px;
    display: flex;
    align-items: center;
  }

  svg {
    width: 10px;
    height: 11px;
    color: $mid-night;
    line-height: 1.6;
    letter-spacing: 0.02px;
    margin-right: 2px;
    margin-bottom: 1px;
    font-weight: 500;
  }
}

.add-tag-dropdown {
  width: 280px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 2px 2px 6px 0 rgba(153, 153, 153, 0.16);
  border: solid 1px $iron;
  background-color: #fff;

  .search-tag {
    input {
      height: 30px;
    }
  }

  .list-tag {
    &-item {
      display: flex;
      align-items: center;
      margin: 15px 0;

      .tag-social {
        flex-grow: 1;
      }

      svg {
        width: 14px;
        height: 15px;
        font-size: 14px;
        margin-left: 8px;
        cursor: pointer;
      }
      svg.trash-icon {
        color: $sunset-color;
        margin-left: 20px;
      }
      span {
        display: flex;
        align-items: center;
      }
    }
  }
  .ant-divider {
    color: $iron;
    margin: 8px 0;
    margin-left: -12px;
    width: calc(100% + 24px);
  }
  .add-new-tag {
    margin-top: 8px;
  }

  .list-color {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 15px 0;
    .color-tag {
      width: 16px;
      height: 16px;
      border-radius: 999px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 14px;
        height: 15px;
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: #FFF;
        display: none;
      }
      &.color-tag-selected {
        width: 22px;
        height: 22px;
        svg {
          display: block;
        }
      }
    }
  }
  .group-btn {
    display: flex;
    justify-content: space-between;
  }
  .error-text {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.03px;
    color: $sunset-color;
  }
  .no-tag {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.03px;
    color: #9b9b9b;
    margin-top: 8px;
  }
}

.ant-modal-wrap:has(.confirm-delete-tag-modal) {
  z-index: 9999;
}