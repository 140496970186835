.media-library-header {
    display: flex;
    justify-content: space-between;

    .ant-select {
        margin: 0 12px;
    }

    .select-type {
        display: flex;
        margin-right: 12px;

        .ant-radio-button-wrapper {
            height: 36px;
            display: flex;
            align-items: center;

            &:last-child {
                border-radius: 0 4px 4px 0;
            }

            &:first-child {
                border-radius: 4px 0 0 4px;
            }
        }
    }

    .search-input {
        margin-right: 12px;
        height: 36px;
    }
}
