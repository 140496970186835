@import "../../../../../../scss/helpers/variables";
.emoji-wrap {
    svg {
        width: 16px;
        height: 18px;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.03px;
        color: $mid-night;
        cursor: pointer;
    }

    .emoji-picker {
        z-index: 9999;
        position: absolute;

    
    }

    .emoji-icon {
        display: flex;
        align-items: center;
    }
    
}

.emoji-popover {
    .ant-popover-inner-content {
        padding: 0px !important
    }
    .ant-popover-inner {
        border-radius: 8px;
    }
    aside.EmojiPickerReact.epr-main{
        border: 0;
    }
    
}
