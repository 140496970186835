@import "../../../../../scss/helpers/variables";

.integration-form__section {
    .account-info {
        display: flex;
        flex-wrap: wrap;
        .account-info-item {
            width: 50%;
            margin-bottom: 16px;
            .label {
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: $nobel-color;
            }
            .value {
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: $mid-night;
            }
        }
    }

    .group-btn {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        .remove-btn {
            color: $sunset-color;
        }

        .right-btns {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .client-campaign {
        &-label {
            margin-bottom: 8px;
            color: #4d546c;
            span {
                color: #f35848;
            }
        }
        .ant-select {
            width: 100%;
        }
    }
}

.remove-account-modal {
    .remove-decs {
        margin-top: 8px;
        ul {
            margin-bottom: 0;
        }
    }
}