.app-layout-wrap {
    display: flex;
    .bg-left {
        width: 25%;
        background-position: center;
        background-size: cover;
        padding-left: 2%;
        padding-top: 5%;
        img {
            margin-bottom: 20px;
        }
        div {
            font-size: 34px;
            line-height: 1.35;
            letter-spacing: 0.07px;
            text-align: left;
            color: #0185de;
        }
    }
}
.auth-layout {
    // width: 100%;
    flex-grow: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-position: center;
    background-size: cover;

    &__logo {
        margin-right: 20px;

        &.approve-request {
           margin: 0px 16px 0px 8px; 
        }
    }

    &__copyright {
        color: #a1aac7;
        margin: 20px auto;
    }

    &__formContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 100%;
    }

    &__logo_wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        span {
            background-color: #0185de;
            text-transform: uppercase;
            color: #ffffff;
            padding: 2px 10px;
            border-radius: 4px;
            font-size: 12px;
        }
    }

    &__form {
        width: 90%;
        max-width: 454px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media only screen and (max-width: 576px) {
            > div {
                padding: 25px;
            }
        }
    }
}
