.countdown {
    &__banner {
        text-align: center;
        padding: 12px 0;
        color: #fff;
        line-height: 1.43;
        letter-spacing: 0.03px;

        &.green {
            background-color: #9bc13c;
        }
        &.orange {
            background-color: #f59f00;
        }
        &.red {
            background-color: #f35848;
        }
    }
    &__premium {
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer;
    }

    &__timer {
        width: 24px;
        height: 20px;
        padding: 2px 3px 1px;
        border-radius: 4px;
        background-color: #000;
        font-weight: 500;
    }
}
