.yarl__container {
    background-color: #4b546e44;
}

.yarl__toolbar {
    padding: 0;
    .yarl__button {
        background-color: #4b546e;
        width: 42px;
        height: 42px;
        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.yarl__navigation_prev,
.yarl__navigation_next {
    background-color: #4b546e;
    padding: 0;
    width: 42px;
    height: 42px;
}

.yarl__navigation_next {
    right: 24px;
}

.yarl__navigation_prev {
    left: 24px;
}

.yarl__sigle {
    .yarl__navigation_prev,
    .yarl__navigation_next {
        display: none;
    }
}

.yarl__video_container {
    video {
        background-color: #000000;
    }
}

.yarl__button {
    filter: none;
}

.yarl__slide.yarl__slide_current.yarl__flex_center {
    display: flex;
    flex-direction: column;
    padding: 60px 0;
}

.custom-lightbox {
    .lightbox-slide {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 100%;
        }

        &.video {
            height: auto;
        }
    }

    .lightbox-title {
        text-align: center;  
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: #fff;
        margin-top: 16px;

        pre {
            white-space: pre-wrap;
        }
    }
}

.custom-lightbox::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Màu nền mờ */
    backdrop-filter: blur(10px); /* Hiệu ứng làm mờ */
    z-index: -1; /* Đảm bảo nằm dưới slide */
}

@media (max-width: 576px) {
    .yarl__container {
        backdrop-filter: blur(4px)!important;
    }
    .yarl__slide {
        padding: unset;
    }
    .yarl__navigation_prev {
        left: 0;
    }
    .yarl__navigation_next {
        right: 0;
    }
    .yarl__navigation_prev,
    .yarl__navigation_next {
        background-color: rgba(35,35,35,.65);
    }
    .custom-lightbox {
        .lightbox-slide {
            img {
                width: 100%;
                height: unset;
            }
            video {
                width: 100vw;
                height: auto;
            }
        }
    }
}