@import "../../../../../scss/helpers/variables";

.send-email-report-modal {
    width: 462px !important;
    max-width: 462px !important;

    @media (max-width: 576px) {
        width: auto;
        max-width: auto;
    }

    .label {
        color: #4d546c;
        font-weight: 500;
        margin-top: 8px;

        &.top {
            margin-top: 0px;
        }
    }

    .title {
        color: #4d546c;

        &.mb-16 {
            margin-bottom: 16px;
        }
    }

    .helper-text {
        color: #9b9b9b;
        font-size: 12px;
        margin-top: 4px;
        margin-bottom: 0px;
    }

    .ant-form-item-explain-error {
        margin-bottom: 0;
    }

}

