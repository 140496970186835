.home-reviews-and-ratings {
    &__sumValue {
        display: flex;
        align-items: center;

        p {
            font-weight: 500;
            font-size: 36px;
            margin-bottom: 0;
            color: #4b546e;
            margin-right: 5px;
        }

        > div {
            display: flex;
            flex-direction: column;

            .ant-rate {
                color: #f59f00;
                font-size: 12px;

                .ant-rate-star:not(:last-child) {
                    margin-right: 2px;
                }
            }

            > span {
                font-size: 10px;
                color: #9b9b9b;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        color: #9b9b9b;

        span {
            &:first-child {
                display: flex;
                align-items: center;
                margin-right: -5px;
            }

            &:last-child {
                margin-left: 10px;
                width: 20px;
            }
        }
    }

    &__review {
        display: flex;
        justify-content: space-around;
        margin-top: 16px;
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;

            > img {
                width: 50px;
                height: 50px;
                margin-bottom: 8px;
            }

            &__emptyImage {
                width: 50px;
                height: 50px;
                margin-bottom: 8px;
                background-color: #e3e4e6;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                text-transform: uppercase;
            }

            &__name {
                color: #9b9b9b;
                text-transform: uppercase;
                font-size: 10px;
                margin-bottom: 8px;
            }

            &__value {
                color: #4d546c;
                font-weight: 500;
                margin-bottom: 10px;
            }

            &__like {
                display: flex;

                > span {
                    display: flex;
                    flex-direction: column;
                    padding: 0 17px;

                    &:first-child {
                        svg {
                            color: #9bc13c;
                        }
                    }

                    &:last-child {
                        svg {
                            color: #f35848;
                        }
                    }
                }

                svg {
                    width: 16px;
                    height: 16px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}
