@import "../../../../../../../scss/helpers/variables";
.comment-wrap {
    margin-bottom: 16px;
    .comment-posted {
        display: flex;
        margin-bottom: 4px;
        position: relative;
        .comment-posted-avatar {
            z-index: 1;
        }
        .comment-posted-content {
            flex-grow: 1;
            margin-left: 8px;
            .comment-posted-detail {
                border-radius: 4px;
                background-color: #9b9b9b1a;
                padding: 12px;
                .comment-posted-detail__name {
                	display: flex;
			        align-items: center;
			        gap: 5px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    color: $mid-night;
                    margin-bottom: 8px;
                }
                .comment-posted-detail__comment {
                    font-size: 14px;
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    color: $mid-night;
                }
                .list-media {
                    margin: 12px 12px 12px 0px;
                    flex-wrap: wrap;
                    display: flex;
                    .media {
                        cursor: pointer;
                        position: relative;
                        margin: 0 4px 4px 0;
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        video,
                        img {
                            height: 60px;
                            border-radius: 4px;
                            width: 90px;
                            object-fit: cover;
                        }

            video {
              background-color: #000000;
            }

            .play-icon {
              position: absolute;
              object-fit: contain;
            }
          }
        }

        .reply,
        .reply-mobile {
          font-size: 14px;
          line-height: 1.43;
          letter-spacing: 0.03px;
          color: $primaryColor;
          cursor: pointer;
          margin-top: 12px;
        }
        .reply-mobile {
          display: none;
        }
      }
      .comment-created-date {
        font-size: 10px;
        line-height: 1.6;
        letter-spacing: 0.02px;
        color: $nobel-color;
      }
    }
    .comment-posted-line {
      position: absolute;
      bottom: -16px;
      left: 17px;
      height: 100%;
      border: 1px solid #9b9b9b1a;
      z-index: 0;
    }
  }

  .comment-delete {
    position: absolute;
    top: 11px;
    right: 11px;
    padding: 0px;
    width: 16px;
    height: 16px;
    color: #9b9b9b;

    &:active,
    &:focus,
    &:hover {
      background-color: transparent;
      color: $sunset-color;
    }

    svg {
      margin: 0;
    }
  }

  &.lock {
    .comment-posted-content {
      .comment-posted-detail {
        background-color: $light-yellow-color;
        &__name {
          &-lock {
            display: flex;
            align-items: center;
            color: $yellow-color;
            margin-bottom: 3px;
            
          }
        }
      }
    }
  }
}


@media (max-width: 576px) {
  .comment-posted-wrap {
    .comment-draft-mobile {
      margin-left: 44px;
    }
  }
  .comment-wrap {
    .comment-posted-line.last-child {
      display: none;
    }
    .comment-posted-content {
      .comment-posted-detail {
        .reply {
          display: none;
        }
        .reply-mobile {
          display: block !important;
        }
        &.highlight {
          background-color: #9b9b9b4c;
        }
      }
    }
  }
}
