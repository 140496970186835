@import "../../../../scss/helpers/variables";
.user-profile-form {
  .avatar {
    position: relative;
    width: fit-content;
    .btn-edit-avatar {
      width: 24px;
      height: 24px;
      padding: 2.8px 4px 2.8px 4px;
      border-radius: 4px;
      border: solid 1px $iron;
      background-color: #fff;
      position: absolute;
      top: 4px;
      right: 4px;
      cursor: pointer;
    }
  }

  .ant-form-item {
    .ant-col {
      label {
        display: block;
        .format-picture {
          word-break: break-word;
          white-space: pre-wrap;
          font-size: 10px;
          font-weight: 500;
          line-height: 1.6;
          letter-spacing: 0.02px;
          text-align: left;
          color: $nobel-color;
        }
      }
    }
  }
}
