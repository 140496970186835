@import "../../../scss/helpers/variables";

.add-profiles-page {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;

  &__header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
    border-bottom: solid 1px #e3e4e6;
    background-color: #fff;
    img {
      width: 132px;
      height: auto;
    }
  }

  &__body {
    width: 100%;
    height: calc(100vh - 88px);
    display: flex;
    justify-content: center;
    padding-top: 100px;
    color: $mid-night;
    background-color: #f7f8fb;

    .container {
      width: 980px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.04px;
        color: #4d546c;
      }
      .description {
        margin: 9px 0 30px 0;
      }
                    
      .list-social {
        display: flex;
        justify-content: center;
        &-item {
          width: 180px;
          height: 120px;
          margin: 0 10px;
          border-radius: 4px;
          box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
          border: dashed 1px #1877f2;
          background-color: #fff;
          position: relative;
          cursor: pointer;
          .social-icon {
            margin: 20px 0 16px 0;
          }
          .icon-add {
            position: absolute;
            top: 4px;
            right: 6px;
            svg {
              width: 15px;
            }
          }
        }
      }
      .all-done {
        margin: 60px 0 80px 0;
        button {
          margin: 16px auto 0;
        }
      }
      .contact {
        padding-top: 16px;
        border-top: 1px solid #e3e4e6;
        color: $nobel-color;
      }
    }
  }

  &__footer {
    padding-bottom: 20px;
    background-color: #f7f8fb;
  }
}
