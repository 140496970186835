@import "../../../../../../scss/helpers/variables";
.custom-media-modal {
  max-width: 85vw !important;
  width: 1290px !important;
  min-height: 90vh !important;
  top: 40px !important;

  .ant-modal-body {
    padding-top: 0;
    .media-library-page {
      .page-header {
        display: none;
      }
      .page-body {
        .sider-bar {
          padding-top: 12px;
          padding-right: 16px;
          padding-bottom: 12px;
          margin-right: 0;
          margin-bottom: -84px;
          border-right: 1px solid #e3e4e6;
        }
        .media-library-main {
          padding-top: 24px;
          border-bottom: 1px solid #e3e4e6;
          border-left: none;
        }

        .file-detail {
          padding-top: 24px;
          border-bottom: 1px solid #e3e4e6;
          margin-right: -24px;
          padding-right: 12px;
        }
      }
    }
  }

  .media-footer-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
