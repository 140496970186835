.sumary-card {
    width: 100%;
    height: 72px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
    border: solid 1px #e3e4e6;
    background-color: #fff;
    display: flex;
    align-items: center;
    color: initial;

    &-icon {
        width: 40px;
        height: 40px;
        padding: 6px 8px 8px;
        border-radius: 3px;
        margin-right: 16px;
        color: #ffffff;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
            margin-bottom: 0;
            font-weight: 500;
        }
    }
}

@media (max-width: 576px) {
    .page-sumary {
        .ant-col:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}
