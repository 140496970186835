@import "../../../../scss/helpers/variables";
.media-library-container {
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.03px;
    color: #4d546c;
    margin-top: 24px;
  }

  .media-loading-container {
    min-height: 470px;
  }

  .description {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > span {
      font-size: 10px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.02px;
      color: $nobel-color;
      margin-right: 10px;
    }

    .select-all {
      color: $primaryColor;
      cursor: pointer;
    }
    .deselect-all {
      color: #353d53;
      cursor: pointer;
    }
    .delete-selected {
      color: $sunset-color !important;
      cursor: pointer;
      flex-grow: 1;
      text-align: right;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.02px;
    }
  }
  .list-media {
    display: flex;
    flex-wrap: wrap;
    min-height: 300px;
    gap: 10px;
    .media-item {
      height: 150px;
      position: relative;
      cursor: pointer;
      width: calc(25% - 7.5px);

      .media-item__bg {
        background-image: unset;
      }

      .video-duration {
        position: absolute;
        right: 0px;
        color: #ffffff;
        bottom: 0px;
        background: rgba(0, 0, 0, 0.5);
        padding: 4px 8px;
        font-size: 10px;
        line-height: 10px;
      }

      img {
        object-fit: cover;
      }

      video {
        object-fit: contain;
      }

      .media-social-source {
        height: 150px;
        width: 100%;
        border-radius: 4px;
        object-fit: cover;

        &.document-img {
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: unset;
        }
      }
      .overlay {
        display: none;
        width: 100%;
        height: 150px;
        top: 0;
        position: absolute;
        border-radius: 4px;
        transition: 0.4s;
        .status {
          position: absolute;
          top: 8px;
          left: 8px;
          width: 24px;
          height: 24px;
          border: solid 2px $primaryColor;
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 12px;
            height: 13px;
            font-size: 12px;
            line-height: 1.67;
            letter-spacing: 0.02px;
            color: #fff;
          }
          &.checked {
            background-color: $primaryColor;
          }
        }
        .author {
          display: none;
          position: absolute;
          bottom: 5px;
          left: 5px;

          a {
            margin-left: 2px;
            font-size: 10px;
            font-weight: 500;
            line-height: 1.6;
            letter-spacing: 0.02px;
            color: #fff;
            text-decoration: underline;
          }
        }
      }
      &.selected {
        .media-social-source {
          border: solid 3px $primaryColor;
        }
        .overlay {
          display: block;
          .status {
            background-color: $primaryColor;
          }
        }
      }
      &:hover {
        .overlay {
          display: block;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(128, 128, 128, 0) 118%);
        }
        .author {
          display: block;
        }
      }
     
    }
    .media-empty {
      width: 100%;
    }
  }

  .media-library-pagination {
    display: flex;
    float: right;
    margin-top: 10px;

    .ant-pagination-options,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next,
    .ant-pagination-item {
      display: none;
    }
    &.my-file-pagination {
      .ant-pagination-item,
      .ant-pagination-jump-next {
        display: block;
      }
    }
  }
}
