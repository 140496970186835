@import "../../../../scss/helpers/variables";
.home-google-listing {
    .rl-card {
        &-title {
            margin-bottom: 24px;
        }
    }
    .home-google-listing-profile__details {
        display: flex;

        > div {
            display: flex;
            flex-direction: column;

            > div {
                display: flex;
                align-items: center;
                margin-right: 25px;
                span {
                    &:first-child {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }

                    &:last-child {
                        font-size: 12px;
                        color: #9b9b9b;
                    }
                }
            }

            .total {
                color: #4d546c;
                font-weight: 500;
                font-size: 20px;
                margin-bottom: 5px;
            }

            .volatility {
                margin-bottom: 5px;
                min-height: 22px;
                span {
                    display: flex;
                    align-items: center;
                }
    
                svg {
                    width: 18px;
                    height: 18px;
                    margin-left: 3px;
                }
            }
        }
    }

    .home-google-listing-user__details {
        display: flex;

        .ant-row {
            width: 100%;
            .gl-user-action-detail {
                margin: auto 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                > div {
                    display: flex;
                    flex-direction: column;
                    > div {
                        display: flex;
                        align-items: center;
                        span {
                            &:first-child {
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                margin-right: 5px;
                            }
        
                            &:last-child {
                                font-size: 12px;
                                color: #9b9b9b;
                            }
                        }
                    }
        
                    > p {
                        color: #4d546c;
                        font-weight: 500;
                        font-size: 20px;
                    }
                }
                > div:not(:last-child) {
                    border-bottom: 1px solid $iron;
                    margin: 15px 0;
                }
                > div:last-child {
        
                    > p {
                        margin: 0;
                    }
                }
            }
        }
    }
}
