@import "./helpers/variables";

.text-center {
    text-align: center;
}

.color-nobel {
    color: $nobel-color;
}

.color-green {
    color: $green-color;
}

.color-sunset {
    color: $sunset-color;
}

.des-color {
    color: $mid-night;
}

.color-blue {
    color: $primaryColor;
}

.color-secondary {
    color: $secondaryColor;
}

.mr-bottom-0 {
    margin-bottom: 0px;
}

.mr-bottom-5 {
    margin-bottom: 5px;
}

.pd-bottom-5 {
    padding-bottom: 5px;
}

.font-w-medium {
    font-weight: 500;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.padding-20 {
    padding: 20px;
}

.mr-bottom-24 {
    margin-bottom: 24px !important;
}

.mr-right-4 {
    margin-right: 4px;
}

.mr-right-12 {
    margin-right: 12px;
}

.mr-right-16 {
    margin-right: 16px;
}

.text-success {
    color: $secondaryColor;
}

.text-warning {
    color: #f59f00;
}

.text-error {
    color: #f35848;
}

.mr-bottom-20 {
    margin-bottom: 20px;
}

.padding-bottom-0 {
    padding-bottom: 0px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $mid-night;
}

.mid-night-border {
    border-color: $mid-night;
}

.fw-500 {
    font-weight: 500;
}

@media only screen and (max-width: 576px) {
    .mobile-hidden {
        display: none;
    }

    .mobile-mr-bottom-20 {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 576px) {
    .desktop-hidden {
        display: none;
    }
}