@import "../../../../../scss/helpers/variables";
.social-dashboard-topposts {
    .rl-card {
        padding: 0;
        .rl-card-toppost {
        }
    }
}

@media (max-width: 576px) {
    .social-dashboard-topposts {
        .ant-row {
            .ant-col {
                margin-bottom: 8px;
            }
        }
    }
}