@import "../../../../scss/helpers/variables";

.general-settings-reports {
    &__tabs {
        display: flex;
        align-items: center;
    }

    &__tabsContent {
        margin-bottom: 16px;
        margin-top: 8px;
        a {
            text-decoration: underline;
        }

        .title {
            color: #4d546c;
        }

        .tab-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 576px) {
                flex-wrap: wrap;
            }
           
            .switch {
                display: flex;
                align-items: center;
                gap: 8px;

                @media (max-width: 576px) {
                    margin-top: 12px;
                }

                .auto-reporting {
                    color: #4d546c;

                    &.clickable {
                        color: #0185de;
                        text-decoration: underline;
                        &:hover {
                            transition: all ease-in-out 0.3;
                            opacity: 0.9;
                            cursor: pointer;
                        }
                    }

                    .tooltip {
                        text-align: center;
                    }
                }

                .circle-question {
                    border-radius: 50%;
                    background-color: #e3e4e6;
                    width: 18px;
                    height: 18px;
                    padding-top: 1px;
                    padding-left: 1px;
                    font-size: 11px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
    }

    .ant-tabs-nav::before {
        border-bottom: 0;
    }
    .ant-tabs-nav-wrap {
        display: none !important;
    }

    .report-title {
        color: #0185de;

        text-decoration: none;

        &:hover {
            text-decoration: underline;
            transition: all ease-in-out 0.3;
            opacity: 0.9;
        }
    }

    .noData {
        display: flex;
        color: #9b9b9b;
        font-size: 14px;
    }

    .reports-pagination.ant-pagination {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 30px;
    }
    .generate-report {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
    }

    .dropdown-action-button {
        svg {
          width: 12px;
          margin-left: 2px;
          margin-right: 0px;
          margin-bottom: 0px;
        }
      }
}



