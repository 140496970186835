@import "../../../../../../scss/helpers/variables";

.top-post-item {

    .toppost-info {
        .toppost-info-profile {
            display: flex;
            align-items: center;
            padding: 20px 24px 16px 24px;
            .toppost-info-profile__right {
                padding-left: 12px;
                .name {
                    font-weight: 500;
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    color: $mid-night;
                }
                .network {
                    font-size: 10px;
                    line-height: 2;
                    letter-spacing: 0.02px;
                    color: $nobel-color;
                }
            }
        }
        .toppost-info-content {
            .caption {
                padding: 0 24px 16px 24px;
                .profile-name {
                    font-weight: 500;
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    color: #4d546c;
                }
            }
            .interactions {
                display: flex;
                justify-content: space-between;
                padding: 14px 24px;
                img {
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                }
                .interaction-left {
                    img {
                        margin-right: 10px;
                    }
                }
            }
        }
        .media {
            .list-media-content-card {
                height: unset;
                max-height: 400px;
                margin-top: 0;
                .media-item {
                    max-height: 400px;
                    height: unset;
                }
                img, video {
                    max-height: 400px;
                }
                video {
                    margin-top: -10px;
                }
            }
        }

        &.toppost-info-tiktok {
            .toppost-info-profile {
                align-items: unset;
                .toppost-info-profile__right {
                    .name {
                        margin: 5px 0;
                    }
                    .network {  
                        font-size: 12px;
                        line-height: 1.33;
                        letter-spacing: 0.02px;
                        color: #4d546c;

                        .media {
                            display: flex;
                            .list-media-content-card {
                                flex-grow: 1;
                            }
                            .interactions {
                                padding: 0 25px;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                .interaction-item {
                                    text-align: center;
                                    .interaction-item__icon {
                                        width: 48px;
                                        height: 48px;
                                        border-radius: 50%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        background-color: rgba(22, 24, 35, 0.06);
                                        cursor: pointer;
                                    }
                                    span {
                                        font-size: 12px;
                                        font-weight: 500;
                                        line-height: 1.33;
                                        letter-spacing: 0.02px;
                                        color: $nobel-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .toppost-table {
        .engagement {
            background-color: #f2f3f4;
            text-transform: uppercase;
            padding: 10px 24px;
            font-size: 10px;
            font-weight: 500;
            line-height: 1.6;
            letter-spacing: 0.02px;
            color: $nobel-color;
        }
        .statitics {
            .statitic-item {
                display: flex;
                justify-content: space-between;
                padding: 10px 24px 12px 24px;
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: $mid-night;
                border-bottom: 1px solid $iron;
                .statitic-item-left {
                    display: flex;
                    align-items: center;
                    svg {
                        width: 14px;
                        height: 15px;
                        margin-right: 5px;
                        margin-bottom: 1px;
                    }
                }
            }
        }
    }
}