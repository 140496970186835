@import "../../../../../scss/helpers/variables";

.integration-form__alert {
    .ant-alert-message {
        .reAuthen {
            color: $primaryColor;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}