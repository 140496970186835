@import "../../../../scss/helpers/variables";
.general-setting-account {
  .avatar {
    position: relative;
    width: fit-content;
    .btn-edit-avatar {
        width: 24px;
        height: 24px;
        padding: 2.8px 4px 2.8px 4px;
        border-radius: 4px;
        border: solid 1px $iron;
        background-color: #fff;
        position: absolute;
        top: 4px;
        right: 4px;
        cursor: pointer;
    }
  }
  .group-btn-account {
    display: flex;
    justify-content: flex-end;
  }

  .ant-form-item {
    margin: 0 0 16px;
    .ant-col {
        label {
            display: block;
            .format-picture {
                word-break: break-word;
                white-space: pre-wrap;
                font-size: 10px;
                font-weight: 500;
                line-height: 1.6;
                letter-spacing: 0.02px;
                text-align: left;
                color: $nobel-color;
                
            }
        }
    }
    .react-tel-input {
      input:disabled {
        color: rgba(0,0,0,.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
        cursor: not-allowed;
        opacity: 1;
      }
    }
  }
  
  @media (max-width: 576px) {
    .ant-form-item {
      label {
        height: auto;
      }
    }
  }
}
