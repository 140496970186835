.top-lead-sources {
    display: flex;
    justify-content: center;
    align-items: center;

    &__chartWrapper {
        width: 232px;
        height: 222px;
        margin-right: 50px;
        position: relative;
    }

    &__legend {
        display: flex;
        flex-direction: column;
        width: 178px;

        &__item {
            display: flex;
            align-items: center;
            width: 100%;
            &__name {
                flex: 1;
                color: #9b9b9b;
            }
            span {
                &:first-child {
                    width: 10px;
                    height: 10px;
                    border-radius: 3px;
                    margin-right: 8px;
                }
            }
        }
    }

    &__total {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        top: 55px;
        background: transparent;
        width: 111px;
        height: 111px;
        border-radius: 50%;
        left: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            &:first-child {
                font-size: 12px;
                color: #9b9b9b;
            }

            &:last-child {
                font-size: 20px;
                color: #4d546c;
                font-weight: 500;
            }
        }
    }

    &__emptyChart {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__empty__total {
        width: 142px;
        height: 142px;
        background-color: #ffffff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span {
            &:first-child {
                font-size: 12px;
                color: #9b9b9b;
            }

            &:last-child {
                font-size: 20px;
                color: #4d546c;
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 576px) {
    .top-lead-sources {
        flex-direction: column;

        &__legend {
            width: 80%;
            max-width: 232px;
            margin-top: 16px;
        }
    }
}
