@import "../../../../../scss/helpers/variables";

.social-profiles-table {
    .ant-table-content {
        margin-top: 0 !important;
    }
    .add-profile {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
    }
    .column-name-setting {
        display: flex;
        align-items: center;
        cursor: pointer;
        .avatar {
            margin-right: 10px;
            .avatar-social-wrap {
                width: 40px;
                height: 40px;
            }
        }
    }
    .column-status-setting {
        display: flex;
        align-items: center;
        .ant-badge-status-text {
            color: $mid-night;
        }

        .warning-limit {
            margin-left: 8px;
            display: flex;
            align-items: center;

            svg {
                width: 14px;
                height: 14px;
                color: #f59f00;
            }
        }
    }
}

.group-action-setting {
    display: flex;
}

.modal-get-link-connect {
    .get-link-block {
        display: flex;
        .btn-link-icon {
            background-color: #f7f8fb;
            border-color: $iron;
            border-right: none;
            border-top-right-radius: 0%;
            border-bottom-right-radius: 0%;
            width: 36px;
            padding: 0 10px;
            svg {
                margin: 0;
                color: $mid-night;
            }
        }
        .btn-link-text {
            flex-grow: 1;
            border-radius: 0%;
            border-color: $iron;
            overflow: hidden;
            visibility: visible;
            text-align: left;
            span {
                overflow: hidden;
                width: 100%;
                text-overflow: ellipsis;
            }
        }
        .btn-copy {
            border-top-left-radius: 0%;
            border-bottom-left-radius: 0%;
            svg {
                margin-right: 8px;
            }
        }
    }
}
.remove-profile-modal-content {
    .warning {
        margin: 16px 0 14px;
        div {
            display: flex;
            align-items: center;
            svg {
                width: 14px;
                height: 14px;
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: #e16351;
                margin-bottom: 1px;
                margin-right: 10px;
            }
        }
    }
}
