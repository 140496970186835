@import "../../../../scss/helpers/variables";
.service-form {
    &__section {
        &__title {
            font-weight: bold;
        }

        &__field {
            margin-bottom: 16px;
        }
    }

    &__alert {
        margin-bottom: 12px;
    }
    .site-collapse-custom-collapse {
        border-color: $iron;
        background-color: #fff;
        .ant-collapse-item {
            border: 1px solid $iron;
            margin-bottom: 16px;

            &.ant-collapse-item-active {
                .ant-collapse-header {
                    border-bottom: 1px solid $iron;
                }
            }

            .ant-collapse-header {
                background-color: #fff;
                padding-right: 16px;
                .ant-collapse-expand-icon {
                    span {
                        transform: inherit;
                        top: 12px;
                    }
                }
                .ant-collapse-header-text {
                    .title {
                        font-weight: bold;
                        color: $mid-night;
                        display: flex;
                        align-items: center;
                        svg {
                            width: 16px;
                            height: 16px;
                            line-height: 1.25;
                            letter-spacing: 0.03px;
                            margin: 0 0 4px 4px;
                            margin-right: 4px;
                        }
                    }
                    .description {
                        font-size: 12px;
                        line-height: 1.33;
                        letter-spacing: 0.02px;
                        color: $nobel-color;
                    }
                }
                .ant-collapse-extra {
                    button:not([disabled]) {
                        border-color: $primaryColor;
                        color: $primaryColor;
                        svg {
                            margin-bottom: 1px;
                        }
                        &:hover {
                            background-color: $primaryColor;
                            color: #FFF;
                        }
                    }
                }
            }
            .ant-collapse-content {
                &-box {
                    padding: 16px !important;
                }
            }

            &.invalid {
                border-color: #f35848;
                .ant-collapse-header {
                    border-color: #f35848;
                }
            }
        }
        .UA-panel {
            .ant-collapse-header {
                .ant-collapse-header-text {
                    .title {
                        color: $nobel-color;
                    }
                }
            }
        }
    }
}