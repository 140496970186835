@import "../../../../../scss/helpers/variables";
.social-panel {
    p {
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: $mid-night;
    }
    .list-user {
        display: flex;
        flex-wrap: wrap;
        .user-image {
            width: 36px;
            height: 36px;
            border-radius: 3px;
            margin-right: 12px;
            margin-bottom: 12px;
            position: relative;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                border-radius: 3px;
                object-fit: cover;
            }
            .remove-icon {
                position: absolute;
                top: -5px;
                right: -5px;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                display: none;
                justify-content: center;
                align-items: center;
                color: #fff;
                background-color: $sunset-color;
                font-size: 10px;
                line-height: 2.22;
                letter-spacing: 0.02px;
            }
            &.add-user {
                border: dashed 1px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                svg {
                    width: 11px;
                    height: 11px;
                    margin: 0;
                }
            }
            &:hover {
                .remove-icon {
                    display: flex;
                }
            }
        }
    }
    .disable-social {
        margin-top: 40px;
    }
}