@import "../../../../../scss/helpers/variables";

.connect-profiles-error {
    &__animation {
        img {
            width: 104px;
            height: 104px;
        }
    }

    .header {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #4b546e;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .description {
        color: $mid-night;
        margin-bottom: 20px;
    }

    .ant-form-item-label {
        display: none;
    }
}
