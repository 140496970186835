@import "../../../scss/helpers/variables";
.social-approvals {
  width: 100%;
  .no-approval {
    height: calc(100vh - 400px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.47;
      color: $mid-night;
      margin: 24px 0 8px 0;
    }
    .description {
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.02px;
      color: #4d546c;
      margin-bottom: 24px;
    }
  }
  .social-approvals-wrapper {
    .social-approvals-wrapper__header {
      display: flex;
      justify-content: flex-end;
      & > button {
        margin-left: 12px;
      }
    }
    .social-approvals-wrapper__table {
      .no-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 190px;

        &-title {
          font-size: 15px;
          font-weight: 500;
          line-height: 1.47;
          color: $nobel-color;
          margin: 24px 0 8px 0;
        }
        &-description {
          font-size: 12px;
          line-height: 1.33;
          letter-spacing: 0.02px;
          color: $nobel-color;
          margin-bottom: 24px;
        }
      }

      .approval-pagination.ant-pagination {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 30px;
      }
    }
  }
  .table-approval-action {
    display: flex;
    .view-approval {
      button {
        color: $primaryColor;
      }
    }

    .dropdown-action-button {
      border: none;
      padding-right: 0;

      svg {
        width: 14px;
        height: 14px;
        color: inherit;
        margin-left: 5px;
      }
    }
  }
}
