
.add-user-modal {
    .ant-form-item-new-pw {
        .ant-form-item-explain-error {
            margin-bottom: 0px;
            & + div {
                display: none;
            }
        }
    }
}