@import "../../../../../scss/helpers/variables";

.add-user-team-modal {
    .add-user-team-form {
        .form-item-permission {
            margin-bottom: 0;
            .ant-checkbox-wrapper {
                .check-box-content {
                    .label {
                        line-height: 1.43;
                        letter-spacing: 0.03px;
                        color: $mid-night;
                    }
                    .description {
                        font-size: 10px;
                        line-height: 1.6;
                        letter-spacing: 0.02px;
                        color: $nobel-color;
                    }
                }
            }
            .ant-form-item:last-child {
                margin-bottom: 6px;
            }
        }
    }
}