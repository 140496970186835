@import "../../scss/helpers/variables";

.media-library-page {
    .sider-bar {
        width: 20%;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 314px);

        &__item {
            width: 100%;
            height: 50px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 4px;
            transition:
                border-color 0.3s,
                background 0.3s,
                padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);

            &:hover {
                background-color: #353d5305;
            }

            &__active {
                background-color: #353d5310;
                font-weight: 500;
            }

            svg {
                width: 16px;
                height: auto;
                margin-right: 8px;
            }
        }
        .sider-bar-social {
            width: 100%;
            height: 50px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            font-weight: 500;
            line-height: 1.14;
            letter-spacing: 0.03px;
            color: $nobel-color;
        }

        &__footer {
            margin-top: 24px;
            padding: 12px;
            border: solid 1px $iron;
            background-color: #f7f8fb;
            border-radius: 4px;
            .ant-skeleton-input {
                width: 100%;
            }
            div {
                line-height: 1.71;
                letter-spacing: 0.03px;
                color: $nobel-color;
                font-size: 12px;
                display: flex;
                align-items: center;

                a {
                    color: inherit;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        text-decoration: underline;
                    }

                    svg {
                        margin-left: 3px;
                    }
                }
            }
        }
    }

    .media-library-main {
        width: 60%;
        border-right: 1px solid $iron;
        border-left: 1px solid $iron;
        padding: 0 16px;
        min-height: calc(100vh - 314px);
    }

    .file-detail {
        width: 20%;
        padding-left: 16px;
    }

    .page-body {
        display: flex;

        .ant-card {
            flex-grow: 1;
        }
    }

    .sider-bar-mobile {
        display: none;
    }

    .ant-divider {
        display: none;
    }

    @media (max-width: 576px) {
        .page-header {
            height: 70px;
            align-items: flex-end;
        }
        .page-body {
            flex-direction: column;
            .ant-card {
                box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
                border: solid 1px $iron;
                border-radius: 4px;
                &-head {
                    padding: 0 20px;
                    .ant-card-head-title {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        letter-spacing: 0.03px;
                        color: $mid-night;
                    }
                }
                &-body {
                    padding: 20px;
                }
            }
        }

        .setting-sider-bar {
            display: none;
        }

        .setting-sider-bar-mobile {
            display: block;
            margin-bottom: 16px;
            .ant-select-selector {
                height: 50px !important;
                padding: 0;
                border: 1px solid $mid-night;
                .setting-sider-bar__item {
                    color: $mid-night;
                    font-weight: 500;
                    padding: 16px;
                }
            }
            .ant-select-arrow {
                svg {
                    width: 15px;
                    color: $mid-night;
                }
            }
        }

        .ant-divider {
            display: block;
            width: calc(100vw - 18px);
            margin-left: -21px;
        }
    }
}

@media (max-width: 576px) {
    .setting-sider-bar-mobile__dropdown {
        .ant-select-item {
            padding: 0;
            .setting-sider-bar__item {
                display: flex;
                align-items: center;
                color: $mid-night;
                height: 50px;
                padding: 16px;
                svg {
                    width: 15px;
                    margin-right: 5px;
                }
            }
        }
        .ant-select-item-option-selected {
            background-color: #f6f6f8;
        }
    }
}
