.success-upgrade-modal {
    padding: 50px ;

    @media (max-width: 576px) {
        padding: 48px 24px 24px 24px ;
    }

    &__header {
        display: flex;
        justify-content: center;
    }
    &__closeIcon {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 24px;
        border-radius: 50% !important;
        height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;

        svg:hover {
            transition: all ease-in 0.3;
            opacity: 0.9;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
       img {
        width: 310px;
        height: 317px;
       }
    }

    &__footer {
        margin-top: 10px;
        text-align: center;
        
        .title {
           color: #0185de;
           font-size: 18px;
           font-weight: 500;
           line-height: 1.33;
           letter-spacing: 0.04px;
        }

        .desc {
            margin-top: 12px;
            color: #4b546e;
            line-height: 1.43;
            letter-spacing: 0.03px;
        }

        .subscription {
            color: #0185de;
            margin-top: 24px;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                opacity: 0.9;
                transition: 0.5s all ease-in-out;
            }
        }
    }
}
