@import "../../../../../scss/helpers/variables";
.file-detail-container {
    .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: #4d546c;
        line-break: anywhere;
        &.info {
            padding-bottom: 16px;
            border-bottom: 1px solid $iron;
        }

        &.info + .info-item {
            margin-top: 16px;
        }

        &.file-name {
            margin: 10px 0;
        }
        
    }
    .image {
        margin-top: 24px;
        .media-item__bg {
            background-image: unset;
        }
        img, video {
            height: auto;
            width: 100%;
        }

        .details-img {
            height: 252px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            cursor: pointer;
        }
    }
    .file-action {
        > div {
            line-height: 1.43;
            letter-spacing: 0.03px;
            color: $mid-night;
            margin-bottom: 12px;
            cursor: pointer;
            width: fit-content;
            border-bottom: 1px dashed #00000000;
            svg {
                width: 16px;
                height: 16px;
                margin-bottom: -1px;
                margin-right: 5px;
            }

            &:not(.file-saved, .disabled):hover {
                border-bottom: 1px dashed $mid-night;
            }
            &.disabled, .disabled {
                pointer-events: none;
                opacity: 0.4;
            }
            &:has(.disabled):hover {
                border-bottom: none;
            }
        }

        .file-saved {
            svg {
                width: 14px;
                height: 14px;

                path {
                    fill: $mid-night !important;
                }
            }
        }
    }
    .info-item {
        display: flex;
        justify-content: space-between;
        margin: 8px 0;
        &__label {
            color: $nobel-color;
        }
        &__value {
            color: $mid-night;
        }
    }
    .more-info {
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: 0.02px;
        color: $nobel-color;
        a {
            text-decoration: underline;
            color: inherit;
            &:hover {
                filter: brightness(0.8);
                transition: all .3s;
            }
        }
    }
}