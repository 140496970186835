@import "../../../../scss/helpers/variables";
.create-user-modal {
    width: 90%;
    max-width: 454px;

    .ant-modal-footer,
    .ant-modal-body {
        background-color: #f7f8fb;
    }
}

.edit-user-site-tab {
    .ant-tabs-nav {
        &::before {
            right: -24px;
            left: -24px;
        }
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    background-color: #fff;
                    border: none;
                    border-bottom: 1px solid #f0f0f0;
                    transition: none;
                    &.ant-tabs-tab-active {
                        background-color: #f7f8fb;
                        border: 1px solid #f0f0f0;
                        border-bottom: none;
                        border-radius: 4px;
                    }
                    .ant-tabs-tab-btn {  
                        font-size: 14px;
                        line-height: 1.43;
                        letter-spacing: 0.03px;
                        color: #4d546c;
                        text-shadow: none;
                    }
                }
            }
        }
    }
}
