.limited-modal {
    padding: 56px 50px 24px 50px ;

    @media (max-width: 576px) {
        padding: 48px 24px 24px 24px ;
    }

    &__header {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
    }
    &__closeIcon {
        position: absolute;
        right: 16px;
        top: 16px;
        background-color: #e3e4e6;
        padding: 3px;
        width: 24px;
        border-radius: 50% !important;
        height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;

        svg:hover {
            transition: all ease-in 0.3;
            opacity: 0.9;
        }
    }

    &__content {
        .title {
            color: #f35848;
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 16px;
        }

        .desc {
            color: #4b546e;
            text-align: center;
            line-height: 1.43;
            letter-spacing: 0.03px;
        }
    }

    &__footer {
        margin-top: 30px;
        .understandBtn {
            width: 100%;
        }
        .checkUsageBtn {
            width: 100%;
            margin-top: 10px;
            color: #4b546e;
        }
    }
}
