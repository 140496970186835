@import "../../../scss/helpers/variables";

.app-date-range-picker {
    .ant-picker {
        visibility: hidden;
        height: 0;
        width: 0;
    }

    &__popover {
        display: flex;

        .time-select {
            padding: 4px 12px;
            border-right: 1px solid #e3e4e6;

            .ant-btn {
                color: #353d53;
                padding: 0;
                width: 100px;

                &.active {
                    color: #0185de;
                    font-weight: 500;
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        > button {
            color: #0185de;
        }
    }

    &__apply {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        border-top: 1px solid #e3e4e6;
        padding: 10px 22px;

        button {
            color: #0185de;
            padding: 0;
        }
    }

    .app-range-picker__footer-wrapper__action-wrapper {
        border-bottom: 1px solid #e3e4e6;
        padding-bottom: 16px;
        margin-bottom: 24px;
    }

    &-value {
        display: flex;
        padding-top: 4px;
        flex: 1;
        position: relative;

        .separator {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        span {
            height: 36px;
            border-bottom: solid 1px #e3e4e6;
            display: flex;
            align-items: center;
            padding: 0 12px;
            transition: all 0.3s;
            justify-content: center;
            width: 100%;

            svg {
                width: 20px;
                height: 20px;
                color: #9b9b9b;
                margin-right: 10px;
            }

            &.active {
                border-bottom: solid 2px #0185de;

                svg {
                    color: inherit;
                }
            }
        }
    }

    &-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 22px;
        margin-bottom: 10px;
        padding: 0 14px;

        .btn-pre,
        .btn-next {
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .ant-picker-calendar .ant-picker-panel {
        border-top: none;
        padding: 0 5px;

        .ant-picker-content th {
            font-size: 10px;
            color: #9b9b9b;
            padding: 8px;
        }
    }

    .ant-picker-cell {
        padding: 0;

        &:not(.ant-picker-cell-in-view) {
            pointer-events: none;
            .custom-date-cell {
                display: none;
            }
        }

        &.ant-picker-cell-disabled {
            pointer-events: none;

            .custom-date-cell-value {
                border-radius: 0;
                background: #fff;
            }

            &::before {
                display: none;
            }
        }
    }

    .custom-date-cell {
        width: 100%;
        height: 100%;

        .custom-date-cell-value {
            border-radius: 4px;
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            line-height: 24px;
            border-radius: 2px;
            transition:
                background 0.3s,
                border 0.3s;
            font-size: 13px;
        }

        &__selected {
            .custom-date-cell-value {
                color: #fff;
                background: #0185de;
                border-radius: 3px;
            }
        }

        &__inrange {
            background-color: #f7f8fb;
            color: #9b9b9b;
        }
    }

    .ant-picker-cell-today {
        .custom-date-cell-value {
            border: 1px solid #5d6164;
        }
    }
}

.app-range-picker-has-border {
    border: 1px solid #e3e4e6;
}
.app-range-picker-disabled {
    background-color: #f7f8fb !important;
    color: $nobel-color !important;
    cursor: not-allowed;
}

.app-range-picker-home-date-range {
    background-color: #80828e !important;
    color: $mid-night !important;
    cursor: not-allowed;

    svg {
        color: #5b5d69 !important;
    }
}
