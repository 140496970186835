@import "../../../../../scss/helpers/variables";
.create-approval-modal {
  width: 90vw !important;
  max-width: 1700px;
  height: 90vh;
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    .ant-modal-header {
      border-radius: 4px 4px 0 0;
    }
    .ant-modal-body {
      flex-grow: 1;
      background-color: #f7f8fb;
      padding: 0;
      overflow-y: scroll;
    }
    .ant-modal-footer {
      background-color: #f7f8fb;
      padding-top: 24px;
      border-top: 1px solid $iron;
      .create-approval-modal-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .create-approval-container {
    display: flex;
    height: 100%;
    .create-approval-side-bar {
      width: 25%;
      border-right: 1px solid $iron;
      height: 100%;
      padding: 24px;
      .date-title {
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: $mid-night;
      }
      .date-description {
        font-size: 10px;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.02px;
        color: $nobel-color;
      }
      .app-date-picker {
        margin-top: 16px;
        .app-date-picker-date {
          width: 100%;
        }
      }
    }
    .create-approval-main {
      width: 75%;
      padding: 24px;
      .create-approval-main-header {
        &__left {
          .title {
            line-height: 1.43;
            letter-spacing: 0.03px;
            color: $mid-night;
          }
          .action {
            display: flex;
            & > div {
              margin-right: 18px;
              font-size: 10px;
              font-weight: 500;
              line-height: 1.6;
              letter-spacing: 0.02px;
            }
            .detail {
              color: $nobel-color;
            }
            .select-all {
              color: $primaryColor;
              cursor: pointer;
            }
            .deselect-all {
              color: #353d53;
              cursor: pointer;
            }
            &.action-no-content {
              .select-all,
              .deselect-all {
                color: $nobel-color;
              }
            }
          }
        }
        &__right {
          display: flex;
          .switch-page {
            display: flex;
            margin-right: 20px;
            align-items: center;
            & > button {
              border-color: $iron;
              padding: 4px 10px;
              svg {
                margin: 0 0 1px 0;
              }
            }
            .prev {
              border-bottom-right-radius: 0px;
              border-top-right-radius: 0px;
              border-right: none;
            }
            .next {
              border-bottom-left-radius: 0px;
              border-top-left-radius: 0px;
            }
          }
        }
      }
      .no-content {
        height: calc(100vh - 400px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
          font-size: 15px;
          font-weight: 500;
          line-height: 1.47;
          color: $mid-night;
          margin: 24px 0 8px 0;
        }
        .description {
          font-size: 12px;
          line-height: 1.33;
          letter-spacing: 0.02px;
          color: #4d546c;
          margin-bottom: 24px;
        }
      }
      .list-content {
        margin-top: 13px;
        // display: flex;
        width: 100%;
        flex-wrap: wrap;
        .ant-row {
          .ant-col {
            position: relative;
            .content-card {
            }
            .overlay {
              display: none;
              position: absolute;
              top: 0;
              bottom: 0;
              right: 10px;
              left: 10px;
              border-radius: 4px;
              .status {
                width: 36px;
                height: 36px;
                position: absolute;
                top: 12px;
                right: 12px;
                border-radius: 50%;
                border: solid 2px $primaryColor;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                  display: none;
                  color: #fff;
                }
              }
              &.checked {
                display: block;
                border: solid 3px $primaryColor;
                background-color: rgba(1, 133, 222, 0.4);
                .status {
                  border: none;
                  background-color: $primaryColor;
                  top: 9px;
                  right: 9px;
                  svg {
                    display: block;
                  }
                }
              }
            }

            &:hover {
              cursor: pointer;
              .overlay {
                display: block;
                background-image: linear-gradient(
                  to bottom,
                  rgba(0, 0, 0, 0.8),
                  rgba(128, 128, 128, 0) 118%
                );
              }
            }
          }
        }
      }
      .ant-alert {
        margin-top: 13px;
      }
    }
  }
}

// Confirm Create Modal
.confirmApproval-modal {
  margin-top: 13px;
  & > ul {
    padding-left: 0px;
    & > li {
      display: flex;
      align-items: center;
    }
    svg {
      color: $blue-color;
      margin-right: 8px;
    }
  }

  &-icon {
    svg {
      display: flex;
      align-items: center;
    }
    margin-top: -2px;
  }
}
.auto-approve-input {
  height: 36px;
  width: 110px;
  input {
    text-align: right;
    height: 28px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.03px;
  }
  .ant-input-suffix {
    color: $nobel-color;
    height: 28px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.03px;
  }
}
