@import "../../../../../../../../scss/helpers/variables";

.photo-video-tab {
  .ant-select {
    min-width: 50%;
    .ant-select-selector {
      .ant-select-selection-placeholder {
        color: $mid-night;
      }
    }
    .ant-select-arrow {
      span {
        svg {
          color: $mid-night;
        }
      }
    }
    &.ant-select-disabled {
      .ant-select-selector {
        .ant-select-selection-item {
          color: $mid-night;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .photo-video-tab {
    .ant-select {
      width: 100%;
    }
  }
}
