@import "../../../scss/helpers/variables";

.campaign {
    &__select {
        width: 100%;
    }
    &__search {
        padding: 8px 12px 12px 12px;
        display: flex;
        .ant-input-affix-wrapper {
            padding: 0 4px 0 0;
            input {
                height: 36px;
                padding: 0 12px;
                &:focus {
                    border-color: none;
                }
            }
            .ant-input-suffix {
                svg {
                    color: $nobel-color;
                }
            }
        }
        .refresh-btn {
            height: 38px;
            width: 38px;
            border-radius: 4px;
            border-color: $iron;
            padding: 0;
            margin-left: 8px;
            svg {
                margin: 0;
                width: 18px;
                height: 20px;
                color: $nobel-color;
                &:hover {
                    color: #000;
                }
            }
        }
    }

    &__icon {
        svg {
            width: 18px;
            height: 18px;
            color: $nobel-color;
            display: flex;
        }
    }

    &__input {
        border-radius: 4px;
    }

    &__display {
        &-name {
            color: $mid-night;
        }
        &-highlight {
            background-color: #f2d249 !important;
        }
    }

 
}