@import "../../../scss/helpers/variables";
.main-header {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    align-items: center;

    &__time {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        // padding: 8px 3px !important;
        transition: all 0.2s;
        border-radius: 4px;
        gap: 5px;
        color: #f7f8fb;
        margin-right: 25px;
        & > svg {
            color: #9b9b9b;
            height: 18px;
            width: 18px;
        }
        &:hover {
            // background-color: #4b546e;
            text-decoration: underline;
        }
    }

    &__left {
        .ant-btn {
            svg {
                width: 30px;
                height: auto;
                color: #ffffff;
            }
        }
    }

    &__paid-plan {
        background-color: #5e5a5e;
        color: #f59f00;
        font-size: 12px;
        font-weight: 500;
        border-radius: 6px;
        padding: 5px 8px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        gap: 4px;

        svg {
            width: 13px;
            height: 13px;
            margin-top: 2px;
        }
    }

    &__rightControl {
        display: flex;
        align-items: center;

        .ant-dropdown {
            left: 0px !important;
        }

        button {
            color: #e3e4e6;

            &:focus,
            &:hover {
                color: #ffffff;
            }

            svg {
                width: 18px;
                height: 18px;
            }
        }

        .btn-notification {
            position: relative;
            .new-notification {
                width: 8px;
                height: 8px;
                background-color: $sunset-color;
                border-radius: 50%;
                position: absolute;
                top: 4px;
                right: 4px;
            }
        }
    }

    &__user {
        display: flex;
        align-items: center;
        font-weight: 300;
        margin-left: 10px;
        cursor: pointer;

        &__name {
            display: flex;
            flex-direction: column;
            margin-left: 8px;

            :last-child {
                font-size: 12px;
                color: #a1aac7;
            }
        }
    }

    &__logo {
        display: flex;
        align-items: center;

        span {
            background-color: #0185de;
            text-transform: uppercase;
            color: #ffffff;
            padding: 4px 10px;
            border-radius: 4px;
            margin-left: 15px;
            font-size: 12px;
        }
    }

    &__userInfo {
        display: flex;
        gap: 12px;
        align-items: center;

        &-name {
            color: #4b546e;
        }

        &-email {
            color: #9b9b9b;
            font-size: 12px;
            line-height: 12px;
        }
        &-icon {
            margin-right: 8px;
            display: flex;
            svg {
                width: 16px;
                height: 16px;
                color: $mid-night;
            }

            &.chart-icon {
                svg {
                    width: 17px;
                    height: 17px;
                    color: $mid-night;
                }
            }
        }
        &-title {
            color: #4b546e !important;
        }
    }
}

@media only screen and (min-width: 576px) {
    .header-mobile {
        display: none;
    }
}

@media only screen and (max-width: 576px) {
    .header-desktop {
        display: none;
    }
}

.navbar-toggler {
    font-size: 16px;
    color: #ffffff !important;

    &:focus {
        color: #ffffff;
    }

    &.open {
        .navbar-toggler-icon {
            transform: rotate(45deg);
            transition:
                top 0.3s,
                bottom 0.3s,
                transform 0.3s 0.3s,
                opacity 0s 0.3s;

            &:after {
                bottom: 0;
                opacity: 0;
            }

            &:before {
                top: 0;
                transform: rotate(-90deg);
            }
        }
    }
}

.navbar-toggler-icon {
    display: inline-block;
    vertical-align: middle;
    height: 2px;
    width: 1.25em;
    background: currentColor;
    border-radius: 10px;
    transition:
        top 0.2s 0.2s,
        bottom 0.2s 0.2s,
        transform 0.2s,
        opacity 0s 0.2s;
    position: relative;
}

.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
    content: "";
    display: block;
    height: inherit;
    width: inherit;
    border-radius: inherit;
    background: inherit;
    position: absolute;
    left: 0;
    transition: inherit;
}

.navbar-toggler-icon:before {
    top: -0.45em;
}

.navbar-toggler-icon:after {
    bottom: -0.45em;
}

.popover-notification {
    .ant-popover-content {
        .ant-popover-arrow {
            right: 8px;
        }
        .ant-popover-inner-content {
            padding: 0;
        }
    }
}

.header-menu-dropdown {
    .ant-dropdown-menu-item {
        padding: 15px 20px;
        &:first-child {
            padding-bottom: 5px;
        }
    }
}

.header-menu-dropdown.mobile {
    left: 8px;
    width: calc(100% - 16px);
}
