.get-premium-modal {
    &__banner {
        width: 100%;
        position: relative;
        height: 220px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    &__closeIcon {
        position: absolute;
        right: 16px;
        top: 16px;
        background-color: #fff;
        padding: 3px;
        width: 24px;
        border-radius: 50% !important;
        height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;

        svg:hover {
            transition: all ease-in 0.3;
            opacity: 0.9;
        }
    }
    &__heading {
        position: absolute;
        top: 19%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        padding: 0 40px;

        @media (max-width: 576px) {
            top: 15%;
            padding: 0 24px;
        }
    }

    &__title {
        color: #4d546c;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
        line-height: 1.33;
        letter-spacing: 0.05px;

        @media (max-width: 576px) {
            font-size: 20px;
        }
    }

    &__desc {
        bottom: 0;
        text-align: center;
        color: #9b9b9b;
        line-height: 1.43;
        letter-spacing: 0.03px;

        @media (max-width: 576px) {
            font-size: 12px;
        }
    }

    &__pricing {
        margin-top: 24px;
        border: solid 1px #0185de;
        padding: 16px;
        border-radius: 4px;
        box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 40px 30px 40px;

        @media (max-width: 576px) {
            margin: 24px;
        }
    }

    &__plan {
        display: flex;
        align-items: center;
        gap: 12px;
        img {
            width: 40px;
            height: 40px;
        }
    }

    &__premium {
        font-size: 16px;
        font-weight: 500;
        color: #4b546e;
    }
    &__price {
        color: #4d546c;
        font-size: 12px;
        gap: 4px;
        display: flex;
        align-items: center;
    }
    &__checkIcon {
        width: 24px;
        height: 24px;
    }

    &__details {
        margin: 0 40px;
        @media (max-width: 576px) {
            margin: 24px;
        }
    }
    &__whatYouGet {
        color: #4d546c;
        font-weight: 500;
        margin-bottom: 14px;
    }
    &__detailsItem {
        margin-bottom: 8px;
        display: flex;
        gap: 8px;
        color: #4d546c;
    }

    &__discountIcon {
        width: 18px;
        height: 18px;
    }

    &__detailsItemText {
        margin-top: -1px;
    }

    &__footer {
        margin: 25px 40px;
        padding-bottom: 40px;

        @media (max-width: 576px) {
            margin: 24px;
        }
    }

    &__premiumBtn {
        width: 100%;
    }

    &__questions {
        text-align: center;
        width: 100%;
        margin-top: 12px;
        color: #4d546c;
        span {
            color: #9bc13c;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                opacity: 0.85;
                transition: all ease-in 0.3;
            }
            a:hover {
                color: #9bc13c;
            }
        }
    }

    &__helpIcon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
