@import "../../../../../scss/helpers/variables";
.social-dashboard-content {
    .home-page__session {
        margin-bottom: 16px;
    }
    .rl-card{
        .rl-card-title {
            margin-bottom: 16px;
        }
    }
    .top-engaged {
        .top-engaged-table {
            margin-left: -16px;
            margin-right: -16px;
            margin-top: 2px;
            margin-bottom: -16px;
    
            .ant-table {
                background-color: transparent;
            }
    
            .ant-table-thead {
                font-size: 10px;
                text-transform: uppercase;
    
                > tr > th {
                    border: none;
                    background-color: #f2f3f4;
                    color: #9b9b9b;
    
                    &::before {
                        display: none;
                    }
                }
            }
    
            .ant-table-cell {
                padding: 9px 16px;
    
                &:first-child {
                    > span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                    }
                }
            }
    
            .ant-table-tbody > tr > td {
                border: none;
                border-bottom: 1px solid $iron;
                padding: 17px 16px;
            }
        }
    }
    .best-time-to-post {
        display: flex;
        &__chart {
            width: 70%;
        }
        &__legend {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 30px 100px;
            .legend-item {
                display: flex;
                justify-content: space-between;
                &__label {
                    color: $nobel-color;
                }
                &__value {
                    color: $mid-night;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .social-dashboard-content {
        .ant-row {
            margin-bottom: 8px;
            .ant-col:not(:last-child) {
                margin-bottom: 8px;
            }
        }
        .best-time-to-post {
            flex-direction: column;
            &__chart {
                width: 100%;
            }
            &__legend {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 10px 90px;
                .legend-item {
                    display: flex;
                    justify-content: space-between;
                    &__label {
                        color: $nobel-color;
                    }
                    &__value {
                        color: $mid-night;
                    }
                }
            }
        }
    }
}
