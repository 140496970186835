.notifications-tab {

    .head-title {
        color: #4d546c;
        margin-bottom: 20px;
    }

    .card-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
       
    }

    .card {
        padding: 16px 20px;
        border-radius: 4px;
        border: solid 1px #e3e4e6;
        background-color: #fff;
        height: 100%;
        
        .card-title {
            color: #4d546c;
            font-weight: 500;
        }

        .recipients-desc {
            margin: 16px 0px;
        }

        .switches {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 12px;

            color: #4d546c;

            div {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }

    .recipients-tabs {
        margin: 0px -20px;
        .tab {
            cursor: pointer;
            color: #4b546e;
            padding: 10px 20px;
            &.active {
                background-color: #F6F6F8;
            }

            &:hover {
                background-color: #F6F6F8;
                transition: all 0.2s;
            }
        }
    }

    .save-btn {
        margin-top: 16px;
        float: right;
    }

    .notification-note {
        margin-top: 12px;
        font-size: 12px;
        color: #9b9b9b;
    }
}