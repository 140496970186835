@import "../../../scss/helpers/variables";
.notification {
    min-width: 410px;
    .notification-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 12px;
        border-bottom: 1px solid #e3e4e6;
        .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: 0.03px;
            color: #4d546c;
        }
        .action {
            display: flex;
            justify-content: space-between;
            div {
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: $mid-night;
                margin: 0 16px;
                cursor: pointer;
            }
            &.no-data {
                div {
                    opacity: 0.5;
                }
            }
        }
    }
    .notification-list {
        max-height: 350px;
        overflow-y: auto;
        padding-bottom: 6px;
        .notification-item {
            padding: 10px 12px;
            border-bottom: 1px solid $iron;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            transition: all .2s;

            &:hover {
                background-color: #F7F8FB;
            }
            
            .container {
                .title {
                    line-height: 1.43;
                    letter-spacing: 0.03px;
                    width: 352px;
                    display: -webkit-box;
                    max-height: 60px;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    word-break: break-word;
                    overflow: hidden;
                    color: #4d546c;
                }
                .description {
                    width: 352px;
                    display: -webkit-box;
                    max-height: 35px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    word-break: break-word;
                    overflow: hidden;
                    color: #4d546c;
                    font-size: 10px;
                    line-height: 1.4;
                    letter-spacing: 0.02px;
                    margin: 2px 0;
                    &.content-success {
                        border-radius: 4px;
                        border: solid 1px $iron;
                        background-color: #f7f8fb;
                        padding: 4px 6px;
                    }
                    &.content-error {
                        border-radius: 4px;
                        border: solid 1px $sunset-color;
                        background-color: rgba(243, 88, 72, 0.1);
                        padding: 4px 6px;
                        color: $sunset-color;
                    }
                    &.content-schedule {
                        border-radius: 4px;
                        border: solid 1px $primaryColor;
                        background-color: rgba(1, 133, 222, 0.1);
                        color: $primaryColor;
                        padding: 4px 6px;
                    }
                }
                .time {
                    font-size: 10px;
                    line-height: 2;
                    letter-spacing: 0.02px;
                    color: $nobel-color;
                }
            }
            .btn-unread {
                display: none;
            }

            &.unread {
                .container {
                    .title {
                        color: #4d546c;
                        font-weight: 500;
                    }
                }
                .btn-unread {
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $primaryColor;
                    margin-left: 16px;
                }

                &.error {
                    .container {
                        .title {
                            color: $sunset-color;
                        }
                    }
                }
            }
        }
        .no-data {
            display: flex;
            justify-content: center;
            line-height: 1.43;
            letter-spacing: 0.03px;
            color: $nobel-color;
            margin: 17px 0;
        }
        .skeleton {
            padding: 10px 12px;
            .ant-skeleton.title {
                height: 16px;
                padding-right: 16px;
                .ant-skeleton-button {
                    height: 16px;
                }
            }
            .ant-skeleton.time {
                height: 12px;
                .ant-skeleton-button {
                    height: 12px;
                    width: 25px;
                }
            }
        }
    }
}
