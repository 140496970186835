.spin-loading {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.view-report-wrapper {
    * {
        max-width: 100%;
    }
}