@import "../../../../../scss/helpers/variables";
.add-connection-modal {
    .list-connection {
        width: 100%;
        .connection-item {
            display: flex;
            align-items: center;
            height: 56px;
            width: 100%;
            border-radius: 4px;
            box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
            border: solid 1px $iron;
            background-color: #fff;
            padding: 10px;
            cursor: pointer;
            transition: 0.4s;
            .icon {
                margin-right: 10px;
                img {
                    width: 36px;
                    height: 36px;
                }
            }
            .label {
                font-weight: 500;
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: $mid-night;
            }

            &:hover {
                border: 1px solid $primaryColor;
            }
        }
    }
}