@import "../../../scss/helpers/variables";

.social-content {
  width: 100%;
  color: $mid-night;
  &-header {
    width: 100%;
    > .ant-row {
      width: 100%;
    }
  }
  &-body {
    .no-connect {
      position: relative;
      .background {
        position: absolute;
        height: calc(100vh - 227px);
        width: 100%;
        background-image: url("../../../assets/images/bg-social-content.png");
        background-repeat: no-repeat;
      }

      .add-connect {
        display: flex;
        justify-content: center;
        padding-top: 140px;
      }
    }

    .container {
      &__header {
        .total-post {
          font-weight: 500;
          color: #4d546c;
        }
        .total-post-detail {
          font-size: 12px;
          line-height: 1.33;
          letter-spacing: 0.02px;
          color: #9b9b9b;
        }
        .filter-by {
          width: 210px;
          display: flex;
          justify-content: space-between;
          border: 1px solid $iron;
        }
      }
      &__body {
        margin: 40px 0px;
        .create-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 190px;

          &-title {
            font-size: 15px;
            font-weight: 500;
            line-height: 1.47;
            color: $mid-night;
            margin: 24px 0 8px 0;
          }
          &-description {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: 0.02px;
            color: #4d546c;
            margin-bottom: 24px;
          }
          &.no-result {
            .create-content-title,
            .create-content-description {
              color: $nobel-color;
            }
          }

          .group-btn {
            display: flex;
            button {
              margin-right: 12px;
            }
          }
        }
        .list-content {
          .ant-row {
            min-height: calc(100vh - 500px);
            .ant-col:last-child {
              flex-grow: 1;
            }
          }
        }
      }
    }

    .cards {
    }
  }
  .content-pagination.ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
}

.filter-content-popover {
  .ant-popover-content {
    width: 400px;
    .ant-popover-inner-content {
      padding: 16px;
    }
  }
}

.content-card-popover-schedule {
  z-index: 99;
  padding-left: 0;
  padding-right: 0;
  width: 224px;
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      .content-card-popover-schedule__content {
        .app-date-picker {
          flex-direction: column;
          .app-date-picker-date {
            width: 100%;
            margin-bottom: 6px;
          }
        }
        .group-btn {
          display: flex;
          justify-content: space-between;
          margin-top: 22px;
        }
      }
    }
  }
}
.content-modal {
  .ant-modal-content {
    border-radius: 4px;
    .ant-modal-header {
      border-radius: 4px 4px 0 0;
    }
    .ant-modal-footer {
      border-radius: 0 0 4px 4px;
    }
  }
}
.edit-content-modal {
  width: 90% !important;
  max-width: 90% !important;
  height: 90vh;
  top: 40px;

  .ant-modal-content {
    background-color: #f7f8fb;
    height: 90vh;
    .ant-modal-body {
      min-height: calc(90vh - 55px);
    }
    .ant-modal-footer {
      justify-content: center;
      .create-content-modal-footer {
        width: 472px;
      }
    }
  }
}
