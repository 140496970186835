
.social-dashboard-audience {
    .rl-card {
        margin-bottom: 16px;
        .rl-card-title {
            margin-bottom: 24px;
        }
        .rl-card-top-regions {
            .ant-row {
                .top-regions-map {
                    min-height: 162px;
                    width: 100%;
                }
                .top-regions-legends {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                    padding: 10px;
                    &__item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        &__name {
                            flex: 1;
                            color: #9b9b9b;
                        }
                        span {
                            &:first-child {
                                width: 10px;
                                height: 10px;
                                border-radius: 3px;
                                margin-right: 8px;
                            }
                        }
                    }

                }
            }
        }
        .ages-chart {
            margin-bottom: -15px;
        }
    }
}
@media (max-width: 576px) {
    .social-dashboard-audience {
        .rl-card {
            .rl-card-top-regions {
                .ant-row {
                    flex-direction: column;
                    .top-regions-map {
                        height: 162px;
                    }
                }
            }
        }
    }
}