@import "../../../scss/helpers/variables";

.app-select-country {
    &__select {
        width: 100%;
    }
    &__search {
        padding: 8px 12px 12px 12px;
    }

    &__icon {
        svg {
            width: 18px;
            height: 18px;
            color: $nobel-color;
            display: flex;
        }
    }

    &__input {
        border-radius: 4px;
    }

    &__display {
        &-name {
            color: $mid-night;
        }
        &-highlight {
            background-color: #f2d249 !important;
        }
    }
}