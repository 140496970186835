.upload-media-modal {
  .ant-modal-content {
    border-radius: 4px;
    .ant-modal-header {
      border-radius: 4px 4px 0 0;
    }
    .ant-modal-body {
      border-radius: 0 0 4px 4px;
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      padding: 0;

      .uppy-Container {
        .uppy-Dashboard-inner {
          width: 100% !important;
          .uppy-Dashboard-AddFiles {
            border: none;
          }
        }
      }
    }
  }
}
