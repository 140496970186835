.select-permissons.ant-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 3px;
    padding-left: 11px;
    border-color: #e3e4e6;

    > span {
        overflow: hidden;
        text-overflow: ellipsis;
        color: #4b546e;
    }
    > svg {
        color: #9b9b9b;
    }
    .select-permission-btn {
        justify-content: space-between;
    }
}

.filter-item {
    &.permission-item {
        &:first-child {
            border-bottom: 1px solid #e3e4e6;
            padding-bottom: 8px;
        }

        &:last-child {
            border-top: 1px solid #e3e4e6;
            padding-top: 8px;
        }

        &:last-child,
        &:first-child {
            margin-left: -13px;
            margin-right: -13px;
            padding-left: 13px;
            padding-right: 13px;
        }

        label {
            span {
                color: #4d546c;
            }
        }
    }
}
