@import "../../scss/helpers/variables";
.main-layout-review {
  .page-container-width {
    max-width: unset;
    background-color: #fff;
    flex-grow: 1;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: $iron;
  }
  .main-header-review {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .second-header-review {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    .second-header-review-left {
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.03px;
        color: $mid-night;
      }
    }
    .second-header-review-right {
      cursor: pointer;
      .collapborator {
        & > span {
          margin-right: 8px;
        }
        .user-name-mobile {
          display: none;
        }
      }
    }
  }
  .app-body {
    background-color: #f7f8fb;
    padding-top: 40px;
    .review-content-wrap {
      width: 100%;
      height: 100%;
      min-height: 70vh;
      .list-media-create-content {
        .media-social-source {
          height: auto;
          max-height: 260px;
        }
      }
    }
  }
  .ant-menu {
    display: none;
  }
}

.create-guest-modal {
  .confirm-modal-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .ant-btn-text {
      color: $mid-night;
      line-height: 1.43;
      letter-spacing: 0.03px;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .main-layout-review {
    .app-body {
      background-color: #f7f8fb;
      padding: 24px 16px 0;
    }
    .ant-menu {
      display: block;
      width: 100%;
      background-color: transparent;
      border-right: none;
      .ant-menu-submenu-title {
        display: none;
      }
      .ant-menu-submenu {
        margin: 0 -8px;
      }
      .ant-menu-item {
        border-top: 1px solid $iron;
        span {
          text-align: center;
        }
      }
    }
    .second-header-review {
      .second-header-review-right {
        .collapborator {
          display: flex;
          align-items: center;
          .user-name {
            display: none;
          }
          .user-name-mobile {
            display: flex;
            align-items: center;
            svg {
              margin: 0;
              width: 16px;
              height: 18px;
              line-height: 1.25;
              letter-spacing: 0.03px;
              color: $nobel-color;
            }
          }
        }
      }
    }
  }
  .dropdown-menu-collaborator {
    display: none;
  }
}
