@import "../../../../../../scss/helpers/variables";
.content-card {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
  border: solid 1px $iron;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  &.content-card-error {
    border: solid 1px #f59f00;
  }
  &-top {
    padding: 15px 16px 0 16px;

    &__action {
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.02px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;
      &-left {
        display: flex;
        .post-status,
        .approval-status {
          margin-right: 8px;
          display: flex;
        }
        svg {
          width: 12px;
          height: 13px;
          line-height: 1.33;
          letter-spacing: 0.02px;
          margin-right: 4px;
        }
        .post-status {
          &.post {
            color: $nobel-color;
          }
          &.schedule {
            color: $primaryColor;
          }
          &.error {
            color: $sunset-color;
            svg {
              width: 15px;
            }
          }
        }
        .approval-status {
          &.not_required {
            color: $nobel-color;
          }
          &.declined {
            color: $sunset-color;
          }
        }
      }
      &-right {
        .ant-dropdown-trigger {
          height: 20px;
          svg {
            width: 18px;
            height: 20px;
            line-height: 1.11;
            letter-spacing: 0.04px;
            color: $mid-night;
          }
          svg:hover,
          svg:focus {
            color: #3a83d7;
            cursor: pointer;
          }
        }
      }
    }
    &__tags {
      white-space: nowrap;
      overflow: auto;
      margin-bottom: 14px;
      .tag-social {
        display: inline-block;
        margin-right: 4.7px;
      }
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-track-piece {
        background: $iron;
      }
      &::-webkit-scrollbar-thumb {
        background: $nobel-color;
        border: none;
      }
    }
    &__network {
      min-height: 39px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .avatar-social-wrap {
        width: 39px;
        height: 39px;
        img {
          border-radius: 50%;
        }
      }
      .warning-limit {
        svg {
          color: #f59f00;
        }
      }
    }
    &__caption {
      margin-top: 12px;
      display: -webkit-box;
      height: 57px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1.43;
      letter-spacing: 0.03px;
      .caption {
        a {
          color: #3a83d7;
        }
        .gmb-event-title {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .no-content {
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: $nobel-color;
      }
    }
  }
  &-bottom {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &__media {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      img,
      video {
        max-height: 150px;
      }
      .empty {
        background-color: #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
        width: 100%;
        margin-top: 12px;
        &.bg-warning {
          flex-direction: column;
          background-color: rgba(245, 159, 0, 0.08);
          color: #f59f00;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    &__metric {
      height: 41px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      &-item {
        display: flex;
        line-height: 1.43;
        letter-spacing: 0.03px;
        cursor: pointer;
        svg {
          width: 14px;
          height: 18px;
          margin-right: 5px;
        }
        &.share {
          svg {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }
}
