.apexcharts-canvas {
    &.disabled {
        pointer-events: none;
    }
}

.custom-tooltip-wrapper {
    display: flex;
    padding: 8px;
    align-items: center;
    font-size: 13px;

    .tooltip-color {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        display: block;
        margin-right: 8px;
    }

    .tooltip-name {
        margin-right: 8px;
    }
}
