@import "../../../scss/helpers/variables";
.social-calendar {
    width: 100%;
    &-body {
        position: relative;
        .background {
            position: absolute;
            height: calc(100vh - 227px);
            width: 100%;
            background-image: url("../../../assets/images/blur-bg-social-calendar.png");
            background-repeat: no-repeat;
        }
        .add-connect {
            display: flex;
            justify-content: center;
            padding-top: 170px;
        }
    }

    .calendar-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 17px;
        .calendar-header-left {
            .avatar-social-wrap {
                width: 42px;
                height: 42px;
            }
        }
        .calendar-header-right {
            display: flex;
            justify-content: flex-end;

            .filter-by {
                width: 210px;
                display: flex;
                justify-content: space-between;
                border: 1px solid $iron;
            }
        }
    }

    .ant-picker-calendar {
        border-radius: 4px;
        box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
        border: solid 1px $iron;
        background-color: #fff;
        .calendar-header-render {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0 10px 0;
            .calendar-selector {
                display: flex;
                font-size: 14px;
                line-height: 1.43;
                letter-spacing: 0.03px;
                color: $mid-night;
                margin: 0 12px;
                .month-selector {
                    margin-right: 4px;
                }
            }
            svg {
                height: 14px;
                width: 15px;
            }
        }
        .ant-picker-panel {
            .ant-picker-content {
                thead tr > th:nth-child(1)::after {
                    content: "nday";
                }
                thead tr > th:nth-child(2)::after {
                    content: "nday";
                }
                thead tr > th:nth-child(3)::after {
                    content: "esday";
                }
                thead tr > th:nth-child(4)::after {
                    content: "dnesday";
                }
                thead tr > th:nth-child(5)::after {
                    content: "ursday";
                }
                thead tr > th:nth-child(6)::after {
                    content: "iday";
                }
                thead tr > th:nth-child(7)::after {
                    content: "turday";
                }
                thead {
                    background-color: #f2f3f4;
                    height: 32px;
                    tr {
                        border: 1px solid $iron;
                        th {
                            text-align: center;
                            padding: 0;
                            font-family: "Rubik";
                            font-size: 10px;
                            font-weight: 500;
                            line-height: 1.6;
                            letter-spacing: 0.02px;
                            color: $nobel-color;
                            text-transform: uppercase;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            border: 1px solid $iron;
                            .ant-picker-calendar-date {
                                border: none;
                                margin: 0;
                                .ant-picker-calendar-date-value {
                                    font-family: "Rubik";
                                    font-size: 8px;
                                    line-height: 0.5;
                                    letter-spacing: 0.02px;
                                    // color: $mid-night;
                                }
                            }
                            &.ant-picker-cell-in-view {
                                .ant-picker-calendar-date-value {
                                    color: $mid-night;
                                }
                                .ant-picker-calendar-date-today {
                                    .ant-picker-calendar-date-value {
                                        width: 15px;
                                        height: 15px;
                                        border-radius: 50%;
                                        background-color: $sunset-color;
                                        margin-right: 0;
                                        margin-left: auto;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.fc-media-screen {
    border: 1px solid $iron;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
    .fc-header-toolbar {
        margin: 12px;
        .fc-toolbar-chunk:nth-child(1) {
            .fc-button-group {
                button {
                    background-color: #fff;
                    border-color: $iron;
                    &:focus {
                        box-shadow: none;
                    }
                    .fc-icon {
                        color: $mid-night;
                    }
                }
            }
            .fc-today-button {
                border: 1px solid $iron;
                background-color: #fff;
                color: $mid-night;
                text-transform: capitalize;
                &:focus {
                    box-shadow: none;
                }
                &:hover {
                    border-color: $mid-night;
                }
                &[disabled] {
                    border-color: $iron;
                }
            }
        }
        .fc-toolbar-chunk:nth-child(3) {
            .fc-button-group {
                button {
                    border-color: $iron;
                    background-color: #fff;
                    color: $mid-night;
                    text-transform: capitalize;
                    margin: 0;
                    &:focus,
                    &.fc-button-active {
                        box-shadow: none;
                        border: 1px solid $primaryColor;
                        color: $primaryColor;
                        background-color: rgba(29, 161, 242, 0.05);
                    }
                    &:hover {
                        border-color: $mid-night;
                    }
                }
            }
        }
    }
    .fc-view-harness {
        .fc-dayGridMonth-view {
            table {
                thead {
                    tr {
                        height: 32px;
                        .fc-col-header-cell {
                            background-color: #f2f3f4;
                            border-right: none;
                            border-left: none;
                            .fc-scrollgrid-sync-inner {
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                a {
                                    color: $nobel-color;
                                    text-transform: uppercase;
                                    font-size: 10px;
                                    font-weight: 500;
                                    line-height: 1.6;
                                    letter-spacing: 0.02px;
                                }
                            }
                        }
                        .fc-col-header-cell:nth-child(1) .fc-scrollgrid-sync-inner a::after {
                            content: "day";
                        }
                        .fc-col-header-cell:nth-child(2) .fc-scrollgrid-sync-inner a::after {
                            content: "day";
                        }
                        .fc-col-header-cell:nth-child(3) .fc-scrollgrid-sync-inner a::after {
                            content: "sday";
                        }
                        .fc-col-header-cell:nth-child(4) .fc-scrollgrid-sync-inner a::after {
                            content: "nesday";
                        }
                        .fc-col-header-cell:nth-child(5) .fc-scrollgrid-sync-inner a::after {
                            content: "rsday";
                        }
                        .fc-col-header-cell:nth-child(6) .fc-scrollgrid-sync-inner a::after {
                            content: "day";
                        }
                        .fc-col-header-cell:nth-child(7) .fc-scrollgrid-sync-inner a::after {
                            content: "urday";
                        }
                    }
                }
                tbody {
                    tr {
                        .fc-day-today {
                            .fc-daygrid-day-frame {
                                .fc-daygrid-day-top {
                                    a {
                                        border-radius: 50%;
                                        background-color: #f35848;
                                        width: 13px;
                                        height: 13px;
                                        color: #fff !important;
                                        padding: 0;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                }
                            }
                        }
                        .fc-daygrid-day {
                            .fc-daygrid-day-frame {
                                height: 135px;
                                padding: 2px;
                                .fc-daygrid-day-top {
                                    height: 15px;
                                    a {
                                        color: $mid-night;
                                        font-size: 8px;
                                        line-height: 0.5;
                                        letter-spacing: 0.02px;
                                    }
                                }
                                .fc-daygrid-day-events {
                                    position: unset;
                                    min-height: 0;
                                    .fc-daygrid-event-harness {
                                        position: unset;
                                        margin-top: 0px !important;
                                        .fc-daygrid-event {
                                            position: unset;
                                            width: 100%;
                                            margin-top: 0px;
                                            margin-bottom: 2px;
                                        }

                                        .fc-h-event {
                                            background-color: unset;
                                            border: unset;
                                        }
                                    }
                                    .fc-daygrid-day-bottom {
                                        display: none;
                                    }
                                }
                                .fc-daygrid-day-events > .fc-daygrid-event-harness:nth-of-type(n + 5) {
                                    visibility: hidden;
                                }
                                .day-grid-footer {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    position: absolute;
                                    bottom: 0;
                                    width: 100%;
                                    .btn-show-more {
                                        font-size: 10px;
                                        letter-spacing: 0.02px;
                                        color: $primaryColor;
                                        cursor: pointer;
                                    }
                                    .btn-new-content {
                                        display: none;
                                        font-size: 10px;
                                        letter-spacing: 0.02px;
                                        text-align: left;
                                        color: #9bc13c;
                                        cursor: pointer;
                                        margin-right: 5px;
                                    }
                                }
                                .btn-plus {
                                    display: none;
                                    height: 20px;
                                    border-radius: 4px;
                                    border: dotted 1px #9bc13c;
                                    background-color: rgba(155, 193, 60, 0.1);
                                    font-size: 11px;
                                    line-height: 0.91;
                                    letter-spacing: 0.02px;
                                    color: #9bc13c;
                                    cursor: pointer;
                                }
                                &:hover {
                                    .btn-plus {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }

                                    .btn-new-content {
                                        display: block;
                                    }
                                }
                                &:has(.day-grid-footer) {
                                    .btn-plus {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .fc-scroller {
                overflow: unset !important;
            }

            &.dragging {
                .fc-daygrid-day-bg {
                    div {
                        background-color: rgba(155, 193, 60, 0.11) !important;
                    }
                }
            }
        }
        .fc-timeGridWeek-view,
        .fc-timeGridDay-view {
            table {
                thead {
                    tr {
                        height: 32px;
                        .fc-col-header-cell {
                            background-color: #f2f3f4;
                            border-right: none;
                            border-left: none;
                            .fc-scrollgrid-sync-inner {
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                a {
                                    color: $nobel-color;
                                    text-transform: uppercase;
                                    font-size: 10px;
                                    font-weight: 500;
                                    line-height: 1.6;
                                    letter-spacing: 0.02px;
                                }
                            }
                            &.fc-day-today {
                                .fc-scrollgrid-sync-inner {
                                    a {
                                        font-size: 0px;
                                        div {
                                            font-size: 10px;
                                            display: flex;
                                            gap: 2px;
                                        }
                                        span {
                                            background-color: $sunset-color;
                                            border-radius: 50%;
                                            color: #fff;
                                            width: 16px;
                                            height: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                tbody {
                    & > tr.fc-scrollgrid-section:nth-child(1) {
                        display: none;
                    }
                    & > tr.fc-scrollgrid-section:nth-child(2) {
                        display: none;
                    }
                    .fc-timegrid-slots {
                        table {
                            tbody {
                                tr:nth-child(2n) {
                                    display: none;
                                }
                                tr {
                                    height: 80px;
                                }
                            }
                        }
                        .fc-timegrid-slot.fc-timegrid-slot-label {
                            .fc-timegrid-slot-label-cushion {
                                color: #9b9b9b;
                                text-transform: uppercase;
                                font-size: 10px;
                                font-weight: 500;
                                line-height: 1.6;
                                letter-spacing: 0.02px;
                            }
                        }
                    }
                    .fc-timegrid-col-frame {
                        .fc-timegrid-col-events {
                            .fc-timegrid-event-harness {
                                inset-inline: 0px !important;
                                height: 20px;
                                margin-top: 5px;
                                .fc-v-event {
                                    background-color: unset;
                                    border: unset;
                                }
                            }
                            .fc-timegrid-more-link {
                                display: none;
                            }
                        }
                        .fc-timegrid-col-bg {
                            .time-slot-overlay {
                                display: flex;
                                align-items: flex-end;
                                justify-content: space-between;
                                .btn-new-content {
                                    display: none;
                                    font-size: 11px;
                                    line-height: 2;
                                    letter-spacing: 0.02px;
                                    text-align: left;
                                    color: #9bc13c;
                                    cursor: pointer;
                                    padding: 3px 2px;
                                    &.btn-full-width {
                                        height: 20px;
                                        border-radius: 4px;
                                        border: dotted 1px #9bc13c;
                                        background-color: rgba(155, 193, 60, 0.1);
                                        line-height: 0.91;
                                        margin: 5px 3px 0;
                                    }
                                }
                                .btn-show-more {
                                    font-size: 11px;
                                    line-height: 2;
                                    letter-spacing: 0.02px;
                                    color: $primaryColor;
                                    cursor: pointer;
                                    padding: 3px 2px;
                                }
                                &:hover {
                                    display: flex;
                                    align-items: flex-end;
                                    justify-content: space-between;
                                    .btn-new-content {
                                        display: block;
                                        &.btn-full-width {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .fc-timegrid-col.dragging {
                        .btn-new-content {
                            display: none !important;
                        }
                    }
                }
            }
            &.dragging {
                .time-slot-overlay:hover {
                    background-color: rgba(155, 193, 60, 0.11);
                }
            }
        }
        .fc-timeGridDay-view {
            .fc-timegrid-col-frame {
                .fc-timegrid-col-events {
                    margin-right: 0;
                }
            }
        }
    }
}

.fc-more-popover {
    .fc-popover-body {
        .fc-timegrid-event {
            background: none !important;
            border: none !important;
        }
    }
}

.social-event-calendar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $mid-night;
    padding: 0 4px;
    border-radius: 4px;
    height: 20px;
    .social-icon {
        img {
            width: 12px;
            height: 12px;
        }
    }
    .post {
        flex-grow: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        margin: 0 4px;
        font-size: 10px;
        line-height: 1.6;
        letter-spacing: 0.02px;
    }
    .time {
        font-size: 8px;
        line-height: 2;
        letter-spacing: 0.02px;
        color: $nobel-color;
    }
    .icon-drag {
        display: none;
        img {
            width: 10px;
            height: 10px;
            margin-bottom: 1px;
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &:hover {
        .icon-drag {
            display: block;
            &:hover {
                cursor: move;
            }
        }
        .time {
            display: none;
        }
    }
    &.platform-0 {
        background-color: rgba(24, 119, 242, 0.1);
        &:hover {
            background-color: rgba(24, 119, 242);
            color: #fff;
            .time {
                color: #fff;
            }
        }
    }
    &.platform-1 {
        background-color: rgba(193, 53, 132, 0.1);
        &:hover {
            background-color: rgba(193, 53, 132);
            color: #fff;
            .time {
                color: #fff;
            }
        }
    }
    &.platform-2 {
        background-color: rgba(0, 0, 0, 0.1);
        &:hover {
            background-color: rgba(0, 0, 0);
            color: #fff;
            .time {
                color: #fff;
            }
        }
    }
    &.platform-3 {
        background-color: rgba(241, 67, 54, 0.1);
        &:hover {
            background-color: #f14336;
            color: #fff;
            .time {
                color: #fff;
            }
        }
    }
    &.platform-4 {
        background-color: rgba(255, 0, 0, 0.1);
        &:hover {
            background-color: #ff0000;
            color: #fff;
            .time {
                color: #fff;
            }
        }
    }
    &.platform-5 {
        background-color: rgba(0, 119, 181, 0.1);
        &:hover {
            background-color: #0077b5;
            color: #fff;
            .time {
                color: #fff;
            }
        }
    }
    &.error {
        border: solid 1px #f59f00;
        background-color: rgba(233, 163, 57, 0.1);
    }
}
