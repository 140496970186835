@import "../../../scss/helpers/variables";
.social-dashboard {
    width: 100%;
    &-header {
      width: 100%;
      > .ant-row {
        width: 100%;
      }
      .app-mobile-range-picker {
        border: 1px solid $iron;
        svg {
            color: $nobel-color;
        }
      }
    }
    .ant-tabs {
        background-color: transparent;
        line-height: 36px;
        .ant-tabs-nav {
            margin-bottom: 40px;
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    padding: 7px 0;
                    .ant-tabs-tab-btn {
                        font-size: 16px;
                        line-height: 1.5;
                        letter-spacing: 0.03px;
                    }
                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: $primaryColor;
                        }
                    }
                }
                .ant-tabs-ink-bar {
                    height: 3px;
                }
            }
        }
    }
    &-body {
        .overview-title {
            color: #a1aac7;
            font-size: 28px;
            font-weight: 500;
            line-height: 1.29;
            text-transform: uppercase;
        }
        .social-dashboard-overview {
            position: relative;
            .background {
                position: absolute;
                height: calc(100vh - 270px);
                width: 100%;
                background-image: url("../../../assets/images/blur-bg-social-social.png");
                background-repeat: no-repeat;
            }
            .add-connect {
                display: flex;
                justify-content: center;
                padding-top: 120px;
            }

        }
        .rl-card {
            .rl-card-count {
                display: flex;
                align-items: center;
                .volatility {
                    margin-left: 7px;
                    margin-top: 4px;
                    span {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.43;
                        letter-spacing: 0.03px;
                    }
        
                    svg {
                        width: 18px;
                        height: 18px;
                        margin-left: 3px;
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .social-dashboard {
        .ant-tabs {
            .ant-tabs-nav {
                .ant-tabs-nav-wrap {
                    .ant-tabs-nav-list {
                        justify-content: space-between;
                        // width: 100%;
                        .ant-tabs-tab { 
                            margin: 0px 10px;
                        }
                    }
                }
                .ant-tabs-nav-operations {
                    display: none !important;
                }
            }
        }
    }

}