@import "../../../../scss/helpers/variables";

.create-member-success {
    &__animation {
        img {
            width: 135px;
            height: 135px;
        }
    }

    .header {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #4b546e;
    }

    .description {
        color: $mid-night;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
