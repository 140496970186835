@import "../../../../../scss/helpers/variables";
.group-btn-integration {
    display: flex;
    justify-content: space-between;
    .remove-btn {
        button {
            color: $sunset-color;
        }
        flex-grow: 1;
    }
    .test-btn, .sync-btn {
        margin: 0 16px 0 0;
    }
    .sync-btn {
        &.loading {
            color: $mid-night;
            border-color: $mid-night;
            opacity: 0.5;
            outline: none;
        }
    }
    &.isAddConnection {
        margin-top: 40px;
    }
    .ant-btn-primary.test-btn[disabled] {
        color: #fff;
        background-color: #9bc13c;
        opacity: 0.5;
    }
    .ant-btn-primary.save-btn[disabled] {
        color: #fff;
        background-color: $primaryColor;
        opacity: 0.5;
    }
}
.remove-connection-content {
    color: $mid-night;
    .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.03px;
        margin-bottom: 16px;
    }
    .content {
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.03px;
        margin-bottom: 30px;
    }
}
.remove-connection-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .ant-btn-text {
        color: $mid-night;
        line-height: 1.43;
        letter-spacing: 0.03px;
    }
}