@import "../../../../scss/helpers/variables";
.custom-move-action-modal {
    .modal-content {
        color: $mid-night;
        .description {
            margin-bottom: 16px;
        }
        .select-label {
            margin-bottom: 8px;
        }
        .ant-select {
            width: 100%;
        }

        .notification-title {
            color: #4d546c;
            margin-bottom: 16px;
        }

        .notification-desc {
            color: #9b9b9b;
            font-weight: 500;
            font-size: 10px;
            margin-top: 6px;
        }
    }
    .ant-select-arrow {
        svg {
            color: #000;
            width: 15px;
            height: 15px;
        }
    }
}