.post-type-distribution {
    display: flex;
    align-items: center;

    &__chartWrapper {
        width: 180px;
        height: 180px;
        margin: 0 32px;
        position: relative;
    }

    &__legend {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        &__item {
            display: flex;
            align-items: center;
            width: 100%;
            &__name {
                flex: 1;
                color: #9b9b9b;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                word-break: break-word;
                height: 22px;
                overflow: hidden;
            }
            .post-count {
                width: 20px;
                text-align: right;
            }
            span {
                &:first-child {
                    width: 10px;
                    height: 10px;
                    border-radius: 3px;
                    margin-right: 8px;
                }
            }
        }
    }

    &__total {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: transparent;
        width: 111px;
        height: 111px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            &:first-child {
                font-size: 12px;
                color: #9b9b9b;
            }

            &:last-child {
                font-size: 20px;
                color: #4d546c;
                font-weight: 500;
            }
        }
    }

    &__emptyChart {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__empty__total {
        width: 110px;
        height: 110px;
        background-color: #ffffff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span {
            &:first-child {
                font-size: 12px;
                color: #9b9b9b;
            }

            &:last-child {
                font-size: 20px;
                color: #4d546c;
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 576px) {
    .post-type-distribution {
        flex-direction: column;

        &__legend {
            width: 100%;
            margin-top: 16px;
        }
    }
}
