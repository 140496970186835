@import "../../../../scss/helpers/variables";
.integration-form {
    &__section {
        &__title {
            font-weight: bold;
        }

        &__field {
            margin-bottom: 16px;
        }
    }

    &__alert {
        margin-bottom: 12px;
    }

    & > div {
        margin-bottom: 24px;
    }
    .connection-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        & > span {
          font-weight: 500;
        }
      }
      .connect-apps {
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
    
        .learn-more {
          color: #0185de;
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            opacity: 0.9;
            transition: all ease-in-out 0.5;
          }
        }
      }
    } 

    .site-collapse-custom-collapse-integration {
        .integration-form__section {
            .account-info {
                .account-info-item {
                    width: 50%;
                }
            }
        }
    }
}
.custom-connection-form {
    &.ant-form {
        color: $mid-night;
        .ant-form-item {
            margin-bottom: 16px;
            color: $mid-night;
        }
    }
}
