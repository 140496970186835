@import "../../scss/helpers/variables";

.general-settings-page {
    .setting-sider-bar {
        width: 310px;
        margin-right: 16px;

        &__item {
            width: 100%;
            height: 50px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 4px;
            transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);

            &:hover {
                background-color: #353d5305;
            }

            &__active {
                background-color: #353d5310;
                font-weight: 500;
            }

            svg {
                width: 16px;
                height: auto;
                margin-right: 8px;
            }
        }
        .setting-sider-bar-social {
            width: 100%;
            height: 50px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            font-weight: 500;
            line-height: 1.14;
            letter-spacing: 0.03px;
            color: $nobel-color;
        }
    }

    .page-body {
        display: flex;
        align-items: flex-start;

        .ant-card {
            flex-grow: 1;
        }
    }

    .setting-sider-bar-mobile {
        display: none;
    }

    .ant-divider {
        display: none;
    }
    
    @media (max-width: 576px) {
        .page-header {
            height: 70px;
            align-items: flex-end;
        }
        .page-body {
            flex-direction: column;
            .ant-card {
                box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
                border: solid 1px $iron;
                border-radius: 4px;
                &-head {
                    padding: 0 20px;
                    .ant-card-head-title {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        letter-spacing: 0.03px;
                        color: $mid-night;
                    }
                }
                &-body {
                    padding: 20px;
                }
            }
        }

        .setting-sider-bar {
            display: none;
        }

        .setting-sider-bar-mobile {
            display: block;
            margin-bottom: 16px;
            .ant-select-selector {
                height: 50px !important;
                padding: 0;
                border: 1px solid $mid-night;
                .setting-sider-bar__item {
                    color: $mid-night;
                    font-weight: 500;
                    padding: 16px;
                }
            }
            .ant-select-arrow {
                svg {
                    width: 15px;
                    color: $mid-night;
                }
            }
        }

        .ant-divider {
            display: block;
            width: calc(100vw - 18px);
            margin-left: -21px;
        }
    }
}

@media (max-width: 576px) {
    .setting-sider-bar-mobile__dropdown {
        .ant-select-item {
            padding: 0;
            .setting-sider-bar__item {
                display: flex;
                align-items: center;
                color: $mid-night;
                height: 50px;
                padding: 16px;
                svg {
                    width: 15px;
                    margin-right: 5px;
                }
            }
        }
        .ant-select-item-option-selected {
            background-color: #F6F6F8;
        }
    }
}
