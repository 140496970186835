@import "../../../../scss/helpers/variables";

.edit-drawer-content {
    .site-collapse-custom-collapse {
        border-color: $iron;
        background-color: #fff;
        .ant-collapse-item {
            border: 1px solid $iron;
            margin-bottom: 16px;

            &.ant-collapse-item-active {
                .ant-collapse-header {
                    border-bottom: 1px solid $iron;
                }
            }

            .ant-collapse-header {
                background-color: #fff;
                padding-right: 16px;
                .ant-collapse-expand-icon {
                    span {
                        transform: inherit;
                        top: 12px;
                    }
                }
                .ant-collapse-header-text {
                    .title {
                        font-weight: bold;
                        color: $mid-night;
                        display: flex;
                        align-items: center;
                        svg {
                            width: 16px;
                            height: 16px;
                            line-height: 1.25;
                            letter-spacing: 0.03px;
                            margin: 0 0 4px 4px;
                            margin-right: 4px;
                        }
                    }
                    .description {
                        font-size: 12px;
                        line-height: 1.33;
                        letter-spacing: 0.02px;
                        color: $nobel-color;
                    }
                }
            }
        }
    }

    .learnMore {
        text-decoration: underline;
    }

    .trend-indicators {
        margin-top: 12px;

        .wrapper {
            display: flex;
            align-items: center;
            gap: 20px;

            .left {
                display: flex;
                align-items: center;
                min-width: 230px;
                gap: 8px;

                color: #4d546c;

                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-color: #e3e4e6;
                    cursor: pointer;

                    span {
                        font-size: 11px;
                        line-height: 1.82;
                        letter-spacing: 0.02px;
                    }

                    svg {
                        width: 11px;
                        height: auto;
                    }
                }
            }
        }

        .saveBtn {
            margin-top: 24px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .fields-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 16px;

        .left {
            display: flex;
            align-items: center;
            min-width: 230px;
            gap: 8px;
        }

        .ant-input-number-group-addon {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            height: 36px;
        }

        .input-right-addon {
            .ant-input-number-group-addon {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                height: 36px;
            }
        }
        .ant-input-number-input {
            border-radius: 4px;
            height: 36px;
            width: 82px;
        }
    }

    .average-value {
        margin: 10px 0 20px 0;
        .label {
            margin-bottom: 16px;
        }
        .average-value__input {
            display: flex;
            align-items: center;
            .reset-btn {
                margin-left: 8px;
            }
            .ant-input-number-group-wrapper {
                max-width: 280px;
                margin: 0 8px 0 0;

                svg {
                    color: #4b546e;
                    width: 14px;
                    height: auto;
                    vertical-align: middle;
                }
            }
            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: #e3e4e6;
                cursor: pointer;

                span {
                    font-size: 11px;
                    line-height: 1.82;
                    letter-spacing: 0.02px;
                }

                svg {
                    width: 11px;
                    height: auto;
                }
            }
        }
    }
}
