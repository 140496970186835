@import "../../../../scss/helpers/variables";
.general-setting-team {
    .title {
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: $mid-night;
    }
    .general-setting-team-table {
        .ant-table {
            .ant-table-content {
                margin-top: 0;
                .ant-table-cell {
                    .ant-checkbox-wrapper-disabled {
                        .ant-checkbox-checked {
                            .ant-checkbox-inner {
                                background-color: #1890ff;
                                border-color: #1890ff;
                                opacity: 0.5;
                                &::after {
                                    border-color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        .group-btn {
            margin-top: 26px;
            display: flex;
            justify-content: space-between;
            .ant-btn-default[disabled] {
                border: solid 1px $mid-night;
                background-color: #fff;
                opacity: 0.5;
                color: $mid-night;
            }
            .ant-btn-primary[disabled] {
                color: #fff;
                background-color: $primaryColor;
                opacity: 0.5;
            }
        }
        .team-table-title {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                height: 12px;
                &:first-child {
                    margin-right: 2px;
                }
                svg {
                    width: 12px;
                    height: 12px;
                    font-weight: 500;
                    line-height: 1.6;
                    letter-spacing: 0.02px;
                    color: $nobel-color;
                }
            }
        }
        .owner {
            display: flex;
            align-items: center;
            gap: 8px;

            &-icon{
                border-radius: 6px;
                border: 0;
                font-weight: 500;
                font-size: 10px;
            }
        }
        
    }
}