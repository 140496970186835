@import "../../../../../../scss/helpers/variables";
.delete-content-modal {
    width: 520px;
    max-width: 520px;
    .ant-modal-body {
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
        border: solid 1px $iron;
        background-color: #fff;
    }
    .delete-content-modal-inner {
        .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: 0.03px;
            color: #4d546c;
        }
        .description {
            font-size: 14px;
            line-height: 1.43;
            letter-spacing: 0.03px;
            color: #4d546c;
        }
        .delete-content-modal-inner__list-content {
            padding: 0 16px;
            .ant-checkbox-group {
                display: flex;
                flex-direction: column;
                .ant-checkbox-group-item {
                    margin-bottom: 19px;
                }
            }
        }
        .group-btn {
            display: flex;
            justify-content: space-between;
            margin-top: 13px;

            .ant-btn-dangerous[disabled] {
                opacity: 0.5;
                border-radius: 4px;
                background-color: $sunset-color;
                color: #fff;
            }
        }
    }
}