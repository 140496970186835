@import "../../../../../scss/helpers/variables";

.generate-report-modal {
    width: 700px !important;
    max-width: 700px !important;

    @media (max-width: 576px) {
        width: auto;
        max-width: auto;
    }

    .headingTitle {
        color: #4d546c;
        font-weight: 500;
        margin-bottom: 8px;
        margin-top: 8px;

        &.top {
            margin-top: 0px;
            margin-bottom: 3px;
        }
    }

    .headingTitleDesc {
        color: #4d546c;
        margin-bottom: 16px;
        margin-top: -8px;
    }

    .app-range-picker {
        border: 1px solid #d9d9d9;

        &:hover {
            border-color: #40a9ff;
            transition: all ease-in-out 0.3s;
        }
    }
    
}

.generate-report-loading {
    max-width: 836px !important;
    width: 836px !important;
    height: 712px !important;
    top: 150px !important;
    
    .format {
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: #4d546c;
    }
    
    .uploading {
        .scroll-upload {
            width: 100%;
            height: 8px;
            background-color: $iron;
            border-radius: 4px;
            margin: 24px 0;
            overflow: hidden;
            .scroll-bar {
                height: 100%;
                width: 30%;
                background-color: $green-color;
                border-radius: 4px;
                animation-name: move;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-direction: right;
                animation-timing-function: linear;
            }
            @keyframes move {
                0% {
                    margin-left: -30%;
                }
                100% {
                    margin-left: 100%;
                }
            }
        }
    }
    .media-footer-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .media-footer-wrapper__left {
            display: flex;
            .gg-drive-upload {
                margin-right: 16px;
            }
            .device-upload {
                .device-upload-btn {
                    label {
                        cursor: pointer;
                    }
                }
            }
        }
        .media-footer-wrapper__right {

        }

        
    }

    &.generate-report-loading {
        max-width: 500px !important;
        width: 500px !important;

        @media (max-width: 576px) {
            max-width: 350px !important;
            width: 350px !important;
        }
    }

}
