@import "../../../scss/helpers/variables";
.app-date-picker {
    display: flex;
    &-date {
        display: flex;
        align-items: center;
        background-color: #fff;
        width: fit-content;
        border-radius: 4px;
        height: 36px;
        padding-left: 10px;
        position: relative;
        cursor: pointer;
        border: 1px solid #e3e4e6;
        margin-right: 6px;

        &__input-wrapper {
            flex: 1;
            position: relative;
            > input {
                border: none;
                width: 100%;
                cursor: pointer;
                &:focus {
                    box-shadow: none;
                    border-color: unset;
                    outline: none;
                }
            }
        }

        .ant-picker {
            border: none;
            padding-right: 0;
            &.ant-picker-focused,
            input:focus {
                box-shadow: none;
                border-color: unset;
                outline: none;
            }
            input {
                color: $mid-night;
            }
        }

        > svg {
            width: 18px;
            height: 18px;
            color: #9b9b9b;
        }

        &__footer-wrapper {
            display: flex;
            justify-content: space-between;
            padding: 10px 0px;

            &__action-wrapper {
                display: flex;

                button {
                    padding: 4px 8px;
                    height: fit-content;

                    &.active {
                        background-color: #4b546e;
                        color: #ffffff;
                    }
                }
            }
        }

        &__apply {
            &:hover {
                background-color: #0185de;
                color: #ffffff;
            }
        }

        &__header {
            border-bottom: 1px solid #e3e4e6;
            padding-left: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__value {
                .separator {
                    margin: 0 5px;
                }
            }

            .close-btn {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                svg {
                    width: 20px;
                    height: 20px;
                    color: #9b9b9b;
                }
            }
        }
            
    }
}