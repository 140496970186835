.second-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    align-items: center;

    &__left {
        display: flex;
        .social-dropdown {
            display: flex;
            align-items: center;
            svg {
                margin-left: 4px;
            }
        }
    }

    a {
        padding: 12px 8px;
        color: #a1aac7;
        display: flex;
        align-items: center;

        &:focus,
        &:hover,
        &.active {
            color: #ffffff;
        }

        svg {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }

    &__right {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            color: #f7f8fb;

            svg {
                color: #9b9b9b;
            }
        }
    }

    &__website {
        padding: 8px 12px !important;
        transition: all 0.2s;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: #4b546e;
        }
    }
}

@media (min-width: 576px) {
    .second-header {
        height: 56px;
    }
}

@media (max-width: 576px) {
    .second-header {
        align-items: flex-start;
        transition: height 0.35s ease;
        overflow: hidden;

        &.collapse:not(.show) {
            height: 0;
        }

        &__left {
            display: flex;
            flex-direction: column;
        }
    }
}

.social-tab-dropdown {
    .ant-dropdown-menu {
        margin: 14px 0 0 5px;
    }   
}