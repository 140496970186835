@import "../../../../../../scss/helpers/variables"; 

.edit-approval {
    display: flex;
    align-items: center;
    .edit-approval-status {
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: $nobel-color;
        margin-right: 8px;
    }
    .icon-approval {
        width: 32px;
        height: 32px;
        border-radius: 999px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $mid-night;
        cursor: pointer;
        svg {
            width: 14px;
            height: 14px;
        }

        &.pending {
            background-color: #fff;
        }
        &.not_required {
            background-color: $iron;
            border: none !important;
            svg {
                color: $nobel-color;
            }
        }
        &.approved {
            background-color: $green-color;
            border: none !important;
            svg {
                color: #FFF;
            }
        }
        &.declined {
            background-color: $sunset-color;
            border: none !important;
            svg {
                color: #FFF;
            }
        }
    }
}

.mobile-action {
    display: none;
}

@media (max-width: 576px) { 
    .edit-approval {
        display: none;
    }
    .mobile-action {
        display: block;
    }
}

.modal-decline-content {
    .description {
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.03px;
        color: $mid-night;
        margin-bottom: 20px;
    }
}
