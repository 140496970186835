@import "../helpers/variables";
.ant-btn {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    svg,
    img {
        width: 15px;
        height: auto;
        margin-right: 5px;
        margin-bottom: 1px;
    }
}

.ant-btn-default {
    border-color: $mid-night;
}

.ant-btn-primary {
    border-color: $primaryColor;
    background: $primaryColor;

    &:hover {
        border-color: $primaryHoverColor;
        background: $primaryHoverColor;
    }

    &.secondary-btn {
        border-color: $secondaryColor;
        background: $secondaryColor;

        &:hover {
            border-color: $secondaryHoverColor;
            background: $secondaryHoverColor;
        }

        &:disabled {
            color: #fff;
            opacity: 0.5;

            &:hover {
                border-color: $secondaryColor;
                background: $secondaryColor;
            }
        }
    }

    &.ant-btn-dangerous {
        border-color: $sunset-color;
        background: $sunset-color;
    }

    &.ant-btn-purple {
        border-color: $purple-color;
        background: $purple-color;
    }
}

.ant-input {
    height: 36px;
    border-radius: 4px;
}

.ant-input-password {
    border-radius: 4px;
    padding: 0;
    &.ant-input-affix-wrapper > input.ant-input {
        padding: 4px 11px;
    }

    .ant-input-suffix {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 30px;
        display: flex;
        justify-content: center;
    }
}

.hiden-required-mark {
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
    }
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    //display: none;
    font-family: inherit;
    position: absolute;
    right: -12px;
}

.ant-form-item {
    margin-bottom: 16px;
    // padding-bottom: 20px;
}

.ant-form-item-with-help .ant-form-item-explain {
    text-align: left;
}

.ant-form-vertical .ant-form-item-label {
    line-height: 14px;
}

.ant-form-item-explain-error {
    font-size: 12px;
    line-height: normal;
    padding-top: 4px;
    margin-bottom: 16px;
}

.ant-form-item-explain-error + .ant-form-item-explain-error {
    display: none;
}

.ant-alert {
    border-radius: 4px;
    display: flex;
    align-items: center;

    &.ant-alert-success {
        background-color: #f5f9eb;
        border: 1px solid #9bc13c;

        .ant-alert-icon {
            svg {
                width: 24px;
                height: 24px;
                margin-right: 16px;
                path {
                    fill: #9bc13c;
                }
            }
        }
    }

    &.ant-alert-error {
        background-color: #feeeec;
        border: 1px solid #f35848;
    }

    > svg {
        margin-right: 16px;
    }

    .ant-alert-content {
        text-align: left;

        .ant-alert-message {
            font-weight: 500;
            color: #4b546e;
        }
    }
}

.ant-notification-notice {
    padding: 12px 20px;
    border-radius: 4px;
    width: auto;
    max-width: 600px;
    &.ant-notification-notice-success {
        background-color: #f5f9eb;
        border: 1px solid #9bc13c;
    }

    &.ant-notification-notice-warning {
        background-color: #f5f9eb;
        border: 1px solid #f59f00;
    }

    &.ant-notification-notice-error {
        background-color: #feeeec;
        border: 1px solid #f35848;
    }
}

.ant-input-affix-wrapper.search-input {
    color: #9b9b9b;
    padding: 0;
    border-radius: 4px;
    input {
        &.ant-input {
            padding: 0 11px;
            height: 34px;
        }
    }
    .ant-input-suffix {
        width: 26px;
        svg {
            &.app-icon {
                width: 18px;
                height: 18px;
            }
        }
    }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    border-radius: 4px;

    .ant-select-selection-item {
        line-height: 34px;
    }

    .ant-select-selection-search-input {
        height: 34px;
        line-height: 34px;
    }
}

.main-table {
    &:not(.no-bordered) {
        .ant-table-content {
            margin-top: 16px;
            box-shadow: 0 2px 6px 0 rgba(31, 41, 59, 0.07);
            border: solid 1px #e3e4e6;
            border-radius: 4px;
        }
    }

    .ant-table-thead > tr > th {
        font-size: 10px;
        text-transform: uppercase;
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: #f2f3f4;
    }
}

.ant-drawer-header {
    padding-right: 0;
}

.ant-drawer-header-title {
    flex-direction: row-reverse;
}

.ant-tabs {
    color: inherit;
}

.ant-picker-dropdown-range {
    .ant-picker-header {
        border-bottom: none;
    }

    .ant-picker-panel {
        &:first-child {
            border-right: 1px solid #e3e4e6;
        }
    }

    .ant-picker-header-super-prev-btn {
        display: none;
    }

    .ant-picker-header-super-next-btn {
        display: none;
    }
}

.ant-picker-panel-container {
    border-radius: 4px;
}

.ant-table {
    color: $mid-night;
}

.ant-table-thead > tr > th {
    color: $nobel-color;
}

.ant-modal-footer {
    display: flex;
    justify-content: space-between;
    border-top: none;
    padding: 24px;
    padding-top: 0px;
}

.ant-input-textarea-show-count:after {
    position: absolute;
    top: -22px;
    right: 0;
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    color: $mid-night;
}

.ant-checkbox-inner {
    border-radius: 3px;
}

.ant-modal-confirm-btns {
    display: flex;
    justify-content: space-between;
}

.ant-modal-confirm-body .ant-modal-confirm-content,
.ant-modal-confirm-body .ant-modal-confirm-title {
    color: $mid-night;
}

.ant-form-item-label > label {
    color: $mid-night;
}

.ant-notification-notice-message {
    font-weight: 500;
    color: $mid-night;
}

.ant-pagination {
    li {
        color: $mid-night;
        a {
            color: $mid-night;
        }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
        display: flex;
        align-items: center;
        height: 28px;
        svg {
            font-size: 15px;
        }
    }
    .ant-pagination-disabled {
        color: $nobel-color;
    }
    .ant-pagination-item {
        border: none;
        background-color: transparent;
        min-width: 28px;
        height: 28px;
    }
    .ant-pagination-item-active {
        border-radius: 3px;
        background-color: $blue-color;
        a {
            color: #fff;
        }
    }
}

.ant-picker-calendar {
    color: $mid-night;
}

.ant-dropdown-menu {
    border-radius: 4px;
}

.ant-table-pagination.ant-pagination {
    align-items: center;
    display: flex;
}
.custom-tooltip {
    .ant-tooltip-inner {
        padding: 0;
        border-radius: 8px !important;
        display: flex;
        font-size: 12px !important;
        text-align: center;
        background-color: #4b546e;
        padding: 8px;
        border-radius: 8px !important;
    }

    &.media-library {
        pointer-events: none;
        .ant-tooltip-content {
            top: 100px;
        }
        .ant-tooltip-inner {
            width: max-content;
        }
    }

    .ant-tooltip-arrow-content {
        --antd-arrow-background-color: #4b546e;
    }
}

.activities {
    .ant-timeline-item-tail {
        position: absolute;
        top: 8px;
        left: 2px;
        height: calc(100% - 10px);
        border-left: 0;
        border-color: #d2d2d2;
        border-style: dashed;
        border-width: 0.5px;
    }

    .ant-timeline-item-head {
        background-color: currentColor;
        width: 6px;
        height: 6px;
    }
}
