@import "../../../../../scss/helpers/variables";

.create-content-modal {
    width: 678px !important;
    max-width: 678px !important;
    .container {
        &-profiles {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            &__left {
                display: flex;
            }
            &__right {
                .sync-content {
                    span {
                        margin-right: 8px;
                    }
                }
            }
        }

        &-content {
            margin-top: 16px;
            display: flex;
            &__tab {
                .content-editor-tab {
                    margin-right: 16px;
                }
            }
            &__editor {
                flex-grow: 1;
                overflow: hidden;
                .create-content-modal-footer {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 16px;
                    .btn-group-save {
                        display: flex;
                        .btn-save {
                            &:not(.no-dropdown) {
                                border-radius: 4px 0 0 4px;
                            }
                            margin-right: 1px;
                            &.full-radius {
                                border-radius: 4px;
                            }
                        }
                        .btn-dropdown {
                            border-radius: 0 4px 4px 0;
                            padding: 4px 8px;
                            svg {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-modal-footer {
        width: 100%;
    }
    
    .ant-modal-body {
        padding-bottom: 16px;
    }
}
