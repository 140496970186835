@import "../../../scss/helpers/variables";
.create-site-modal {
    .ant-form-item {
        margin-bottom: 16px;
    }
    .ant-row {
        .invitation-email {
            .title {
                font-size: 14px;
                color: #4d546c;
            }
            .description {
                font-size: 10px;
                font-weight: 500;
                line-height: 1.6;
                letter-spacing: 0.02px;
                color: $nobel-color;
            }
        }

        .ant-form-item-explain {
            & + div {
                display: none;
            }
        }
    }
    .ant-modal-body {
        padding-bottom: 32px;
        .ant-form-item-has-error {
            .ant-row {
                .ant-col {
                    & > div:last-child {
                        display: block !important;
                    }
                }
            }
        }
    }
}


